<template>
    <Line
        :data="chartData"
        :options="chartOptions"
        :plugins="pluginOptions"
        :height="height"
    />
</template>

<script setup>
import { Line } from 'vue-chartjs'
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    TimeScale,
    Filler,
} from 'chart.js'
import 'chartjs-adapter-date-fns'
ChartJS.defaults.font.family = 'Inter'

const props = defineProps({
    height: {
        type: [Number, String],
        default: 50,
    },
    grace: {
        type: Number,
        default: 5,
    },
    chartData: {
        type: Object,
        required: true,
    },
    chartOptions: {
        type: Object,
        default: () => ({}),
    },
    plugins: {
        type: Array,
        default: () => [],
    },
    pluginClassList: {
        type: Array,
        default: () => [],
    },
})

const line = ref(null)
defineExpose({ line })

const pluginOptions = computed(() => [
    ...props.plugins,
    {
        id: 'customScale',
        beforeLayout: (chart) => {
            let max = 0
            let min = 0
            const grace = props.grace / 100

            chart.data.datasets.forEach((dataset) => {
                max = Math.max(max, Math.max(...dataset.data))
                min = Math.min(min, Math.min(...dataset.data))
            })

            chart.options.scales.y.suggestedMax =
                max + (Math.abs(max) + Math.abs(min)) * grace
            chart.options.scales.y.suggestedMin = min
        },
    },
])

ChartJS.register(
    Title,
    Tooltip,
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Filler,
    TimeScale,
    ...props.pluginClassList
)
</script>
