<template>
    <div class="flex min-w-96 flex-col border-r">
        <!-- Sidebar header -->
        <div
            class="flex justify-between border-b bg-gray-50 p-5"
            :class="headerClasses"
        >
            <slot name="header" v-bind="{ checkedItems }" />
        </div>

        <!-- Sidebar main -->
        <div class="grow overflow-auto" ref="el">
            <div v-for="item in items" :key="item.id">
                <slot name="item" v-bind="{ item }">
                    <div
                        class="flex cursor-pointer items-start gap-5 border-b p-2.5 text-gray-400 hover:bg-gray-50 hover:text-gray-700"
                        :class="{
                            'bg-gray-50 text-gray-700':
                                item.id === selectedItem?.id,
                        }"
                    >
                        <form-checkbox
                            v-if="selectable"
                            :model-value="
                                checkedItems.some(
                                    (checkedItem) => checkedItem.id === item.id
                                )
                            "
                            @update:model-value="onItemCheck($event, item)"
                        />
                        <slot
                            name="item-content"
                            v-bind="{ item, clickItem: onItemClick }"
                        />
                    </div>
                </slot>
            </div>

            <base-loading
                v-if="isLoading"
                class="flex items-center justify-center p-2.5"
            />
        </div>
    </div>
</template>
<script setup>
import { useInfiniteScroll } from '@vueuse/core'

const props = defineProps({
    items: {
        type: Array,
        default: () => [],
    },
    isLoading: {
        type: Boolean,
        default: false,
    },
    initialSelectedItem: {
        type: Object,
        default: () => ({}),
    },
    selectable: {
        type: Boolean,
        default: false,
    },
    initialize: {
        type: Boolean,
        default: false,
    },
    headerClasses: {
        type: String,
        default: '',
    },
})
const emit = defineEmits(['item-click', 'load-more', 'item-check'])

const selectedItem = ref(null)
const onItemClick = (item) => {
    selectedItem.value = item
    emit('item-click', item)
}

const el = ref(null)
useInfiniteScroll(
    el,
    () => {
        emit('load-more')
    },
    { distance: 10 }
)

watchEffect(() => {
    if (!selectedItem.value) {
        selectedItem.value = props.initialSelectedItem
    }
})

const checkedItems = ref([])
const onItemCheck = (value, item) => {
    if (value) {
        checkedItems.value.push(item)
    } else {
        checkedItems.value = checkedItems.value.filter(
            (checkedItem) => checkedItem.id !== item.id
        )
    }

    emit('item-check', {
        checked: value,
        checkedItems: checkedItems.value,
        item,
    })
}

// Set checkedItems to empty when there's need to initialize the list
watch(
    () => props.initialize,
    (value) => {
        if (value) {
            checkedItems.value = []
        }
    }
)
</script>
