<template>
    <div
        class="grid cursor-pointer grid-cols-3 gap-3 rounded-lg bg-gray-50 px-3 py-2 hover:!bg-primary-50"
    >
        <div class="col-span-2 text-left text-sm font-normal text-gray-700">
            {{ item.contact_name }}
        </div>

        <div class="col-span-1 text-right text-sm font-normal text-black">
            {{ $filters.symbolCurrency(item.amount, item.currency) }}
        </div>

        <div class="col-span-3 flex justify-between">
            <div class="text-left text-xs font-normal text-primary-600">
                {{ item.number }} |
                <span class="text-xs font-normal text-gray-500">
                    {{ $filters.dateHumanFormat(item.date) }}
                </span>
            </div>
            <div class="text-right text-xs font-normal text-black">
                <partial-status
                    :status="getInvoiceStatus(item.status, item.due_date)"
                    variant="small"
                >
                    <template #text>{{ getStatusText(item) }}</template>
                </partial-status>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useInvoice } from '@tenant/modules/tenant/invoices/composables/use-invoice'
import { dayDiff, now } from '@tenant/utils/day'

const { getInvoiceStatus } = useInvoice()

defineProps({
    item: {
        type: Object,
        required: true,
    },
})

const getStatusText = (item) => {
    const { t } = useI18n()
    if (!item?.status) return

    const status = getInvoiceStatus(item.status, item.due_date)

    if (status === 'due') {
        return t(
            `general.statuses.${status}`,
            Math.abs(dayDiff(item.due_date, now()))
        )
    }

    return t(`general.statuses.${status}`)
}
</script>
