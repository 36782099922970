<template>
    <base-modal close size="lg" @modal-close="closeModal" :show="show">
        <template #modal-header>
            <span class="text-lg font-medium text-gray-900">
                {{ $t('shareLink.title') }}
            </span>
        </template>

        <p class="pb-5 text-sm text-gray-500">
            {{
                $t('shareLink.description', {
                    type,
                })
            }}
        </p>
        <form-text-input :model-value="link" :disabled="true" />
        <p class="pb-1 pt-5 text-sm text-gray-500">
            {{ $t('shareLink.copyLinkText') }}
        </p>

        <p
            @click="previewInvoice"
            class="font-weight-bold flex cursor-pointer items-center gap-1 text-sm text-primary-600"
        >
            {{ $t('shareLink.previewText') }}
            <base-icon size="xs" name="line-icons:general:share-07" />
        </p>

        <template #modal-footer>
            <div class="flex justify-end gap-3">
                <base-button outline variant="default" @click="closeModal">
                    {{ $t('general.cancel') }}
                </base-button>

                <base-button outline variant="default" @click="copyLink">
                    {{ $t('shareLink.copyOnly') }}
                </base-button>

                <base-button v-if="canMarkAsSent" @click="markAsSent">
                    {{
                        $t('shareLink.copyAndMarkAsSent', {
                            type,
                        })
                    }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
import cookie from '@tenant/utils/cookie'
import { STORAGE } from '@tenant/utils/constants'

const emit = defineEmits(['modal-close', 'modal-confirm', 'mark-as-sent'])

const props = defineProps({
    show: {
        type: Boolean,
        default: true,
    },
    link: {
        type: String,
        default: '',
    },
    type: {
        type: String,
        default: '',
    },
    canMarkAsSent: {
        type: Boolean,
        default: true,
    },
    hash: {
        type: String,
        default: '',
    },
})

const closeModal = () => {
    emit('modal-close')
}

const { successNotify, errorNotify } = useNotification()
const { t } = useI18n()
const copyLink = async () => {
    try {
        await navigator.clipboard.writeText(props.link)
        successNotify({
            title: t('shareLink.linkCopiedMsg'),
        })
    } catch (err) {
        errorNotify({
            title: t('shareLink.failedToCopyMsg'),
        })
    }
}

const markAsSent = async () => {
    await copyLink()
    emit('mark-as-sent')
}

const router = useRouter()
const previewInvoice = () => {
    const linkRoute = router.resolve({
        name: `${props.type}.share-preview`,
        params: {
            tenantId: cookie.getItem(STORAGE.TENANT_ID),
            hash: props.hash,
        },
    })

    window.open(linkRoute.href, '_blank')
}
</script>
