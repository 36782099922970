<template>
    <data-grid
        :table-borderless="!compact"
        :data-source="entities.data"
        :columns="columns"
        :loading="loading"
        identify-field="id"
        @row-click="onRowClick"
        v-bind="$attrs"
        :empty-text="$t('documentExtraction.emptyText')"
        :empty-sub="$t('documentExtraction.emptySubText')"
        :replace-query="false"
        @sort-change="loadData"
    >
        <template v-if="!compact" #filter>
            <div class="flex w-full items-center justify-between gap-2">
                <div class="grow">
                    <purchases-bill-filter-tabs />
                </div>
                <div class="flex grow gap-3">
                    <purchases-bill-filter-search />
                    <purchases-bill-filter-slide-out />
                </div>
            </div>
        </template>

        <template #column-date_of_document="{ item }">
            <div class="text-sm font-normal text-gray-500">
                {{ $filters.dateHumanFormat(item.date_of_document) }}
            </div>
        </template>

        <template #column-document_number="{ item }">
            <span class="text-sm font-normal text-primary-600">
                {{ item.document_number }}
            </span>
        </template>

        <template #column-vendor="{ item }">
            <div class="text-sm font-normal text-gray-500">
                {{ item.supplier_name }}
            </div>
        </template>

        <template #column-status="{ item }">
            <partial-status
                :status="getStatus(item.status, item.due_date)"
                variant="small"
            >
                <template #text>
                    {{ getStatusText(item) }}
                </template>
            </partial-status>
        </template>

        <template #column-dueDate="{ item }">
            <div class="text-sm font-normal text-gray-500">
                {{ $filters.dateHumanFormat(item.due_date) }}
            </div>
        </template>

        <template #column-amount="{ item }">
            {{
                $filters.symbolCurrency(item.amount_gross_total, item.currency)
            }}
        </template>

        <template #column-unpaid_amount="{ item }">
            {{ $filters.symbolCurrency(item.unpaid_amount, item.currency) }}
        </template>

        <template #footer>
            <grid-pagination
                :total="entities.last_page"
                :page="entities.current_page"
                :replace-query="replaceQuery"
                @page-change="onPageChange"
            />
        </template>

        <template #empty v-if="$slots['empty']">
            <slot name="empty" />
        </template>
    </data-grid>
</template>

<script>
export default {
    inheritAttrs: false,
}
</script>

<script setup>
import {
    useBillStatus,
    useGetBills,
} from '@tenant/modules/tenant/purchases-bill/composables/use-bill'

const { getStatus, getStatusText } = useBillStatus()

const props = defineProps({
    statuses: {
        type: Array,
        default: () => [],
    },
    replaceQuery: {
        type: Boolean,
        default: true,
    },
    title: {
        type: String,
        default: null,
    },
    compact: {
        type: Boolean,
        default: false,
    },
    queries: {
        type: Object,
        default: () => ({}),
    },
})

const emit = defineEmits(['bills-total'])

const router = useRouter()
const { t } = useI18n()

const { entities, loading, execute } = useGetBills({}, false)

watch(
    () => entities?.value?.total,
    (value) => {
        emit('bills-total', value)
    }
)

const state = reactive({
    page: 1,
})

onMounted(() => {
    loadData()
})

watch(
    () => [props.statuses, props.queries],
    () => {
        loadData()
    },
    { deep: true }
)

const loadData = async (property, direction) => {
    await execute({
        statuses: props.statuses,
        page: state.page,
        ...props.queries,
        ...(property && direction
            ? { sort: { col: property, by: direction } }
            : {}),
    })
}

const onPageChange = (page) => {
    state.page = page

    return loadData()
}

const columns = computed(() => [
    {
        property: 'date_of_document',
        label: t('purchasesBill.columns.date'),
        sortable: true,
        sortDirection: 'desc',
    },
    {
        property: 'document_number',
        label: t('purchasesBill.columns.bill'),
    },
    // @TODO: Add reference number
    {
        property: 'vendor',
        label: t('purchasesBill.columns.vendorName'),
    },
    {
        property: 'status',
        label: t('purchasesBill.columns.status'),
    },
    {
        property: 'dueDate',
        label: t('purchasesBill.columns.dueDate'),
        hide: props.compact,
    },
    {
        property: 'amount',
        label: t('purchasesBill.columns.amount'),
    },
    {
        property: 'unpaid_amount',
        label: t('purchasesBill.columns.balanceDue'),
    },
])

const onRowClick = (id) => {
    return router.push({ name: 'purchases.bill.detail', params: { id } })
}
</script>
