<template>
    <div :class="containerClass">
        <base-icon
            :name="iconName"
            size="md"
            variant="inherit"
            :class="iconClass"
        />
        <div class="flex flex-1 flex-col">
            <slot>
                <slot name="before-content" />
                <slot name="title" v-bind="{ titleClass }">
                    <span
                        v-if="title"
                        class="mb-1 text-sm font-medium"
                        :class="titleClass"
                    >
                        {{ title }}
                    </span>
                </slot>

                <slot name="description" v-bind="{ classes: descriptionClass }">
                    <span
                        v-if="description"
                        class="text-sm font-normal"
                        :class="descriptionClass"
                    >
                        {{ description }}
                    </span>
                </slot>

                <slot name="after-content" />
            </slot>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    /**
     * Variant
     */
    variant: {
        type: String,
        default: 'success',
        required: false,
        validator(value) {
            return ['primary', 'danger', 'warning', 'success', 'gray'].includes(
                value
            )
        },
    },
    /**
     * Title
     */
    title: {
        type: String,
        required: false,
    },
    /**
     * Description
     */
    description: {
        type: String,
        required: false,
    },
})

const containerClass = computed(() => {
    const { variant } = props

    const colorData = {
        primary: 'border-primary-300 bg-primary-25',
        danger: 'border-danger-300 bg-danger-25',
        warning: 'border-warning-300 bg-warning-25',
        success: 'border-success-300 bg-success-25',
        gray: 'border-gray-300 bg-gray-25',
    }

    return ['flex flex-row gap-3 rounded-lg border p-4', colorData[variant]]
})

const iconName = computed(() => {
    const { variant } = props

    const iconNames = {
        primary: 'line-icons:general:info-circle',
        danger: 'line-icons:general:info-circle',
        warning: 'line-icons:alerts-&-feedback:alert-triangle',
        success: 'line-icons:general:check-circle',
        gray: 'line-icons:general:info-circle',
    }

    return iconNames[variant]
})

const iconClass = computed(() => {
    const { variant } = props

    const colorData = {
        primary: 'text-primary-600',
        danger: 'text-danger-600',
        warning: 'text-warning-600',
        success: 'text-success-600',
        gray: 'text-gray-600',
    }

    return colorData[variant]
})

const titleClass = computed(() => {
    const { variant } = props

    const colorData = {
        primary: 'text-primary-700',
        danger: 'text-danger-700',
        warning: 'text-warning-700',
        success: 'text-success-700',
        gray: 'text-gray-700',
    }

    return colorData[variant]
})

const descriptionClass = computed(() => {
    const { variant } = props

    const colorData = {
        primary: 'text-primary-600',
        danger: 'text-danger-600',
        warning: 'text-warning-600',
        success: 'text-success-600',
        gray: 'text-gray-600',
    }

    return colorData[variant]
})
</script>
