<template>
    <div
        :class="modeClass"
        class="inline-flex items-center rounded-2xl font-medium"
    >
        <img
            class="mr-2.5 h-8"
            :src="$filters.asset('static/images/logo.svg')"
            alt=""
        />
    </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
    label: {
        type: String,
        default: 'CY.TAX',
    },

    mode: {
        type: String,
        default: 'light',
        required: false,
        validator(value) {
            return ['light', 'dark'].includes(value)
        },
    },
})

const modeClass = computed(() => ({
    'text-white': props.mode === 'dark',
    'text-black': props.mode === 'light',
}))
</script>
