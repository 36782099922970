<template>
    <div
        class="relative flex cursor-pointer items-center justify-center px-1.5 py-1.5 text-gray-500 hover:text-primary-700"
        @click="toggleMenu"
    >
        <slot name="icons">
            <base-tooltip dark hover placement="top" :content="label">
                <div class="flex items-center justify-center">
                    <slot name="title">
                        <base-icon
                            v-if="icon"
                            :name="icon"
                            size="sm"
                            variant="inherit"
                        />
                        <base-icon
                            v-if="hasSubMenu"
                            name="line-icons:arrows:chevron-down"
                            size="xs"
                            variant="inherit"
                        />
                    </slot>
                </div>
            </base-tooltip>
        </slot>

        <div
            ref="headingDropdown"
            v-if="hasSubMenu && open"
            class="absolute left-0 top-4 z-10 mt-2 w-60 rounded-lg border border-gray-100 bg-white p-2 text-sm text-gray-700 shadow-md"
        >
            <slot />
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'

const headingDropdown = ref(null)

defineProps({
    label: {
        type: String,
        default: '',
    },
    icon: {
        type: String,
        default: '',
    },
    hasSubMenu: {
        type: Boolean,
        default: false,
    },
})

const open = ref(false)

const toggleMenu = () => {
    open.value = !open.value
}

const hideMenu = () => {
    open.value = false
}

onClickOutside(headingDropdown, hideMenu)
</script>
