<template>
    <div :class="containerClass">
        <label
            class="group relative flex cursor-pointer items-center gap-2"
            :class="labelClass"
        >
            <input
                v-bind="$attrs"
                v-model="value"
                type="checkbox"
                class="peer hidden"
            />
            <span
                :class="[checkboxVariantClasses, checkboxSizeClasses]"
                class="relative z-10 cursor-pointer border border-gray-300 bg-white transition-all duration-150 group-hover:bg-primary-100 peer-checked:border-primary-600 peer-checked:bg-primary-100 peer-focus:ring-4 peer-focus:ring-primary-100 peer-focus:ring-offset-0 peer-disabled:cursor-default peer-disabled:opacity-50"
            />
            <span
                :class="[checkboxSizeClasses, checkedClasses]"
                class="absolute z-10 flex cursor-pointer items-center justify-center"
            >
                <base-icon
                    v-if="['checkbox', 'check-circle'].includes(variant)"
                    name="line-icons:general:check-thick"
                    variant="inherit"
                    :size="checkboxIconSize"
                />

                <span
                    v-if="text && variant === 'text-circle'"
                    class="cursor-pointer text-sm font-medium peer-disabled:cursor-default peer-disabled:opacity-50"
                >
                    {{ text }}
                </span>
            </span>
            <slot name="text" v-bind="{ modelValue }">
                <span
                    v-if="text && variant !== 'text-circle'"
                    class="cursor-pointer text-sm font-medium text-gray-700 peer-disabled:cursor-default peer-disabled:opacity-50"
                >
                    {{ text }}
                </span>
            </slot>
        </label>

        <slot name="supportingText">
            <div v-if="supportingText" :class="subTextMargin">
                <p class="text-sm text-gray-500">
                    {{ supportingText }}
                </p>
            </div>
        </slot>
    </div>
</template>

<script>
// A normal <script> is needed to declare the inheritAttrs option
export default {
    inheritAttrs: false,
}
</script>

<script setup>
const props = defineProps({
    text: {
        type: String,
    },
    supportingText: {
        type: String,
    },
    labelClass: {
        type: [String, Array, Object],
        default: '',
    },
    containerClass: {
        type: [String, Array, Object],
        default: null,
    },
    size: {
        type: String,
        default: 'sm',
    },
    variant: {
        type: String,
        default: 'checkbox',
        validator(value) {
            return ['checkbox', 'check-circle', 'text-circle'].includes(value)
        },
    },
    modelValue: {
        type: [Boolean, Array],
        default: false,
    },
})

const checkboxVariantClasses = computed(() => ({
    rounded: props.variant === 'checkbox',
    'rounded-full': ['check-circle', 'text-circle'].includes(props.variant),
    'peer-checked:bg-primary-600': props.variant === 'text-circle',
}))

const checkboxSizeClasses = computed(() => ({
    'min-h-6 min-w-6': props.size === 'lg',
    'min-h-5 min-w-5': props.size === 'md',
    'min-h-4 min-w-4': props.size === 'sm',
}))

const checkedClasses = computed(() => ({
    'opacity-0 transition-all duration-150 peer-checked:text-primary-600 peer-checked:opacity-100 peer-disabled:cursor-default peer-disabled:peer-checked:opacity-50':
        ['checkbox', 'check-circle'].includes(props.variant),
    'peer-checked:text-white': props.variant === 'text-circle',
}))

const checkboxIconSize = computed(() => {
    const { size } = props

    const sizeData = {
        md: 'xs',
        sm: 'xxs',
    }

    return sizeData[size]
})

// Input handler
const emit = defineEmits(['update:modelValue'])
const value = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
    },
})

const subTextMargin = computed(() => {
    const { size } = props

    const sizeData = {
        md: 'ml-7',
        sm: 'ml-6',
    }

    return sizeData[size]
})
</script>
