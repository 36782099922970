<template>
    <base-modal
        v-if="invoice"
        close
        @modal-close="closeModal"
        :show="show"
        size="xxl"
        :content-padding="false"
        :custom-content-class="{
            'min-h-[300px]': true,
        }"
    >
        <template #modal-header>
            <span class="text-lg font-medium text-gray-900">
                {{ title }}
            </span>
        </template>

        <div :class="{ 'min-h-[365px]': differenceAmountLabel }">
            <div class="grid grid-cols-7 gap-5 border-b px-6 pb-3">
                <div>
                    <p class="text-sm font-medium text-gray-900">
                        {{ $t('transaction.bookPaymentModal.invoiceNumber') }}
                    </p>
                    <span
                        v-if="invoice.sale_document?.number"
                        class="text-sm text-gray-500"
                        >{{ invoice.sale_document?.number }}
                    </span>
                </div>

                <div>
                    <p class="text-sm font-medium text-gray-900">
                        {{ $t('transaction.bookPaymentModal.customer') }}
                    </p>
                    <span class="text-sm text-gray-500">{{ contactName }}</span>
                </div>

                <div>
                    <p class="text-sm font-medium text-gray-900">
                        {{ $t('transaction.bookPaymentModal.dateOfInvoice') }}
                    </p>
                    <span
                        v-if="invoice.sale_document?.date_of_invoice"
                        class="text-sm text-gray-500"
                        >{{
                            $filters.dateShortFormat(
                                invoice.sale_document.date_of_invoice
                            )
                        }}</span
                    >
                </div>

                <div class="col-span-2">
                    <p class="text-sm font-medium text-gray-900">
                        {{ $t('transaction.bookPaymentModal.unpaidAmount') }}
                    </p>
                    <span
                        class="text-sm font-semibold text-success-600"
                        v-if="invoice"
                    >
                        {{
                            $filters.currency(
                                invoice.sale_document.amount_gross_total,
                                invoice.sale_document.currency.iso
                            )
                        }}
                        /
                        {{
                            $filters.currency(
                                invoice.sale_document.unpaid_amount,
                                invoice.sale_document.currency.iso
                            )
                        }}</span
                    >
                </div>

                <div class="col-span-2">
                    <form-single-select
                        v-model="paymentAccount"
                        :error-message="errorPaymentAccount"
                        :options="paymentAccounts"
                        :show-clear="false"
                        option-label="name"
                        option-value="id"
                        :placeholder="
                            $t(
                                'transaction.bookPaymentModal.paymentAccountPlaceholder'
                            )
                        "
                    />
                </div>
            </div>

            <template
                v-if="selectedPaymentAccount?.type === ACCOUNT_TYPES.ONLINE"
            >
                <div class="p-6">
                    <transaction-filter-panel @filter-update="onFilterUpdate" />
                </div>

                <div>
                    <transaction-payment-list
                        :payment-account-id="paymentAccount"
                        @transaction-select="onTransactionSelect"
                        :queries="filterQueries"
                    />
                </div>
            </template>

            <template v-else>
                <div class="flex w-full justify-end border-b p-6">
                    <div class="flex w-1/2 gap-5">
                        <form-date-picker
                            size="md"
                            :label="
                                $t(
                                    'transaction.bookPaymentModal.paymentDateLabel'
                                )
                            "
                            single
                            v-model="paymentDate"
                            :error-message="errorPaymentDate"
                            :full-width="true"
                            auto-apply
                        />

                        <form-number-input
                            :label="
                                $t('transaction.bookPaymentModal.amountLabel')
                            "
                            size="md"
                            v-model="amount"
                            :error-message="errorAmount"
                            :precision="2"
                            :block="true"
                        />
                    </div>
                </div>
            </template>

            <div
                class="flex border-b px-6 pb-8 pt-2.5"
                v-if="differenceAmountLabel"
            >
                <form-single-select class="max-w-[280px]" :options="reasonList">
                    <template #label>
                        <div
                            class="mb-2 block text-sm font-medium text-gray-700"
                            v-html="differenceAmountLabel"
                        ></div>
                    </template>
                </form-single-select>
            </div>
        </div>

        <template #modal-footer>
            <div class="flex justify-end gap-3">
                <base-button
                    outline
                    variant="default"
                    :disabled="isBookingPayment"
                    @click="closeModal"
                >
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button
                    :disabled="
                        !paymentAccount ||
                        (!paymentTransaction &&
                            selectedPaymentAccount?.type ===
                                ACCOUNT_TYPES.ONLINE)
                    "
                    :loading="isBookingPayment"
                    @click="onSubmit"
                >
                    {{ $t('transaction.bookPaymentModal.bookBtn') }}
                </base-button>
            </div>
        </template>
    </base-modal>

    <transaction-book-close-modal
        :code="code"
        :date="parameters?.date"
        @modal-close="code = null"
    />
</template>

<script setup>
import {
    ACCOUNT_TYPES,
    CONTACT_RELATIONSHIP_TYPES,
    DATE_SERVER_FORMAT,
} from '@tenant/utils/constants'
import { dayjsFormat, now } from '@tenant/utils/day'
import { currency } from '@tenant/utils/helper'

const emit = defineEmits(['modal-close', 'modal-confirm'])

const props = defineProps({
    show: {
        type: Boolean,
        default: true,
    },
    title: {
        type: String,
        default: '',
    },
    invoice: {
        type: [Object, null],
        default: () => ({}),
    },
})

const closeModal = () => {
    emit('modal-close')
}

const { handleSubmit, setErrors } = useForm()

const {
    value: paymentDate,
    errorMessage: errorPaymentDate,
    resetField: resetPaymentDate,
} = useField('payment_date')
const {
    value: amount,
    errorMessage: errorAmount,
    resetField: resetAmount,
} = useField('amount', undefined, {
    initialValue: props.invoice.sale_document?.unpaid_amount ?? 0,
})
const { value: paymentAccount, errorMessage: errorPaymentAccount } =
    useField('payment_account_id')
const { value: paymentTransaction } = useField('payment_account_transaction_id')

// PAYMENT ACCOUNTS
const { paymentAccounts } = usePaymentAccounts(
    props.invoice.sale_document.currency_id
)

watch(
    () => paymentAccounts.value,
    () => {
        if (paymentAccounts.value.length) {
            paymentAccount.value = paymentAccounts.value[0].id
        }
    },
    { immediate: true }
)

const selectedPaymentAccount = computed(() => {
    return paymentAccounts.value.find(
        (item) => item.id === paymentAccount.value
    )
})

// Handle set payment date and amount when payment account is changed
watch(
    () => selectedPaymentAccount.value,
    (value) => {
        if ([ACCOUNT_TYPES.BANK_FEED].includes(value.type)) {
            amount.value = props.invoice.sale_document?.unpaid_amount ?? 0
            paymentDate.value = dayjsFormat(now(), DATE_SERVER_FORMAT)
        } else {
            resetAmount()
            resetPaymentDate()
        }
    }
)
// END: PAYMENT ACCOUNTS

const contactName = computed(() => {
    const { contact } = props.invoice.sale_document ?? {}
    if (!contact) return null

    return contact.type === CONTACT_RELATIONSHIP_TYPES.person
        ? `${contact.first_name} ${contact.last_name}`
        : contact.company
})

const onTransactionSelect = (value) => {
    paymentTransaction.value = value
}

// HANDLE BOOK PAYMENT
const isBookingPayment = ref(false)
const {
    execute: bookIncomingPayment,
    code,
    parameters,
} = useApi('/api/book-incoming-payment', 'POST')

const onSubmit = handleSubmit(async (values) => {
    try {
        isBookingPayment.value = true

        await bookIncomingPayment({
            data: {
                booked_id: props.invoice.id,
                ...values,
            },
        })

        emit('modal-confirm')
    } catch ({ errors }) {
        setErrors(errors)
    } finally {
        isBookingPayment.value = false
    }
})
// END: HANDLE BOOK PAYMENT

// REASON FOR DIFFERENCE
const { t } = useI18n()

const reasonList = ref([
    {
        label: t(
            'transaction.bookPaymentModal.reasonOfDifference.cashDiscount'
        ),
        value: 'cash_discount',
    },
    {
        label: t(
            'transaction.bookPaymentModal.reasonOfDifference.partialPayment'
        ),
        value: 'partial',
    },
    {
        label: t(
            'transaction.bookPaymentModal.reasonOfDifference.transactionCost'
        ),
        value: 'transaction_cost',
    },
    {
        label: t('transaction.bookPaymentModal.reasonOfDifference.other'),
        value: 'other',
    },
])
const differenceAmountLabel = computed(() => {
    if (!amount.value || !props.invoice.sale_document?.unpaid_amount)
        return null
    const difference = amount.value - props.invoice.sale_document?.unpaid_amount
    if (difference >= 0) return null

    const labelCls = difference > 0 ? 'text-success-600' : 'text-danger-600'
    return t('transaction.bookPaymentModal.differenceReasonLabel', {
        difference: `<span class="${labelCls}">${currency(
            difference,
            props.invoice.sale_document.currency.iso
        )}:</span>`,
    })
})
// END: REASON FOR DIFFERENCE

const filterQueries = ref(null)
const onFilterUpdate = (values) => {
    filterQueries.value = values
}
</script>
