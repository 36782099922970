<template>
    <div :class="containerClass">
        <slot v-if="$slots['header']" name="header" />

        <ag-grid-vue
            :suppressDragLeaveHidesColumns="true"
            :columnDefs="colDefs"
            dom-layout="autoHeight"
            class="ag-theme-quartz"
            v-bind="rest"
        />
    </div>
</template>

<script lang="ts">
export default {
    inheritAttrs: false,
}
</script>

<script setup lang="ts">
import type { Component } from 'vue'
import { AgGridVue } from '@ag-grid-community/vue3'
import { ColDef, ICellRendererParams } from '@ag-grid-community/core'
import { ModuleRegistry } from '@ag-grid-community/core'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'

import DataAgGridCell from '@tenant/components/data-ag-grid/data-ag-grid/data-ag-grid-cell.vue'

import '@ag-grid-community/styles/ag-grid.css'
import '@ag-grid-community/styles/ag-theme-quartz.css'

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ServerSideRowModelModule,
])

const { class: containerClass, ...rest } = useAttrs()

export type ICellParams<TData> = ICellRendererParams<TData> & {
    data: TData
}

/**
 * const colDefs = ref<Column[]>([
 *     {
 *         field: 'make',
 *         render: {
 *             component: BaseBadge,
 *             props: {
 *                 label: 'test',
 *             },
 *             on: {
 *                 click: () => {},
 *             },
 *         },
 *     },
 *     ...
 * ])
 */
export interface AgGridColumn extends ColDef {
    render?: {
        component?: Component
        props?: object
        on?: object
    }
}

export interface Props {
    dataSource: any[]
    columns: AgGridColumn[]
}

const props = defineProps<Props>()

const colDefs = computed(() =>
    props.columns.map((col) => {
        if (col.render) {
            return {
                sortable: false,
                cellRenderer: DataAgGridCell,
                cellRendererParams: {
                    component: col.render.component,
                    props: col.render.props,
                    on: col.render.on,
                },
                ...col,
            }
        }

        return {
            sortable: false,
            ...col,
        }
    })
)
</script>

<style lang="scss">
.ag-theme-quartz {
    --ag-border-color: theme('colors.gray.300');
    --ag-wrapper-border-radius: theme('borderRadius.md');
    --ag-header-column-resize-handle-height: 100%;
    --ag-header-column-resize-handle-width: 1px;
}

.ag-root-wrapper {
    @apply rounded-tl-none rounded-tr-none border-t-0;
}

.ag-row .ag-cell {
    @apply flex items-center;
}
</style>
