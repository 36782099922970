<template>
    <div class="relative mb-6 border-t">
        <div class="absolute left-0 right-0 top-[-14px] mx-auto text-center">
            <span class="bg-white px-2 text-sm text-gray-500">{{
                props.text
            }}</span>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    text: {
        type: String,
        default: '',
    },
})
</script>
