<template>
    <div class="flex w-full min-w-[300px] flex-col">
        <form-control-label :label="label" />

        <slot>
            <div class="flex gap-2.5">
                <form-number-input
                    :label="!label ? $t('filters.from') : ''"
                    :placeholder="label ? $t('filters.from') : ''"
                    v-model="filterFromValue"
                    :size="size"
                />

                <form-number-input
                    :label="!label ? $t('filters.to') : ''"
                    :placeholder="label ? $t('filters.to') : ''"
                    v-model="filterToValue"
                    :size="size"
                />
            </div>
        </slot>
    </div>
</template>
<script setup>
const props = defineProps({
    queryKey: {
        type: String,
        required: true,
    },
    queryValue: {
        type: String,
        required: true,
    },
    size: {
        type: String,
        default: 'md',
        validator(value) {
            return ['sm', 'md'].includes(value)
        },
    },
    label: {
        type: String,
        default: '',
    },
    isNegative: {
        type: Boolean,
        default: false,
    },
})

const queries = {
    [props.queryKey]: props.queryValue,
}

const { useRangeFilter } = useFilters(queries)
const { fromValue, toValue } = useRangeFilter(props.queryKey, props.queryValue)

const filterFromValue = computed({
    get() {
        return props.isNegative ? Math.abs(toValue.value) : fromValue.value
    },
    set(value) {
        if (props.isNegative) {
            toValue.value = value * -1
        } else {
            fromValue.value = value
        }
    },
})

const filterToValue = computed({
    get() {
        return props.isNegative ? Math.abs(fromValue.value) : toValue.value
    },
    set(value) {
        if (props.isNegative) {
            fromValue.value = value * -1
        } else {
            toValue.value = value
        }
    },
})
</script>
