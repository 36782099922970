<template>
    <data-grid
        :columns="columns"
        :data-source="dataSource"
        :table-borderless="tableBorderless"
        :empty-sub="emptySub"
        :empty-text="emptyText"
        :show-table-header="showTableHeader"
    >
        <template #outer-header v-if="$slots['outer-header']">
            <slot name="outer-header" />
        </template>

        <template #header v-if="$slots['header']">
            <slot name="header" />
        </template>

        <template #filter v-if="$slots['filter']">
            <slot name="filter" />
        </template>

        <template #body>
            <div style="height: 245px; overflow: auto" ref="el">
                <table
                    :class="[
                        'border-gray-20 w-full',
                        { 'border-t': $slots['header'] },
                        { 'border-b ': $slots['footer'] },
                    ]"
                >
                    <data-grid-header-row
                        v-if="showTableHeader"
                        :border="borderTableHeader"
                    >
                        <data-grid-header-cell
                            v-if="selectable"
                            checkBox
                            sortable
                        >
                            <form-checkbox v-model="isSelectedAll" />
                        </data-grid-header-cell>

                        <data-grid-header-cell
                            v-for="(column, columnIndex) in currentColumns"
                            :key="column.property"
                            :sortable="column.sortable"
                            :checkBox="column.checkBox"
                            @click="onChangeSort(column)"
                            :class="[
                                'sticky top-0 z-10',
                                column.headerCellClass,
                                {
                                    'rounded-tl-lg':
                                        !$slots['header'] &&
                                        isFirstItem(columnIndex),
                                    'rounded-tr-lg':
                                        (!$slots['header'] &&
                                            $slots['column-action']) ||
                                        isLastItem(columnIndex, currentColumns),
                                },
                            ]"
                            :center="column.center"
                            :end="column.end"
                        >
                            <slot
                                :name="`header-${column.property}`"
                                v-bind="{ column, columnIndex }"
                            >
                                <template v-if="column.checkBox">
                                    <form-checkbox
                                        v-model="data.isActive"
                                        variant="checkbox"
                                        @click="onChangeCheckBox(data.isActive)"
                                    />
                                </template>
                                <template v-if="column.iconRight">
                                    <base-icon
                                        :name="column.iconRight"
                                        size="sm"
                                        class="mr-2"
                                        variant="primary"
                                    />
                                </template>
                                <!-- TODO: AC-271 - Add checkbox for the first table data-grid table header. -->
                                {{ column.label }}
                                <template v-if="column.iconLeft">
                                    <base-icon
                                        :name="column.iconLeft"
                                        size="sm"
                                        class="ml-2"
                                        variant="gray"
                                    />
                                </template>
                                <template v-if="column.sortable">
                                    <base-icon
                                        v-if="column.sortDirection === 'desc'"
                                        name="line-icons:arrows:arrow-down"
                                        size="sm"
                                        variant="gray"
                                    />
                                    <base-icon
                                        v-else
                                        name="line-icons:arrows:arrow-up"
                                        size="sm"
                                        variant="gray"
                                    />
                                </template>
                                <base-icon
                                    v-if="column.tooltip"
                                    name="line-icons:general:help-circle"
                                    class="ml-2"
                                    size="sm"
                                    variant="gray"
                                />
                            </slot>
                        </data-grid-header-cell>
                        <data-grid-header-cell
                            v-if="$slots['column-action']"
                            :class="{ 'rounded-tr-lg': !$slots['header'] }"
                        >
                            <slot name="header-action" />
                        </data-grid-header-cell>
                    </data-grid-header-row>

                    <slot name="additional-top-row" />

                    <template v-if="records && records.length">
                        <template
                            v-for="(item, itemIndex) in records"
                            :key="item.id"
                        >
                            <data-grid-row
                                class="cursor-pointer"
                                :class="
                                    isShowExpended(item, itemIndex)
                                        ? [expandedRowClasses, '!bg-gray-100']
                                        : ''
                                "
                            >
                                <data-grid-cell
                                    v-if="selectable"
                                    :key="`checkbox-${itemIndex}`"
                                >
                                    <form-checkbox
                                        v-model="selectedItems"
                                        :value="item"
                                    />
                                </data-grid-cell>

                                <data-grid-cell
                                    v-for="(
                                        column, columnIndex
                                    ) in currentColumns"
                                    :key="column.property"
                                    :class="[
                                        column.dataCellClass,
                                        {
                                            'rounded-bl-lg':
                                                !tableBorderless &&
                                                !$slots['footer'] &&
                                                isLastItem(
                                                    itemIndex,
                                                    records
                                                ) &&
                                                isFirstItem(columnIndex),
                                            'rounded-br-lg':
                                                !tableBorderless &&
                                                !$slots['footer'] &&
                                                !$slots['column-action'] &&
                                                isLastItem(
                                                    itemIndex,
                                                    records
                                                ) &&
                                                isLastItem(
                                                    columnIndex,
                                                    currentColumns
                                                ),
                                        },
                                    ]"
                                    :center="column.center"
                                    :end="column.end"
                                    @click="
                                        column?.suppressRowClick
                                            ? false
                                            : openContent(item, itemIndex)
                                    "
                                >
                                    <slot
                                        :name="`column-${column.property}`"
                                        v-bind="{
                                            item,
                                            itemIndex,
                                            column,
                                            columnIndex,
                                            isShowExpended: isShowExpended(
                                                item,
                                                itemIndex
                                            ),
                                        }"
                                    >
                                        <span
                                            :class="{
                                                'font-bold text-gray-700':
                                                    item.isSummary,
                                            }"
                                            >{{ item[column.property] }}</span
                                        >
                                    </slot>
                                </data-grid-cell>

                                <data-grid-action-cell
                                    v-if="$slots['column-action']"
                                    :class="{
                                        'rounded-br-lg':
                                            $slots['column-action'] &&
                                            !tableBorderless,
                                    }"
                                >
                                    <slot
                                        name="column-action"
                                        v-bind="{ item, itemIndex }"
                                    />
                                </data-grid-action-cell>
                            </data-grid-row>

                            <data-grid-row
                                v-if="
                                    isShowExpended(item, itemIndex) &&
                                    hasExpandableRows
                                "
                            >
                                <slot
                                    name="row-extra-content-container"
                                    v-bind="{
                                        closeContent,
                                        item,
                                        itemIndex,
                                        currentColumns,
                                    }"
                                >
                                    <data-grid-cell
                                        :colspan="currentColumns.length + 1"
                                        class="relative bg-gray-100"
                                    >
                                        <div
                                            class="absolute right-6 top-4 z-10"
                                        >
                                            <base-button
                                                variant="default"
                                                class="border-0 focus:ring-0"
                                                icon="line-icons:general:x"
                                                @click="closeContent"
                                            ></base-button>
                                        </div>
                                        <slot
                                            name="row-extra-content"
                                            v-bind="{ item, itemIndex }"
                                        />
                                    </data-grid-cell>
                                </slot>
                            </data-grid-row>
                        </template>
                    </template>

                    <slot name="additional-bottom-row" />
                </table>
                <base-loading
                    v-if="loading"
                    class="flex items-center justify-center p-2.5"
                />

                <data-grid-empty
                    v-if="(!records || !records.length) && !loading"
                    :title="emptyText"
                    :sub="emptySub"
                    :border="!tableBorderless"
                >
                    <template #default>
                        <slot name="empty" />
                    </template>

                    <template #actions>
                        <slot name="empty-actions" />
                    </template>
                </data-grid-empty>
            </div>
        </template>

        <template #footer v-if="$slots['footer']">
            <slot name="footer" />
        </template>
    </data-grid>
</template>

<script setup>
import { useInfiniteScroll } from '@vueuse/core'
const emit = defineEmits(['row-click', 'on-items-selected', 'load-more'])

const data = {
    isActive: false,
}

const props = defineProps({
    dataSource: {
        type: Array,
        required: true,
    },
    columns: {
        type: Array,
        required: true,
    },
    tableBorderless: {
        type: Boolean,
        default: false,
        required: false,
    },
    showTableHeader: {
        type: Boolean,
        default: true,
        required: false,
    },
    borderTableHeader: {
        type: Boolean,
        default: false,
        required: false,
    },
    loading: {
        type: Boolean,
        default: false,
        required: false,
    },
    selectable: {
        type: Boolean,
        default: false,
        required: false,
    },
    hasExpandableRows: {
        type: Boolean,
        default: false,
        required: false,
    },
    emptyText: {
        type: String,
        default: '',
    },
    emptySub: {
        type: String,
        default: '',
    },
    identifyField: {
        type: String,
        required: false,
        default: null,
    },
    preSelectedItems: {
        type: Array,
        required: false,
        default: () => [],
    },
    summaryData: {
        type: Array,
        default: () => [],
    },
    expandedRowClasses: {
        type: String,
        default: '',
    },
})

const isSelectedAll = ref(false)
const selectedItems = ref([])
const selectedExpended = ref(null)
const records = computed(() => {
    const summaryRecords = props.summaryData.map((item) => {
        return {
            ...item,
            isSummary: true,
        }
    })
    return [...props.dataSource, ...summaryRecords]
})
const currentColumns = ref(props.columns)

watch(
    () => props.preSelectedItems,
    (value) => {
        selectedItems.value = value
    }
)

watch(
    () => selectedItems.value,
    (value) => {
        emit('on-items-selected', value)
    }
)

watch(
    () => isSelectedAll.value,
    (value) => {
        if (value) {
            selectedItems.value = records.value
        } else {
            selectedItems.value = []
        }
    }
)

const isFirstItem = (index) => {
    return index === 0
}

const isLastItem = (index, list) => {
    return index === list.length - 1
}

const isShowExpended = (item, itemIndex) => {
    if (props.identifyField) {
        return item[props.identifyField] === selectedExpended.value
    }

    return itemIndex === selectedExpended.value
}

const openContent = (item, itemIndex) => {
    let checkingValue = itemIndex
    if (props.identifyField) {
        checkingValue = item[props.identifyField]
    }

    if (checkingValue === selectedExpended.value) {
        selectedExpended.value = null
    } else {
        selectedExpended.value = checkingValue
    }

    emit(
        'row-click',
        selectedExpended.value,
        isShowExpended(item, itemIndex) ? item : null
    )
}

const closeContent = () => {
    selectedExpended.value = null
}

defineExpose({
    closeContent,
})

// INFINITE SCROLL
const el = ref(null)
useInfiniteScroll(
    el,
    () => {
        emit('load-more')
    },
    { distance: 10 }
)
// END: INFINITE SCROLL
</script>
