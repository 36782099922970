<template>
    <div class="flex h-full flex-col">
        <!-- Header -->
        <div class="flex justify-between border-b p-6 pb-4">
            <div>
                <div class="mb-1 text-xl font-medium">
                    {{ $t('chat.chatFeed.title') }}
                </div>
                <span class="text-sm text-gray-500">
                    {{ $t('chat.chatFeed.description') }}
                </span>
            </div>

            <base-icon
                class="cursor-pointer"
                name="line-icons:general:x"
                variant="gray"
                @click="onClose"
            />
        </div>

        <div class="flex grow flex-col overflow-auto">
            <partial-chat-feed-items
                :group-id="groupId"
                @load-chat="onLoadChat"
            />
        </div>

        <!-- Footer form -->
        <div class="flex justify-center p-6">
            <base-button @click="onNewChat">
                {{ $t('chat.chatFeed.sendMessage') }}
                <base-icon
                    name="line-icons:arrows:chevron-right"
                    variant="inherit"
                />
            </base-button>
        </div>
    </div>
</template>

<script setup>
const emit = defineEmits(['new-chat', 'load-chat', 'close'])

defineProps({
    groupId: {
        type: String,
        default: null,
    },
})

const onNewChat = () => {
    emit('new-chat')
}

const onClose = () => {
    emit('close')
}

const onLoadChat = (conversation) => {
    emit('load-chat', conversation)
}
</script>
