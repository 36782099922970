<template>
    <span
        :class="avatarClasses"
        class="relative flex items-center justify-center rounded-full bg-gray-200 focus:ring-primary-100"
    >
        <base-icon
            v-if="variant === 'placeholder'"
            class="h-1/2 w-1/2"
            :size="size"
            name="line-icons:users:user-01"
        />

        <base-img
            v-if="variant === 'default' && user?.avatar"
            :src="user?.avatar?.path"
            alt="Avatar"
            :class="avatarClasses"
            class="rounded-full object-cover"
            :loading-size="size"
        />
        <span v-else class="font-normal" :class="shortNameClass">
            {{ shortName }}
        </span>

        <span v-if="variant === 'text'" class="absolute text-center">
            {{ text }}
        </span>

        <base-icon
            v-if="statusIcon"
            :name="statusIcon"
            :class="statusIconClasses"
            :size="size"
            class="absolute bottom-0"
        />
    </span>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
    size: {
        type: String,
        default: 'md',
        validator(value) {
            return ['xxs', 'xs', 'sm', 'md', 'lg', 'xl', '2xl'].includes(value)
        },
    },

    statusIcon: {
        type: String,
    },

    variant: {
        type: String,
        default: 'default',
    },

    text: {
        type: String,
        default: 'OR',
    },

    user: {
        type: Object,
        default: null,
    },
})

const shortName = computed(() =>
    [
        Array.from(props.user?.first_name ?? '')[0],
        Array.from(props.user?.last_name ?? '')[0],
    ]
        .filter(Boolean)
        .join('')
)

const avatarClasses = computed(() => ({
    'min-h-4 h-4 w-4 min-w-4 text-xs': props.size === 'xxs',
    'min-h-6 h-6 w-6 min-w-6 text-xs': props.size === 'xs',
    'min-h-8 h-8 w-8 min-w-8 text-sm': props.size === 'sm',
    'min-h-10 h-10 w-10 min-w-10 text-md': props.size === 'md',
    'min-h-12 h-12 w-12 min-w-12 text-lg': props.size === 'lg',
    'min-h-14 h-14 w-14 min-w-14 text-xl': props.size === 'xl',
    'min-h-16 h-16 w-16 min-w-16 text-2xl': props.size === '2xl',
}))

const statusIconClasses = computed(() => ({
    'max-w-1 max-h-1 right-0': props.size === 'xxs',
    'max-w-2.5 max-h-2.5 right-0': props.size === 'xs',
    'max-w-3 max-h-3 right-0': props.size === 'sm',
    'max-w-3.5 max-h-3.5 right-0': props.size === 'md',
    'max-w-4 max-h-4 right-0': props.size === 'lg',
    'max-w-5 max-h-5 right-0.5': props.size === 'xl',
    'max-h-6 max-w-6 right-0.5': props.size === '2xl',
}))

const shortNameClass = computed(() => {
    if (props.size === 'xxs') {
        return 'text-xxs'
    }

    if (props.size === 'xs') {
        return 'text-xxs'
    }

    return 'text-sm'
})
</script>

<style scoped>
.sas-icon::v-deep path {
    stroke: transparent;
}
</style>
