<template>
    <form-rich-text-editor ref="editorRef">
        <template #toolbar-container-bottom>
            <form-rich-text-toolbar-container v-if="selectedTemplate">
                <form-rich-text-toolbar-group>
                    <form-rich-text-toolbar-button
                        :label="
                            $t('settingsTextTemplates.general.placeholderLabel')
                        "
                        @click="onToggleOpenPlaceholder"
                    >
                        <template #title>
                            <span class="text-sm font-medium">
                                {{
                                    $t(
                                        'settingsTextTemplates.general.placeholderLabel'
                                    )
                                }}
                            </span>
                        </template>
                    </form-rich-text-toolbar-button>
                </form-rich-text-toolbar-group>
                <form-rich-text-toolbar-group>
                    <form-rich-text-toolbar-button
                        :label="
                            $t('settingsTextTemplates.general.templateLabel')
                        "
                        @click="onToggleOpenTemplate"
                    >
                        <template #title>
                            <span class="text-sm font-medium">
                                {{
                                    $t(
                                        'settingsTextTemplates.general.templateLabel'
                                    )
                                }}
                            </span>
                        </template>
                    </form-rich-text-toolbar-button>
                </form-rich-text-toolbar-group>
            </form-rich-text-toolbar-container>

            <base-placeholder-modal
                :show="openPlaceholder"
                :template-type="templateType"
                :template-technical-type="templateTechnicalType"
                :template-line-item-type="templateLineItemType"
                @modal-close="onToggleOpenPlaceholder"
                @on-insert="onInsertPlaceholder"
            />

            <base-text-template-modal
                :show="openTemplate"
                :template-type="templateType"
                :template-technical-type="templateTechnicalType"
                :template-line-item-type="templateLineItemType"
                @modal-close="onToggleOpenTemplate"
                @on-insert="onInsertTemplate"
            />
        </template>
    </form-rich-text-editor>
</template>

<script setup>
import { ref, watch } from 'vue'

const props = defineProps({
    templateType: {
        type: [Number, String],
        required: false,
    },
    templateTechnicalType: {
        type: [Number, String],
        required: false,
    },
    templateLineItemType: {
        type: [Number, String],
        required: false,
    },
})

const editorRef = ref(null)
const openPlaceholder = ref(false)
const openTemplate = ref(false)
const selectedTemplate = ref(false)

watch(
    () => props.templateType,
    (templateType) => {
        selectedTemplate.value = !!templateType
    },
    { immediate: true }
)

const onToggleOpenPlaceholder = () => {
    openPlaceholder.value = !openPlaceholder.value
}

const onToggleOpenTemplate = () => {
    openTemplate.value = !openTemplate.value
}

const onInsertPlaceholder = (placeholders) => {
    if (!placeholders) {
        return
    }

    for (const placeholder of placeholders) {
        editorRef.value.editor.commands.insertContent(placeholder.value)
    }
}

const onInsertTemplate = (content) => {
    if (!content) {
        return
    }

    editorRef.value.editor.commands.insertContent(content.content)
}
</script>
