<template>
    <div
        ref="formControlRef"
        class="flex flex-col"
        :class="[block ? 'w-full' : null, containerClass]"
    >
        <slot name="label">
            <form-control-label :label="label" />
        </slot>

        <slot
            v-if="position === 'top'"
            name="option-list"
            v-bind="{
                isOpenSelection,
                results,
                currentOptions,
                optionValue,
                optionLabel,
                onClickOption,
                inputValue,
            }"
        >
            <form-control-option-list
                v-if="isOpenSelection"
                :results="results"
                :position="position"
                :options="currentOptions"
                :option-value="optionValue"
                :option-label="optionLabel"
                @select-option="onClickOption"
            >
                <template #option="{ item, index, selected }">
                    <slot name="option" v-bind="{ item, index, selected }" />
                </template>

                <template #option-label="{ item, index, selected }">
                    <slot
                        name="option-label"
                        v-bind="{ item, index, selected }"
                    />
                </template>

                <template #create>
                    <slot name="create" />
                </template>
            </form-control-option-list>
        </slot>

        <div
            class="relative flex gap-2 rounded-lg border outline-0"
            :class="containerClasses"
        >
            <form-control-highlight v-if="highlight" />

            <div v-if="iconName" class="pointer-events-none flex items-center">
                <base-icon :name="iconName" variant="gray" size="md" />
            </div>

            <div class="flex max-w-full grow items-center gap-x-1.5 gap-y-1">
                <slot name="before-input" v-bind="{ results }" />

                <input
                    ref="input"
                    class="w-full min-w-0 max-w-full grow border-0 p-0 text-md font-normal text-gray-900 placeholder-gray-500 outline-0 focus:ring-0 disabled:pointer-events-none disabled:cursor-not-allowed disabled:bg-transparent"
                    :class="[
                        {
                            'text-gray-500': disabled,
                            'placeholder-gray-900': !!selectionLabel,
                            [inputTextStyle]: true,
                        },
                        inputClass,
                    ]"
                    v-bind="attrs"
                    v-model="inputValue"
                    :disabled="disabled"
                    :placeholder="inputPlaceholder"
                    @input="onTypeInput"
                    @focus="openSelection"
                    @keyup.enter="onEnter"
                />

                <slot name="badge" />
            </div>

            <div
                v-if="tooltip && !errorMessage"
                class="pointer-events-none flex items-center"
            >
                <base-icon
                    name="line-icons:general:help-circle"
                    variant="grayLight"
                    size="sm"
                />
            </div>

            <form-control-icon
                v-if="!disabled"
                class="absolute right-2 top-3 text-gray-500"
                :class="controlIconClass"
                :is-multiple="false"
                :is-selected="!!modelValue && showClear"
                :is-open="isOpenSelection"
                @clear-option="onClearResults"
                @open-selection-list="openSelection"
                @close-selection-list="closeSelection"
            />
        </div>

        <slot
            v-if="position === 'bottom'"
            name="option-list"
            v-bind="{
                isOpenSelection,
                results,
                currentOptions,
                optionValue,
                optionLabel,
                onClickOption,
                inputValue,
            }"
        >
            <form-control-option-list
                v-if="isOpenSelection"
                :results="results"
                :position="position"
                :options="currentOptions"
                :option-value="optionValue"
                :option-label="optionLabel"
                @select-option="onClickOption"
            >
                <template #option="{ item, index, selected }">
                    <slot name="option" v-bind="{ item, index, selected }" />
                </template>

                <template #option-label="{ item, index, selected }">
                    <slot
                        name="option-label"
                        v-bind="{ item, index, selected }"
                    />
                </template>

                <template #option-icon="{ item, index, selected }">
                    <slot
                        name="option-icon"
                        v-bind="{ item, index, selected }"
                    />
                </template>

                <template #create>
                    <slot name="create" />
                </template>
            </form-control-option-list>
        </slot>

        <slot name="after-options" />

        <form-control-hint-text
            v-if="!hideMessage"
            :hint-text="hintText"
            :error-message="errorMessage"
        />
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
}
</script>

<script setup>
import { computed, ref, watch, useAttrs, watchEffect } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { useI18n } from 'vue-i18n'
import { useSelector } from '@tenant/composables'

const attrs = useAttrs()
const emit = defineEmits([
    'update:modelValue',
    'change',
    'on-close',
    'on-open',
    'on-input',
    'on-enter',
])

const props = defineProps({
    /**
     * Label displayed above the form field
     */
    label: {
        type: String,
        default: '',
    },
    position: {
        label: String,
        default: 'bottom',
        validator(value) {
            return ['bottom', 'top'].includes(value)
        },
    },
    /**
     * If there is no option selected, a placeholder will show
     */
    placeholder: {
        type: String,
        default: () => {
            const { t } = useI18n()

            return t('general.chooseValueLabel')
        },
    },
    /**
     * Short help text that goes below the input field
     */
    hintText: {
        type: String,
        default: '',
    },
    /**
     * A floating, non-actionable label used to explain an input
     */
    tooltip: {
        type: String,
        default: '',
    },
    /**
     * Error message in red shown below the input field when it's destructive
     */
    errorMessage: {
        type: String,
        default: '',
    },
    /**
     * Set the amount of padding in the input
     */
    size: {
        type: String,
        default: 'md',
        validator(value) {
            return ['sm', 'md'].includes(value)
        },
    },
    /**
     * Highlight dot and outline in yellow shown above the input field
     */
    highlight: {
        type: Boolean,
        default: false,
    },
    /**
     * Name of the icon displayed on the left corner of the input
     */
    iconName: {
        type: String,
        default: '',
    },
    /**
     * The options that you can choose from when you click on the input
     */
    options: {
        type: Array,
        default: () => [],
    },
    /**
     * The returned string value is what is internally used within component to validate the following:
     *
     * - Which options are matched to a search term (searchableFields)
     * - Which options are already selected
     */
    optionValue: {
        type: String,
        default: 'value',
    },
    /**
     * The returned string value is the string that's rendered:
     *
     * - As the label for options in the menu
     * - As the label for value(s) in the control.
     */
    optionLabel: {
        type: [String, Function],
        default: 'label',
    },
    /**
     * Option property that can be search
     */
    searchableFields: {
        type: Array,
        default: () => ['value', 'label'],
    },
    /**
     * Component model value
     */
    modelValue: {
        type: [String, Number],
        default: '',
    },
    /**
     * Width block
     */
    block: {
        type: Boolean,
        default: true,
    },
    /**
     * Hide message
     */
    hideMessage: {
        type: Boolean,
        default: false,
    },
    /**
     * Hide message
     */
    inputDisplay: {
        type: String,
        default: null,
    },
    /**
     * Show Clear
     */
    showClear: {
        type: Boolean,
        default: true,
    },
    /**
     * Disabled
     */
    disabled: {
        type: Boolean,
        default: false,
    },

    contentClass: {
        type: [String, Array, Object],
        default: '',
    },

    inputClass: {
        type: [String, Array, Object],
        default: '',
    },

    controlIconClass: {
        type: [String, Array, Object],
        default: '',
    },

    inputTextStyle: {
        type: String,
        default: 'normal',
        validator(value) {
            return [
                'italic',
                'normal',
                'oblique',
                'initial',
                'inherit',
                'unset',
            ].includes(value)
        },
    },
})

const isOpenSelection = ref(false)
const inputValue = ref('')
const formControlRef = ref(null)
const internalOptions = computed(() => props.options)
const input = ref(null)
const selectionLabel = ref('')

const { results, currentOptions, replaceResults, clearResults, isSelected } =
    useSelector(
        internalOptions,
        props.searchableFields,
        props.optionValue,
        inputValue,
        false
    )

const { class: containerClass } = attrs
const containerClasses = computed(() => {
    const { size, errorMessage, highlight, disabled, contentClass } = props

    return [
        {
            'px-3 py-2 pr-8': size === 'sm',
            'px-3.5 py-2.5 pr-8': size === 'md',
            'border-gray-300': !errorMessage,
            'ring-4 ring-offset-0': isOpenSelection.value,
            'border-primary-300 ring-primary-100':
                isOpenSelection.value && !errorMessage,
            'border-danger-300': errorMessage && !disabled,
            'border-danger-300 ring-danger-100':
                isOpenSelection.value && errorMessage && !disabled,
            'border-gray-300 bg-gray-50 cursor-not-allowed': disabled,
            'border-warning-300 focus:border-warning-300 ring-4 ring-warning-100 focus:ring-warning-100':
                highlight,
        },
        contentClass ? contentClass : disabled ? 'bg-gray-50' : 'bg-white',
    ]
})

const getOptionLabel = (val) => {
    if (!val) return ''
    if (typeof props.optionLabel === 'function') {
        return props.optionLabel(val)
    }

    return val[props.optionLabel]
}

watchEffect(() => {
    if (props.modelValue === '') {
        selectionLabel.value = ''
        clearResults()
        return
    }

    const optionsValue = internalOptions.value
    if (optionsValue.length === 0) {
        selectionLabel.value = props.inputDisplay || ''
        return
    }

    const result = optionsValue.find((option) => {
        return option[props.optionValue] === props.modelValue
    })

    if (result) {
        selectionLabel.value = getOptionLabel(result)
        replaceResults([result])
    } else {
        selectionLabel.value = props.inputDisplay || ''
    }
})

watch(isOpenSelection, (value) => {
    if (!value) {
        emit('on-close')
    }
})

const onClickOption = (option) => {
    if (isSelected(option) && props.showClear) {
        onClearResults()
    } else {
        selectionLabel.value = getOptionLabel(option)
        inputValue.value = ''
        replaceResults([option])
        closeSelection()

        emit('update:modelValue', option[props.optionValue], option)
        emit('change', option[props.optionValue], option)
    }
}

const onClearResults = () => {
    inputValue.value = ''
    selectionLabel.value = ''
    clearResults()
    closeSelection()

    emit('update:modelValue', '', [])
    emit('change', '', [])
}

const onTypeInput = () => {
    emit('on-input', inputValue.value)
    clearResults()
}

const openSelection = () => {
    isOpenSelection.value = true
    emit('on-open')
}

const closeSelection = () => {
    isOpenSelection.value = false
}

const onEnter = () => {
    if (isOpenSelection.value) {
        closeSelection()
    }

    emit('on-enter', inputValue.value, results.value)
    input.value.blur()
}

const onBlur = () => {
    closeSelection()

    if (!props.modelValue && inputValue.value) {
        inputValue.value = ''
        return
    }

    if (props.modelValue) {
        const option = internalOptions.value.find(
            (option) => option[props.optionValue] == props.modelValue
        )

        if (!option) return

        inputValue.value = ''
        selectionLabel.value = getOptionLabel(option)
        replaceResults([option])
        closeSelection()
    }
}

const inputPlaceholder = computed(() => {
    return selectionLabel.value || props.placeholder
})

onClickOutside(formControlRef, onBlur)
</script>
