const cacheIcons = {}

/**
 * @param {string} key
 * @param value
 */
export const setCacheIcon = (key, value) => {
    if (!cacheIcons[key]) {
        cacheIcons[key] = value
    }

    return value
}

/**
 * @param {string} key
 */
export const getCacheIcon = (key) => {
    return cacheIcons[key]
}
