<template>
    <div class="sentry-unmask flex border-b bg-gray-100">
        <!-- Logo -->
        <div class="flex w-80 items-center justify-between border-r px-6 py-5">
            <base-logo class="pt-1" />
            <base-context-menu
                dropdown-classes="w-64"
                v-if="
                    companies.length > 1 ||
                    hasCentralRoles(USER_ACCOUNT_TYPES.ACCOUNTANT)
                "
            >
                <template #trigger="{ active, toggleMenu }">
                    <div
                        class="flex h-12 w-12 cursor-pointer items-center justify-center"
                        @click="toggleMenu"
                    >
                        <base-icon
                            variant="inherit"
                            :class="active ? 'text-gray-900' : 'text-gray-700'"
                            name="line-icons:general:dots-vertical"
                        />
                    </div>
                </template>

                <base-context-menu-item
                    v-if="hasCentralRoles(USER_ACCOUNT_TYPES.ACCOUNTANT)"
                    icon="line-icons:media-&-devices:monitor-05"
                    @click="goAccountant"
                >
                    {{ $t('topBand.accountantDashboard') }}
                </base-context-menu-item>

                <base-context-menu-item
                    v-for="company in companies"
                    :key="company.id"
                    icon="line-icons:general:building-01"
                    content-class="truncate"
                    :class="{
                        'pointer-events-none bg-gray-50':
                            tenantId === company.id,
                    }"
                    @click="switchCompany(company)"
                >
                    <span class="truncate">
                        {{ company.name }}
                    </span>
                </base-context-menu-item>
            </base-context-menu>
        </div>

        <div class="flex grow items-center justify-between gap-2.5 px-6 py-5">
            <!-- Search -->
            <partial-search-bar />

            <!-- Actions -->
            <div class="flex items-center gap-2.5">
                <!-- Add -->
                <partial-top-band-add
                    v-if="$feature.isActive('FEATURE_FLAG_1083')"
                />

                <!-- Notifications -->
                <!--                <base-tooltip-->
                <!--                    dark-->
                <!--                    hover-->
                <!--                    :content="$t('topBand.notificationTooltip')"-->
                <!--                >-->
                <!--                    <div class="relative">-->
                <!--                        <base-button-->
                <!--                            class="bg-white p-2"-->
                <!--                            icon="line-icons:alerts-&-feedback:bell-01"-->
                <!--                            variant="default"-->
                <!--                            size="sm"-->
                <!--                            :hasPadding="false"-->
                <!--                        />-->

                <!--                        &lt;!&ndash; Notification status/dot &ndash;&gt;-->
                <!--                        <span-->
                <!--                            class="absolute right-2 top-1.5 h-2.5 w-2.5 rounded-full bg-primary-600"-->
                <!--                        ></span>-->
                <!--                    </div>-->
                <!--                </base-tooltip>-->

                <!-- Assistant -->
                <base-tooltip
                    dark
                    hover
                    :content="$t('topBand.assistantTooltip')"
                >
                    <base-button
                        class="bg-white p-2"
                        icon="line-icons:general:zap-fast"
                        variant="default"
                        size="sm"
                        :hasPadding="false"
                        @click="
                            $router.push({
                                name: 'assistant',
                            })
                        "
                    />
                </base-tooltip>

                <!-- Settings -->
                <base-tooltip
                    v-if="$acl.can('view_companies')"
                    dark
                    hover
                    :content="$t('topBand.settingsTooltip')"
                >
                    <base-button
                        class="bg-white p-2"
                        icon="line-icons:general:settings-01"
                        variant="default"
                        size="sm"
                        :hasPadding="false"
                        @click="$router.push({ name: 'settings' })"
                    />
                </base-tooltip>

                <!-- Profile -->
                <base-button
                    class="bg-white"
                    variant="default"
                    size="sm"
                    icon="line-icons:users:user-03"
                    @click="showUserMenu = true"
                >
                    {{ user.full_name }}
                </base-button>

                <partial-user-slide-out
                    :show="showUserMenu"
                    @close="showUserMenu = false"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { USER_ACCOUNT_TYPES } from '@tenant/utils/constants'

const emit = defineEmits(['go-accountant', 'switch-client'])

const { user, centralUser, tenantId, hasCentralRoles } = useAuth()

const companies = computed(() => centralUser.value.tenants || [])

const goAccountant = () => {
    emit('go-accountant')
}

const switchCompany = async (item) => {
    emit('switch-client', item)
}
const showUserMenu = ref(false)
</script>
