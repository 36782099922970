<template>
    <div class="flex h-full flex-col">
        <!-- Header -->
        <div class="flex border-b p-6 pb-4">
            <div class="flex grow items-center gap-4">
                <base-icon
                    class="cursor-pointer"
                    name="line-icons:arrows:chevron-left"
                    variant="inherit"
                    @click="onReturn"
                />
                {{
                    $t('chat.chatWindow.title', {
                        name: accountant.full_name,
                    })
                }}
            </div>

            <base-icon
                class="cursor-pointer"
                name="line-icons:general:x"
                variant="gray"
                @click="onClose"
            />
        </div>

        <!-- Messages -->
        <partial-chat-items
            v-if="room"
            :room="room"
            :key="room.id"
            class="px-6 py-7"
        />

        <div v-else class="grow" />

        <!-- Footer form -->
        <div class="border-t px-6 py-4">
            <form class="flex gap-3" @submit.prevent="sendMessage">
                <form-text-input
                    v-model="message"
                    :error-message="errorMessage"
                    hide-message
                    class="grow"
                    :placeholder="$t('chat.chatWindow.messageInputPlaceholder')"
                    autofocus
                />
                <base-button
                    type="submit"
                    class="p-3"
                    icon="line-icons:communication:send-01"
                    :disabled="disabledSend"
                    :loading="loadingSend"
                    :has-padding="false"
                />
            </form>
        </div>
    </div>
</template>

<script setup>
const emit = defineEmits(['return', 'close'])

const emitter = useEmitter()

const props = defineProps({
    room: {
        type: Object,
        required: true,
    },
    accountant: {
        type: Object,
        default: null,
    },
})

const { message, errorMessage, disabledSend, loadingSend, onSendMessage } =
    useMessage(props.room)

const onReturn = () => {
    emit('return')
}

const onClose = () => {
    emit('close')
}

const sendMessage = () => {
    onSendMessage().then((message) => {
        emitter.emit('sent-message', {
            room_id: props.room.id,
            message,
        })
    })
}
</script>
