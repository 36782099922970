<template>
    <div class="py flex gap-5">
        <form-text-input
            :label="$t('transaction.bookPaymentModal.filterPanel.name')"
            size="sm"
            :model-value="filterValues.name?.[1]"
            @change="onSearchText($event.target.value, 'name')"
        />

        <form-text-input :label="'Type'" size="sm" />

        <form-date-picker
            :label="
                $t('transaction.bookPaymentModal.filterPanel.dateOfTransfer')
            "
            single
            :model-value="filterValues.date_of_transfer"
            @update:modelValue="onChange($event, 'date_of_transfer')"
        />

        <form-filter-range
            query-key="amount"
            :query-value="queries.amount"
            :is-negative="isNegative"
            size="sm"
            :label="$t('transaction.bookPaymentModal.filterPanel.amount')"
        />
    </div>
</template>

<script setup>
import { useFilters } from '@tenant/composables'
import { TRANSACTION_STATUS_TYPE } from '@tenant/utils/constants'

const props = defineProps({
    isNegative: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits(['filter-update'])

const queries = {
    name: 'filter.description',
    date_of_transfer: 'filter.date_of_transfer',
    amount: 'filter.amount',
    status: 'filter.status',
}
const { filterValues, updateFilter, updateFilters } = useFilters(queries, {
    replaceRoute: false,
})

watch(
    () => filterValues,
    () => {
        emit('filter-update', filterValues.value)
    },
    {
        deep: true,
    }
)

onMounted(() => {
    updateFilters({
        [queries['amount']]: [props.isNegative ? '<' : '>', 0],
        [queries['status']]: ['<>', TRANSACTION_STATUS_TYPE.PAID],
    })
})
const onChange = (value, key) => updateFilter(queries[key], value)

const onSearchText = (value, key) => {
    updateFilters({
        [queries['amount']]: [props.isNegative ? '<' : '>', 0],
        [queries['status']]: ['<>', TRANSACTION_STATUS_TYPE.PAID],
        [queries[key]]: ['LIKE', value],
    })
}
</script>
