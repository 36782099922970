export const upsertToolTip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div')

    if (!tooltipEl) {
        tooltipEl = document.createElement('div')
        tooltipEl.style.cssText = `
            background: white;
            border-radius: 8px;
            border: 1px solid #D0D5DD;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            width: 200px;
            color: rgba(52, 64, 84, 1);
            opacity: 1;
            pointer-events: none;
            position: absolute;
            transform: translate(-50%, -100%);
            transition: all .1s ease;
            padding: 8px 12px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 5px;
            font-size: 12px;
            font-weight: 500;
            font-family: Inter;
            line-height: 18px;
            font-style: normal;
        `

        const table = document.createElement('table')
        table.style.cssText = `
            width: 100%;
            border-collapse: collapse;
        `

        const div = document.createElement('div')
        div.style.cssText = `
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, 200%);
            display: inline-block;
            width: 0;
            height: 0;
            margin-bottom: 5px;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #FFF;
        `

        tooltipEl.appendChild(table)
        tooltipEl.appendChild(div)
        chart.canvas.parentNode.appendChild(tooltipEl)
    }

    return tooltipEl
}

export const externalTooltipHandler = (context, innerHtml, styles = {}) => {
    const { chart, tooltip } = context
    if (tooltip.dataPoints[0].datasetIndex < 0) {
        return
    }

    const tooltipEl = upsertToolTip(chart)

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = '0'
        return
    }

    // Set Text
    if (tooltip.body) {
        const tableRoot = tooltipEl.querySelector('table')
        while (tableRoot.firstChild) {
            tableRoot.firstChild.remove()
        }

        tableRoot.innerHTML = innerHtml
    }

    const { offsetLeft: positionX } = chart.canvas

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1
    tooltipEl.style.left = positionX + tooltip.caretX + 'px'
    tooltipEl.style.top = '0px'
    Object.keys(styles).forEach((key) => {
        tooltipEl.style[key] = styles[key]
    })
}
