<template>
    <div>
        <label
            class="relative flex cursor-pointer items-center"
            :class="containerClass"
        >
            <input
                v-bind="$attrs"
                v-model="value"
                type="radio"
                class="peer hidden"
            />
            <span
                :class="radioSizeClasses"
                class="border border-gray-300 transition-all duration-150 group-hover:bg-primary-100 peer-checked:border-primary-600 peer-checked:bg-primary-100 peer-focus:ring-4 peer-focus:ring-primary-100 peer-focus:ring-offset-0 peer-disabled:cursor-default peer-disabled:border-gray-300 peer-disabled:bg-gray-100"
            />
            <span
                v-if="variant === 'square'"
                :class="checkedRadioSizeClasses"
                class="absolute flex cursor-pointer items-center justify-center opacity-0 transition-all duration-150 peer-checked:text-primary-600 peer-checked:opacity-100 peer-disabled:cursor-default peer-disabled:peer-checked:opacity-50"
            >
                <base-icon
                    name="line-icons:general:check-thick"
                    variant="inherit"
                    :size="checkboxIconSize"
                />
            </span>
            <span
                v-else
                :class="checkedRadioSizeClasses"
                class="absolute rounded-full bg-primary-600 opacity-0 transition-all duration-150 peer-checked:opacity-100 peer-disabled:cursor-default"
            />
            <slot name="text">
                <span
                    v-if="text"
                    class="ml-2 text-sm font-medium text-gray-700 peer-disabled:cursor-default"
                >
                    {{ text }}
                </span>
            </slot>
        </label>

        <slot name="supportingText">
            <p v-if="supportingText" class="text-sm text-gray-500">
                {{ supportingText }}
            </p>
        </slot>
    </div>
</template>

<script>
// A normal <script> is needed to declare the inheritAttrs option
export default {
    inheritAttrs: false,
}
</script>

<script setup>
import { computed } from 'vue'

const props = defineProps({
    text: {
        type: String,
    },
    containerClass: {
        type: String,
        default: '',
    },
    supportingText: {
        type: String,
    },
    size: {
        type: String,
        default: 'sm',
    },
    modelValue: {
        type: [Number, String, Boolean],
        default: null,
    },
    variant: {
        type: String,
        default: 'circle',
        required: false,
        validator(value) {
            return ['circle', 'square'].includes(value)
        },
    },
})

const radioSizeClasses = computed(() => {
    return [
        {
            'rounded-full': props.variant === 'circle',
            rounded: props.variant === 'square',
        },
        {
            'h-5 w-5': props.size === 'md',
            'h-4 w-4': props.size === 'sm',
        },
    ]
})

const checkedRadioSizeClasses = computed(() => {
    return [
        {
            'h-2 w-2 m-1.5': props.size === 'md',
            'h-1.5 w-1.5 m-[5px]': props.size === 'sm',
        },
    ]
})

const checkboxIconSize = computed(() => {
    const { size } = props

    const sizeData = {
        md: 'xs',
        sm: 'xxs',
    }

    return sizeData[size]
})

// Input handler
const emit = defineEmits(['update:modelValue'])
const value = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
    },
})
</script>
