<template>
    <span
        :class="[badgeClasses, borderClass]"
        class="inline-flex items-center rounded-2xl font-normal"
    >
        <slot name="left" />
        <slot name="label">
            {{ label }}
        </slot>
        <slot name="right" />
    </span>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
    label: {
        type: [String, Number],
        default: 'Badge',
    },

    variant: {
        type: String,
        default: 'primary',
        required: false,
        validator(value) {
            return [
                'primary',
                'danger',
                'warning',
                'success',
                'gray',
                'hard-gray',
                'info',
            ].includes(value)
        },
    },

    border: {
        type: Boolean,
        default: false,
    },

    size: {
        type: String,
        default: 'sm',
        required: false,
        validator(value) {
            return ['sm', 'md', 'lg'].includes(value)
        },
    },
})

const badgeClasses = computed(() => ({
    'bg-primary-50 text-primary-700': props.variant === 'primary',
    'bg-danger-50 text-danger-700': props.variant === 'danger',
    'bg-warning-50 text-warning-700': props.variant === 'warning',
    'bg-success-50 text-success-700': props.variant === 'success',
    'bg-blue-50 text-blue-700': props.variant === 'info',
    'bg-gray-100 text-gray-700': props.variant === 'gray',
    'bg-gray-300 text-gray-700': props.variant === 'hard-gray',
    'px-2 py-0.5 text-xs': props.size === 'sm',
    'px-2.5 py-0.5 text-sm': props.size === 'md',
    'px-3 py-1 text-sm': props.size === 'lg',
}))

const borderClass = computed(() => {
    if (props.border) {
        return {
            'border border-primary-200': props.variant === 'primary',
            'border border-danger-200': props.variant === 'danger',
            'border border-warning-200': props.variant === 'warning',
            'border border-success-200': props.variant === 'success',
            'border border-blue-200': props.variant === 'info',
            'border border-gray-200': props.variant === 'gray',
        }
    }

    return null
})
</script>
