<template>
    <span ref="contextMenuRef" class="relative flex justify-center">
        <slot
            name="trigger"
            v-bind="{
                active,
                toggleMenu,
            }"
        >
            <div
                class="flex cursor-pointer items-center justify-center"
                @click="toggleMenu"
            >
                <base-icon
                    variant="inherit"
                    :class="active ? 'text-gray-900' : 'text-gray-400'"
                    name="line-icons:general:dots-vertical"
                    :size="size"
                />
            </div>
        </slot>

        <span
            v-if="active"
            class="absolute top-full z-10 w-60 rounded-lg border border-gray-100 bg-white text-sm text-gray-700 shadow-md"
            :class="[menuClasses, dropdownClasses]"
        >
            <slot v-bind="{ toggleMenu, closeDropdown }" />
        </span>
    </span>
</template>

<script setup>
import { onClickOutside } from '@vueuse/core'

const POSITION_CLASS = {
    left: 'left-0',
    right: 'right-0',
}

const props = defineProps({
    position: {
        type: String,
        default: 'right',
        validator(value) {
            return ['left', 'right'].includes(value)
        },
    },
    dropdownClasses: {
        type: String,
        default: '',
    },
    size: {
        type: String,
        default: 'lg',
    },
})

const active = ref(false)
const contextMenuRef = ref(null)

const menuClasses = computed(() => POSITION_CLASS[props.position])

const toggleMenu = () => (active.value = !active.value)
const closeDropdown = () => (active.value = false)

onClickOutside(contextMenuRef, closeDropdown)
</script>
