<template>
    <base-modal close size="auto" :show="show" @modal-close="closeModal">
        <template #modal-header>
            <span class="text-lg font-medium text-gray-900">
                {{ $t('contacts.add.title') }}
            </span>
        </template>

        <contacts-section-general
            is-creating-form
            hide-info-block
            vertical
            :has-border="false"
        />

        <div
            class="flex gap-0.5 rounded-lg border border-gray-100 bg-gray-50 p-1"
        >
            <base-button
                variant="link-gray"
                @click="selectedTab = 'address'"
                :class="{
                    'bg-white shadow-sm': selectedTab === 'address',
                }"
            >
                {{ $t('contacts.form.addressSection.title') }}
            </base-button>
            <base-button
                variant="link-gray"
                @click="selectedTab = 'contact'"
                :class="{
                    'bg-white shadow-sm': selectedTab === 'contact',
                }"
            >
                {{ $t('contacts.form.contactSection.title') }}
            </base-button>
            <base-button
                variant="link-gray"
                @click="selectedTab = 'payment'"
                :class="{
                    'bg-white shadow-sm': selectedTab === 'payment',
                }"
            >
                {{ $t('contacts.form.paymentSection.title') }}
            </base-button>
            <base-button
                variant="link-gray"
                @click="selectedTab = 'additional'"
                :class="{
                    'bg-white shadow-sm': selectedTab === 'additional',
                }"
            >
                {{ $t('contacts.form.additionalSection.title') }}
            </base-button>
            <base-button
                variant="link-gray"
                @click="selectedTab = 'other'"
                :class="{
                    'bg-white shadow-sm': selectedTab === 'other',
                }"
            >
                {{ $t('contacts.form.defaultSettings.title') }}
            </base-button>
        </div>

        <contacts-section-address
            v-show="selectedTab === 'address'"
            hide-info-block
            vertical
        />
        <contacts-section-contact
            v-show="selectedTab === 'contact'"
            hide-info-block
            vertical
        />
        <contacts-section-payment
            v-show="selectedTab === 'payment'"
            hide-info-block
            vertical
        />
        <contacts-section-additional
            v-show="selectedTab === 'additional'"
            hide-info-block
            vertical
        />

        <contacts-section-other-details
            v-show="selectedTab === 'other'"
            hide-info-block
            vertical
        />

        <template #modal-footer>
            <div class="flex justify-end gap-3">
                <base-button outline variant="default" @click="closeModal">
                    {{ $t('general.cancel') }}
                </base-button>

                <base-button
                    variant="primary"
                    icon="line-icons:general:check"
                    :loading="loadingCreateOrg || loading"
                    @click="onSubmit"
                >
                    {{ $t('general.save') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
import { CONTACT_RELATIONSHIP_TYPES } from '@tenant/utils/constants'
const { t } = useI18n()

const { successNotify } = useNotification()

const emit = defineEmits(['modal-close', 'contact-added'])

defineProps({
    show: {
        type: Boolean,
        required: false,
    },
    loading: {
        type: Boolean,
        default: false,
    },
})

const closeModal = () => {
    emit('modal-close')
}

const { setErrors, handleSubmit, setValues } = useForm()
const { loading: loadingCreateOrg, execute: executeCreateOrg } = useApiFactory(
    'contacts/organisation'
).useApiCreate()
useField('contact_type', undefined, {
    initialValue: CONTACT_RELATIONSHIP_TYPES.organisation,
})

const onSubmit = handleSubmit(async (values) => {
    let contact
    try {
        contact = await executeCreateOrg(values)

        // Show a notification on success
        successNotify({
            title: t('general.success'),
            text: t('contacts.add.addSuccess'),
        })

        emit('contact-added', contact)
    } catch ({ errors }) {
        setErrors(errors)
    }
})

const selectedTab = ref('address')

const { defaultCurrency } = useCurrencies()
const { value: currency } = useField('currency_id')

watch(
    () => defaultCurrency.value,
    (value) => {
        if (!value) {
            return
        }

        if (!currency.value) {
            setValues({
                currency_id: value.value,
            })
        }
    },
    { immediate: true }
)
</script>
