<template>
    <base-accordion
        ref="accordion"
        class="rounded-md border border-gray-200"
        :icon-bordered="false"
        :trigger-classes="triggerClasses"
        @toggle="onToggle"
    >
        <template #title v-if="transactions">
            <p class="mr-1 text-xs">
                {{ $t('journalHistory.title') }}
            </p>
            <base-badge
                variant="info"
                :label="transactions?.data?.length ?? 0"
            />
        </template>

        <template #icon="{ isOpen }">
            <base-icon
                v-if="isOpen"
                name="line-icons:arrows:chevron-down"
                size="sm"
                variant="inherit"
            />
            <base-icon
                v-else
                name="line-icons:arrows:chevron-right"
                size="sm"
                variant="inherit"
            />
        </template>

        <template #content v-if="transactions">
            <base-currency class="px-4" />
            <data-grid
                :columns="columns"
                :data-source="transactions.data"
                :summary-data="summaryData"
                class="mt-2 rounded-none border-x-0 border-b-0"
            >
                <template #column-account="{ item }">
                    {{ item?.chart_of_account?.name }}
                </template>

                <template #column-debit="{ item }">
                    <span
                        :class="{
                            'font-bold text-gray-700': item.isSummary,
                        }"
                        >{{ $filters.numberFormat(item?.debit) }}
                    </span>
                </template>

                <template #column-credit="{ item }">
                    <span
                        :class="{
                            'font-bold text-gray-700': item.isSummary,
                        }"
                        >{{ $filters.numberFormat(item?.credit) }}
                    </span>
                </template>
            </data-grid>
        </template>
    </base-accordion>
</template>

<script setup>
const { t } = useI18n()
const props = defineProps({
    loading: {
        type: Boolean,
        default: false,
    },
    queries: {
        type: Object,
        default: () => ({}),
    },
})

const accordion = ref(null)

// GET JOURNAL HISTORY
const columns = [
    {
        property: 'account',
        label: t('journalHistory.account'),
    },
    {
        property: 'debit',
        label: t('general.debit'),
    },
    {
        property: 'credit',
        label: t('general.credit'),
    },
]

const route = useRoute()
const { useApiSearch, entities: transactions } = useApiFactory('journal-lines')

const getJournals = async () => {
    const { execute } = useApiSearch(
        {
            queries: { page: 1, limit: 500 },
            filter: { 'journalEntry.journalable_id': route.params.id },
            ...props.queries,
        },
        false
    )

    await execute()
}

onMounted(() => {
    getJournals()
})

watch(
    () => props.loading,
    (value) => {
        if (!value && accordion.value) {
            accordion.value.collapseAccordion()
        }

        if (value) {
            getJournals()
        }
    }
)

const summaryData = computed(() => {
    if (!transactions.value) {
        return []
    }

    const summary = {
        debit: 0,
        credit: 0,
    }

    transactions.value.data.forEach((item) => {
        summary.debit += Number(item.debit)
        summary.credit += Number(item.credit)
    })

    return [
        {
            debit: summary.debit,
            credit: summary.credit,
        },
    ]
})

// END: JOURNAL HISTORY

const triggerClasses = ref('p-4')
const onToggle = (isOpen) => {
    triggerClasses.value = isOpen ? 'p-4 pb-0' : 'p-4'
}
</script>
