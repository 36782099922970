<template>
    <data-grid
        :data-source="entities.data"
        :columns="columns"
        :loading="loading"
        identify-field="id"
        @row-click="redirectToDetail"
    >
        <template v-if="!compact" #filter>
            <div class="flex w-full items-center justify-between gap-2">
                <div class="grow" />
                <div class="flex grow gap-3">
                    <purchases-expense-filter-search />
                </div>
            </div>
        </template>

        <template #column-date="{ item }">
            <div class="text-sm font-normal text-gray-500">
                {{ $filters.dateHumanFormat(item.date) }}
            </div>
        </template>

        <template #column-expense="{ item }">
            <div class="text-sm font-medium text-primary-600">
                <template v-if="item.itemize">
                    {{ $t('general.itemized') }}
                </template>
                <template v-else>
                    {{ item.line_items[0]?.chart_of_account?.name }}
                </template>
            </div>
        </template>

        <template #column-vendor="{ item }">
            <div class="text-sm font-normal text-gray-500">
                {{ getContactName(item.contact) }}
            </div>
        </template>

        <template #column-status="{ item }">
            <base-badge
                :label="$t('expenseStatus', { status: item.status })"
                :variant="getStatusVariant(item.status)"
            />
        </template>

        <template #column-amount="{ item }">
            {{
                $filters.symbolCurrency(item.amount_gross_total, item.currency)
            }}
        </template>

        <template #column-actions>
            <base-icon
                name="line-icons:files:paperclip"
                variant="inherit"
                size="sm"
            />
        </template>

        <template #empty v-if="$slots['empty']">
            <slot name="empty" />
        </template>

        <template #footer>
            <grid-pagination
                :total="entities.last_page"
                :page="entities.current_page"
                :replace-query="replaceQuery"
                @page-change="onPageChange"
            />
        </template>
    </data-grid>
</template>
<script setup>
import { getStatusVariant } from '@tenant/modules/tenant/purchases-expense/utils/helper'
import { getContactName } from '@tenant/utils/helper'
import { useGetExpense } from '@tenant/modules/tenant/purchases-expense/composables/use-expense'
import { useI18n } from 'vue-i18n'

const emit = defineEmits(['expenses-total'])

const props = defineProps({
    queries: {
        type: Object,
        default: () => ({}),
    },
    replaceQuery: {
        type: Boolean,
        default: false,
    },
    compact: {
        type: Boolean,
        default: false,
    },
})

const { entities, loading, execute } = useGetExpense(
    { ...props.queries },
    false
)

const router = useRouter()
const { t } = useI18n()

const columns = [
    {
        property: 'date',
        label: t('purchasesExpense.list.columns.date'),
    },
    {
        property: 'expense',
        label: t('purchasesExpense.list.columns.expenseAccount'),
    },
    {
        property: 'reference',
        label: t('purchasesExpense.list.columns.referenceNumber'),
    },
    {
        property: 'vendor',
        label: t('purchasesExpense.list.columns.vendorName'),
    },
    {
        property: 'status',
        label: t('purchasesExpense.list.columns.status'),
    },
    {
        property: 'amount',
        label: t('purchasesExpense.list.columns.amount'),
    },
    {
        property: 'actions',
        label: ' ',
    },
]

const state = reactive({
    page: 1,
})

onMounted(() => {
    loadData()
})

watch(
    () => props.queries,
    () => {
        loadData()
    }
)

const loadData = async () => {
    await execute({
        page: state.page,
        ...props.queries,
    })
}

const onPageChange = (page) => {
    state.page = page

    return loadData()
}

const redirectToDetail = (id) => {
    router.push({ name: 'purchases.expense.detail', params: { id } })
}

watch(
    () => entities?.value?.total,
    (value) => {
        emit('expenses-total', value)
    }
)
</script>
