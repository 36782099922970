<template>
    <StripeElements
        v-if="state.stripeLoaded"
        v-slot="{ elements }"
        ref="elms"
        :stripe-key="stripeSecret"
        :elements-options="elementsOptions"
        class="flex flex-col gap-4"
    >
        <form-control-group-row>
            <form-text-input
                v-model="state.name"
                class="flex-1"
                :label="$t('slideOut.paymentMethod.add.nameOnCard.label')"
                :placeholder="
                    $t('slideOut.paymentMethod.add.nameOnCard.placeholder')
                "
            />
        </form-control-group-row>
        <div class="flex flex-col gap-1.5">
            <span class="text-sm font-medium text-gray-700">
                {{ $t('slideOut.paymentMethod.add.cardNumber') }}
            </span>
            <StripeElement
                ref="cardNumber"
                type="cardNumber"
                :elements="elements"
                :options="cardNumberOptions"
                class="rounded-lg border border-gray-300 p-2.5 shadow-sm"
                @ready="onReady('cardNumber')"
            />
        </div>
        <div class="flex gap-4">
            <div class="flex flex-1 flex-col gap-1.5">
                <span class="text-sm font-medium text-gray-700">
                    {{ $t('slideOut.paymentMethod.add.expiry') }}
                </span>
                <StripeElement
                    type="cardExpiry"
                    :elements="elements"
                    :options="expiryOptions"
                    class="flex-1 rounded-lg border border-gray-300 p-2.5 shadow-sm"
                    @ready="onReady('expiry')"
                />
            </div>
            <div class="flex flex-1 flex-col gap-1.5">
                <span class="text-sm font-medium text-gray-700">
                    {{ $t('slideOut.paymentMethod.add.cvc') }}
                </span>
                <StripeElement
                    type="cardCvc"
                    :elements="elements"
                    :options="cvcOptions"
                    class="flex-1 rounded-lg border border-gray-300 p-2.5 shadow-sm"
                    @ready="onReady('cvc')"
                />
            </div>
            <div class="flex-1" />
        </div>

        <hr class="my-2" />
        <h5 class="mb-2 text-lg font-medium text-gray-700">
            {{ $t('slideOut.paymentMethod.add.billingAddress') }}
        </h5>

        <form-control-group>
            <form-control-group-row>
                <form-single-select
                    v-model="state.address.country"
                    class="flex-1"
                    :label="$t('slideOut.paymentMethod.add.country.label')"
                    :placeholder="
                        $t('slideOut.paymentMethod.add.country.placeholder')
                    "
                    :options="countries"
                />
            </form-control-group-row>
            <form-control-group-row>
                <form-text-input
                    v-model="state.address.line1"
                    class="flex-1"
                    :label="$t('slideOut.paymentMethod.add.address.label')"
                    :placeholder="
                        $t('slideOut.paymentMethod.add.address.placeholder')
                    "
                />
            </form-control-group-row>
            <form-control-group-row>
                <form-text-input
                    v-model="state.address.city"
                    class="flex-1"
                    :label="$t('slideOut.paymentMethod.add.city.label')"
                    :placeholder="
                        $t('slideOut.paymentMethod.add.city.placeholder')
                    "
                />
            </form-control-group-row>
            <form-control-group-row>
                <form-text-input
                    v-model="state.address.state"
                    class="flex-1"
                    :label="$t('slideOut.paymentMethod.add.state.label')"
                    :placeholder="
                        $t('slideOut.paymentMethod.add.state.placeholder')
                    "
                />
                <form-text-input
                    v-model="state.address.postal_code"
                    class="flex-1"
                    :label="$t('slideOut.paymentMethod.add.postalCode.label')"
                    :placeholder="
                        $t('slideOut.paymentMethod.add.postalCode.placeholder')
                    "
                />
            </form-control-group-row>
        </form-control-group>
    </StripeElements>
</template>

<script setup>
import { StripeElements, StripeElement } from 'vue-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { toRefs } from 'vue'

const emit = defineEmits(['on-ready'])

const { t } = useI18n()
const { countries } = useCountries()

const state = reactive({
    readyStripe: [],
    stripeLoaded: false,
    name: null,
    address: {
        city: null,
        country: null,
        line1: null,
        postal_code: null,
        state: null,
    },
})

const cardNumber = ref()
const elms = ref()

const stripeSecret = computed(() => import.meta.env.VITE_STRIPE_KEY)
const baseOptions = {
    style: {
        base: {
            iconColor: '#101828',
            color: '#101828',
            fontWeight: '400',
            fontFamily: 'Inter, Roboto, Open Sans, Segoe UI, sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
        },
        invalid: {
            iconColor: '#D92D20',
            color: '#D92D20',
        },
    },
}

const elementsOptions = computed(() => ({
    locale: 'en',
}))

const cardNumberOptions = computed(() => ({
    ...baseOptions,
    showIcon: true,
    placeholder: t('slideOut.paymentMethod.add.cardNumber'),
}))

const expiryOptions = computed(() => ({
    ...baseOptions,
}))

const cvcOptions = computed(() => ({
    ...baseOptions,
}))

onMounted(() => {
    getIntent()
})

watch(
    () => state.readyStripe,
    (value) => {
        if (value.length > 0) {
            return
        }

        emit('on-ready')
    }
)

const getIntent = () => {
    state.readyStripe = ['cardNumber', 'expiry', 'cvc']

    const stripePromise = loadStripe(stripeSecret.value)

    stripePromise.then(() => {
        state.stripeLoaded = true
    })
}

const onReady = (type) => {
    state.readyStripe = state.readyStripe.filter((i) => i !== type)
}

defineExpose({
    cardNumber,
    elms,
    ...toRefs(state),
})
</script>
