<template>
    <base-icon
        name="line-icons:communication:message-chat-square"
        variant="inherit"
    />
    <template v-if="!isCollapsed">
        {{ $t('accountant.sidebar.inbox') }}
        <base-badge
            v-if="totalUnAnswer > 0"
            class="ml-auto"
            variant="danger"
            :label="totalUnAnswer"
        />
    </template>
</template>

<script setup>
import { ECHO_EVENTS } from '@tenant/utils/constants'

defineProps({
    isCollapsed: {
        type: Boolean,
        default: false,
    },
})

const { totalUnAnswer } = useUnAnswerConversation()
const { updateUnAnswer } = useConversation()

useListenEmitEcho(ECHO_EVENTS.CHAT_MESSAGE, ({ chatRoom }) => {
    updateUnAnswer(chatRoom.latest_message, chatRoom)
})
</script>
