<template>
    <base-modal close @modal-close="closeModal" :show="show" :size="size">
        <template #modal-icon>
            <div class="flex">
                <slot name="icon">
                    <base-feature-icon
                        variant="danger"
                        name="line-icons:alerts-&-feedback:alert-circle"
                    />
                </slot>
            </div>
        </template>
        <div class="flex flex-col">
            <h5 class="text-lg font-medium text-gray-900">
                <slot name="title" v-bind="{ name }">
                    {{
                        $t('modal.delete.title', {
                            count: hasName ? 2 : 1,
                            name,
                        })
                    }}
                </slot>
            </h5>
            <p class="mt-2 text-sm font-normal text-gray-500">
                <slot name="description" v-bind="{ name }">
                    {{
                        $t('modal.delete.description', {
                            count: hasName ? 2 : 1,
                            name,
                        })
                    }}
                </slot>
            </p>

            <slot></slot>

            <div class="mt-4">
                <form-text-input
                    v-if="confirmationValue"
                    v-model="confirmationInput"
                    :label="confirmationMessage"
                />
            </div>
        </div>
        <template #modal-footer>
            <div class="flex justify-between gap-3">
                <base-button
                    outline
                    full-width
                    variant="default"
                    :disabled="loading"
                    @click="closeModal"
                >
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button
                    full-width
                    variant="danger"
                    :disabled="
                        (confirmationValue &&
                            confirmationValue !== confirmationInput) ||
                        loading
                    "
                    :loading="loading"
                    @click="confirmDelete"
                >
                    {{ $t('general.confirm') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>
<script setup>
import { ref, computed } from 'vue'

const emit = defineEmits(['modal-close', 'confirm-delete'])

const props = defineProps({
    show: {
        type: [Boolean, Number, String],
        default: false,
    },

    size: {
        type: String,
        default: 'sm',
    },

    name: {
        type: String,
        default: '',
    },

    loading: {
        type: Boolean,
        default: false,
    },

    confirmationMessage: {
        type: String,
    },

    confirmationValue: {
        type: String,
    },
})

const hasName = computed(() => !!props.name)

const confirmationInput = ref('')

const closeModal = () => {
    confirmationInput.value = ''
    emit('modal-close')
}

const confirmDelete = () => {
    confirmationInput.value = ''
    emit('confirm-delete')
}
</script>
