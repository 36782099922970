<template>
    <span class="relative inline-block" ref="dropdown">
        <slot name="btn" v-bind="{ toggleDropdown, active }">
            <base-button
                :label="label"
                :variant="variant"
                :size="size"
                :disabled="btnDisabled"
                :class="triggerClasses"
                @click.stop="toggleDropdown"
            >
                <slot name="btn-content" v-bind="{ active, toggleDropdown }" />
            </base-button>
        </slot>

        <span
            v-if="active"
            class="absolute top-full z-20 mt-2 max-h-[288px] overflow-auto rounded-lg border border-gray-100 bg-white text-sm text-gray-700 shadow-md"
            :class="menuClasses"
        >
            <slot v-bind="{ active, toggleDropdown, close }" />
        </span>
    </span>
</template>

<script setup>
const active = ref(false)
const dropdown = ref(null)

const close = (e) => {
    if (!dropdown.value.contains(e.target)) {
        active.value = false
    }
}

onMounted(() => {
    document.addEventListener('click', close)
})

onBeforeUnmount(() => {
    document.removeEventListener('click', close)
})

// PROPS
const props = defineProps({
    label: {
        type: String,
    },

    contentClasses: {
        type: [String, Array],
        default: 'w-60',
    },

    btnDisabled: {
        type: Boolean,
        default: false,
    },

    triggerClasses: {
        type: String,
        default: '',
    },

    variant: {
        type: String,
        validator(value) {
            // The value must match one of these strings
            return [
                'default',
                'white',
                'gray',
                'primary',
                'error',
                'warning',
                'success',
                'tertiary-gray',
                'link-gray',
            ].includes(value)
        },
    },

    size: {
        type: String,
        validator(value) {
            // The value must match one of these strings
            return ['sm', 'md', 'lg', 'xl', '2xl'].includes(value)
        },
    },

    position: {
        type: String,
        default: 'left',
        validator(value) {
            // The value must match one of these strings
            return ['left', 'right'].includes(value)
        },
    },
})

// CLASSES
const menuClasses = computed(() => {
    const { position, contentClasses } = props

    const positionClasses = {
        left: 'left-0',
        right: 'right-0',
    }

    return [positionClasses[position], contentClasses]
})

const toggleDropdown = () => {
    active.value = !active.value
}
</script>
