<template>
    <div
        class="relative flex h-48 w-80 cursor-pointer rounded-2xl bg-gradient-to-r text-white"
        :class="containerClass"
    >
        <img
            :src="$filters.asset('static/images/card-lines.svg')"
            :alt="paymentAccount.name"
            class="pointer-events-none absolute bottom-0 w-full select-none"
        />
        <div class="absolute left-5 top-5 max-w-[80%]">
            <div v-if="paymentAccount.name">{{ paymentAccount.name }}</div>
            <div class="text-xs capitalize" v-if="paymentConnector">
                {{ $t('creditCard.updated') }}
                {{ diffFromNow(paymentConnector.updated_at, 'seconds') }}
            </div>
        </div>
        <div class="absolute right-5 top-5">
            <base-icon
                name="line-icons:editor:pencil-01"
                variant="inherit"
                @click.stop="onClickEdit"
            />
        </div>
        <div class="absolute bottom-5 left-5" v-if="bankBalance">
            <div class="mb-2 text-xs uppercase">
                {{ $t('creditCard.bankBalance') }}
            </div>
            <div>{{ bankBalance }}</div>
        </div>
        <div class="absolute bottom-5 right-5" v-if="balance">
            <div class="mb-2 text-xs uppercase">
                {{ $t('creditCard.inCyBooks') }}
            </div>
            <div>{{ balance }}</div>
        </div>

        <edit-bank-account
            :show="state.showEdit"
            :payment-account="paymentAccount"
            @modal-close="onCloseEdit"
            @on-finish="onFinish"
        />
    </div>
</template>

<script setup>
import { diffFromNow } from '@tenant/utils/day'
import { currency } from '@tenant/core/filter'
import { ACCOUNT_TYPES } from '@tenant/utils/constants'

const emit = defineEmits(['on-finish-edit'])

const props = defineProps({
    active: {
        type: Boolean,
        default: false,
    },
    paymentAccount: {
        type: Object,
        required: true,
    },
})

const state = reactive({
    showEdit: false,
})

const paymentConnector = computed(() => props.paymentAccount.payment_connector)
const paymentCurrency = computed(() => props.paymentAccount.currency)
const bankBalance = computed(() => {
    if (props.paymentAccount.type === ACCOUNT_TYPES.BANK_FEED) {
        return currency(props.paymentAccount.balance, paymentCurrency.value.iso)
    }

    if (!paymentConnector.value || !paymentCurrency.value) {
        return null
    }

    return currency(paymentConnector.value.balance, paymentCurrency.value.iso)
})
const balance = computed(() => {
    if (!paymentCurrency.value) {
        return null
    }

    return currency(
        props.paymentAccount?.cybooks_balance ?? 0,
        paymentCurrency.value.iso
    )
})

const containerClass = computed(() => {
    const { active } = props

    const classes = {
        true: 'from-primary-900 to-primary-600',
        false: 'from-gray-600 to-gray-500',
    }

    return classes[active]
})

const onClickEdit = () => {
    state.showEdit = true
}

const onCloseEdit = () => {
    state.showEdit = false
}

const onFinish = (data) => {
    emit('on-finish-edit', data)
    onCloseEdit()
}
</script>
