<template>
    <div
        ref="formControlRef"
        class="flex flex-col"
        :class="[block ? 'w-full' : null, containerClass]"
    >
        <form-control-label :label="label" />

        <div
            class="relative flex gap-2 rounded-lg border bg-white outline-0"
            :class="containerClasses"
        >
            <form-control-highlight v-if="highlight" />

            <div v-if="iconName" class="pointer-events-none flex items-center">
                <base-icon :name="iconName" variant="gray" size="md" />
            </div>

            <div
                class="flex max-w-full grow flex-wrap items-baseline gap-x-1.5 gap-y-1"
            >
                <input
                    class="max-w-full grow cursor-pointer border-0 bg-transparent text-md text-gray-900 placeholder-gray-500 outline-0 focus:outline-none focus:ring-0 disabled:cursor-not-allowed"
                    :class="{ 'text-gray-500': disabled }"
                    v-bind="attrs"
                    v-model="inputValue"
                    :placeholder="placeholder"
                    @input="onTypeInput"
                    @focus="openSelection"
                />
            </div>

            <div
                v-if="tooltip && !errorMessage"
                class="pointer-events-none flex items-center"
            >
                <base-icon
                    name="line-icons:general:help-circle"
                    variant="grayLight"
                    size="sm"
                />
            </div>

            <form-control-icon
                v-if="!disabled"
                class="absolute right-2 top-3 text-gray-500"
                :is-multiple="false"
                :is-selected="results && !!results.length && showClear"
                :is-open="isOpenSelection"
                @clear-option="onClearResults"
                @open-selection-list="openSelection"
                @close-selection-list="closeSelection"
            />
        </div>

        <slot name="option-list">
            <form-control-option-list
                v-if="isOpenSelection"
                :results="results"
                :options="currentOptions"
                :option-value="optionValue"
                :option-label="optionLabel"
                :custom-is-selected="isSelected"
                @select-option="onClickOption"
            >
                <template v-if="parentFolder" #before-option>
                    <div
                        class="flex cursor-pointer select-none justify-between bg-white px-3.5 py-2.5 hover:bg-gray-50"
                        @click="onGoBackFolder"
                    >
                        <span
                            class="flex items-center gap-2 text-sm font-semibold text-gray-600"
                        >
                            <base-icon
                                variant="inherit"
                                name="line-icons:arrows:chevron-up"
                            />
                            {{ $t('general.goToTopFolder') }}
                        </span>
                    </div>
                </template>
                <template #option-label="{ item }">
                    <div class="flex items-center gap-2 text-gray-900">
                        <div
                            v-if="isNestedFolder(item)"
                            class="rounded-md border border-gray-300"
                        >
                            <base-icon
                                variant="inherit"
                                name="line-icons:arrows:chevron-right"
                            />
                        </div>
                        {{ item[optionLabel] }}
                    </div>
                </template>
                <template #create>
                    <slot name="create" />
                </template>
            </form-control-option-list>
        </slot>

        <form-control-hint-text
            v-if="!hideMessage"
            :hint-text="hintText"
            :error-message="errorMessage"
        />
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
}
</script>

<script setup>
import { onClickOutside } from '@vueuse/core'

const attrs = useAttrs()
const emit = defineEmits([
    'update:modelValue',
    'on-change-tree',
    'change',
    'on-close',
])

const props = defineProps({
    /**
     * Label displayed above the form field
     */
    label: {
        type: String,
        default: '',
    },
    /**
     * If there is no option selected, a placeholder will show
     */
    placeholder: {
        type: String,
        default: () => {
            const { t } = useI18n()

            return t('general.chooseValueLabel')
        },
    },
    /**
     * Short help text that goes below the input field
     */
    hintText: {
        type: String,
        default: '',
    },
    /**
     * A floating, non-actionable label used to explain an input
     */
    tooltip: {
        type: String,
        default: '',
    },
    /**
     * Error message in red shown below the input field when it's destructive
     */
    errorMessage: {
        type: String,
        default: '',
    },
    /**
     * Highlight dot and outline in yellow shown above the input field
     */
    highlight: {
        type: Boolean,
        default: false,
    },
    /**
     * Name of the icon displayed on the left corner of the input
     */
    iconName: {
        type: String,
        default: '',
    },
    /**
     * The options that you can choose from when you click on the input
     */
    options: {
        type: Array,
        default: () => [],
    },
    treeOptions: {
        type: Array,
        default: () => [],
    },
    /**
     * The returned string value is what is internally used within component to validate the following:
     *
     * - Which options are matched to a search term (searchableFields)
     * - Which options are already selected
     */
    optionValue: {
        type: String,
        default: 'id',
    },
    /**
     * The returned string value is the string that's rendered:
     *
     * - As the label for options in the menu
     * - As the label for value(s) in the control.
     */
    optionLabel: {
        type: String,
        default: 'name',
    },
    /**
     * Component model value
     */
    modelValue: {
        type: [String, Number],
        default: '',
    },
    /**
     * Width block
     */
    block: {
        type: Boolean,
        default: true,
    },
    /**
     * Hide message
     */
    hideMessage: {
        type: Boolean,
        default: false,
    },
    /**
     * Show Clear
     */
    showClear: {
        type: Boolean,
        default: true,
    },
})

const isOpenSelection = ref(false)
const inputValue = ref('')
const formControlRef = ref(null)
const folders = computed(() => props.options)
const parentFolderId = ref(null)
const treeOptions = computed({
    get: () => props.treeOptions,
    set: (treeOptions) => {
        emit('on-change-tree', treeOptions)
    },
})

const parentFolder = computed(() => {
    if (!parentFolderId.value) {
        return null
    }

    return getNestedOption(folders.value, parentFolderId.value)
})

const internalOptions = computed(() => {
    if (!parentFolder.value) {
        return folders.value
    }

    return parentFolder.value.children
})

const {
    results,
    currentOptions,
    addResultItem,
    removeResultItem,
    clearResults,
} = useSelector(internalOptions, [], props.optionValue, '', true)

const { disabled, class: containerClass } = attrs
const containerClasses = computed(() => {
    const { errorMessage, highlight } = props

    return {
        'border-gray-300': !errorMessage,
        'ring-4 ring-offset-0': isOpenSelection.value,
        'border-primary-300 ring-primary-100':
            isOpenSelection.value && !errorMessage,
        'border-danger-300': errorMessage && !disabled,
        'border-danger-300 ring-danger-100':
            isOpenSelection.value && errorMessage && !disabled,
        'border-gray-300 bg-gray-50 cursor-not-allowed': disabled,
        'border-warning-300 focus:border-warning-300 ring-4 ring-warning-100 focus:ring-warning-100':
            highlight,
    }
})

watch(
    results,
    (results) => {
        handleFolderChange(results[results.length - 1] || null)
    },
    { deep: true }
)

watch(
    () => treeOptions.value,
    (treeOptions) => {
        if (!treeOptions) {
            return
        }

        const nestedNames = []
        for (const treeOption of treeOptions) {
            const data = getNestedOption(folders.value, treeOption)
            if (!data) {
                continue
            }

            nestedNames.push(data.name)
        }

        inputValue.value = nestedNames.join('/')
    },
    { deep: true }
)

watch(isOpenSelection, (value) => {
    if (!value) {
        emit('on-close')
    }
})

const onClickOption = (option) => {
    if (option.children) {
        addResultItem(option)
    } else {
        emit('update:modelValue', option.id)
        emit('change', option.id)

        const treeIds = results.value.map((folder) => folder.id)
        treeOptions.value = [...treeIds, option.id]
        closeSelection()
    }
}

const handleFolderChange = (option) => {
    if (!option) {
        return
    }

    if (!isNestedFolder(option)) {
        return
    }

    parentFolderId.value = option.id
}

const onClearResults = () => {
    inputValue.value = ''
    clearResults()
    closeSelection()
}

const onTypeInput = () => {
    clearResults()
}

const openSelection = () => {
    isOpenSelection.value = true
}

const closeSelection = () => {
    isOpenSelection.value = false
}

const getNestedOption = (folders, selectedFolderId) => {
    for (const folder of folders) {
        if (folder.id === selectedFolderId) {
            return folder
        }

        if (!folder.children) {
            continue
        }

        const nestedOption = getNestedOption(folder.children, selectedFolderId)
        if (nestedOption) {
            return nestedOption
        }
    }

    return null
}

const isSelected = (option) => {
    if (!treeOptions.value) {
        return false
    }

    return treeOptions.value.includes(option.id)
}

const isNestedFolder = (option) => {
    return option.children
}

const onGoBackFolder = () => {
    const pFolder = unref(parentFolder)
    if (!pFolder) {
        return
    }

    parentFolderId.value = pFolder.level === 1 ? null : pFolder.parent
    removeResultItem(pFolder)
}

onClickOutside(formControlRef, closeSelection)
</script>
