<template>
    <!-- Date -->
    <base-dropdown
        variant="default"
        :content-classes="[
            'h-[444px] max-h-[444px]',
            {
                'min-w-[300px]': showDatePicker,
            },
        ]"
    >
        <template #btn-content>
            <base-icon name="line-icons:time:calendar-date" variant="gray" />

            {{ currentOption }}

            <base-icon name="line-icons:arrows:chevron-down" variant="gray" />
        </template>

        <template #default="{ toggleDropdown }">
            <div class="flex h-full w-full">
                <div
                    class="min-w-[145px]"
                    :class="{
                        'border-r border-gray-200': showDatePicker,
                    }"
                >
                    <base-dropdown-item
                        v-for="key in Object.keys(dateOptions)"
                        :key="key"
                        :class="{
                            'bg-gray-50': showDatePicker && key === 'custom',
                        }"
                        @click="
                            () => {
                                if (key !== 'custom') {
                                    toggleDropdown()
                                    showDatePicker = false
                                }
                                setDateOption(key)
                            }
                        "
                    >
                        {{ dateOptions[key].label }}
                    </base-dropdown-item>
                </div>

                <form-date-picker
                    inline
                    :single="isSingleFilter"
                    :multi-calendars="!isSingleFilter"
                    class="date-dropdown-picker h-full flex-1"
                    v-if="showDatePicker"
                    v-model="customDateRange"
                    @update:model-value="
                        (event) => {
                            toggleDropdown()
                            onChangeDate(event)
                        }
                    "
                    @close="toggleDropdown"
                >
                    <template #left-sidebar>
                        <span></span>
                    </template>
                </form-date-picker>
            </div>
        </template>
    </base-dropdown>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import { REPORT_TYPES } from '@tenant/modules/tenant/reports/utils/constants'
import { shortServerFormat } from '@tenant/utils/day'
import { isArray } from 'lodash-es'

const { t } = useI18n()
const emit = defineEmits(['set-dates', 'set-date-range-key'])

const props = defineProps({
    dateRangeKey: {
        type: String,
        default: 'year',
    },
    queryKey: {
        type: String,
        default: '',
    },
    queryValue: {
        type: String,
        default: '',
    },
    isSingleFilter: {
        type: Boolean,
        default: false,
    },
    reportType: {
        type: String,
        default: '',
        validator(value) {
            return Object.values(REPORT_TYPES).includes(value)
        },
    },
})

const currentOption = ref(t('dateDropdown.optionToday'))
const currentDate = new Date()
const showDatePicker = ref(false)
const customDateRange = ref([new Date(), new Date()])

const queries = {
    [props.queryKey]: props.queryValue,
}

const { useRangeFilter } = useFilters(queries)
const dateStart = ref(null)
const dateEnd = ref(null)

if (props.queryKey) {
    const { fromValue, toValue } = useRangeFilter(
        props.queryKey,
        props.queryValue,
        true
    )

    watchEffect(() => {
        if (!props.isSingleFilter) {
            fromValue.value = dateStart.value
        }
        toValue.value = dateEnd.value
    })
}

const formatDate = (date) => {
    return `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
}

// Date options
const dateOptions = ref({
    today: {
        label: t('dateDropdown.optionToday'),
        action: () => {
            dateStart.value = currentDate
            dateEnd.value = currentDate
        },
    },
    week: {
        label: t('dateDropdown.optionThisWeek'),
        action: () => {
            const currentDay = currentDate.getDay()
            dateStart.value = new Date(currentDate)

            dateStart.value.setDate(currentDate.getDate() - currentDay)

            dateEnd.value = new Date(dateStart.value)
            dateEnd.value.setDate(dateStart.value.getDate() + 6)
        },
    },
    month: {
        label: t('dateDropdown.optionThisMonth'),
        action: () => {
            dateStart.value = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                1
            )
            const nextMonth = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() + 1,
                1
            )
            dateEnd.value = new Date(nextMonth - 1)
        },
    },
    quarter: {
        label: t('dateDropdown.optionThisQuarter'),
        action: () => {
            const currentMonth = currentDate.getMonth()
            const quarterStartMonth = Math.floor(currentMonth / 3) * 3

            dateStart.value = new Date(
                currentDate.getFullYear(),
                quarterStartMonth,
                1
            )

            const nextQuarter = quarterStartMonth + 3
            dateEnd.value = new Date(currentDate.getFullYear(), nextQuarter, 0)
        },
    },
    year: {
        label: t('dateDropdown.optionThisYear'),
        action: () => {
            dateStart.value = new Date(currentDate.getFullYear(), 0, 1)

            dateEnd.value = new Date(currentDate.getFullYear(), 11, 31)
        },
    },
    yesterday: {
        label: t('dateDropdown.optionYesterday'),
        action: () => {
            dateStart.value = new Date(currentDate)
            dateStart.value.setDate(currentDate.getDate() - 1)

            dateEnd.value = dateStart.value
        },
    },
    'pre-week': {
        label: t('dateDropdown.optionPrevWeek'),
        action: () => {
            const currentDay = currentDate.getDay()
            const startOfCurrentWeek = new Date(currentDate)
            startOfCurrentWeek.setDate(currentDate.getDate() - currentDay)

            dateStart.value = new Date(startOfCurrentWeek)
            dateStart.value.setDate(startOfCurrentWeek.getDate() - 7)
            dateEnd.value = new Date(dateStart.value)
            dateEnd.value.setDate(dateStart.value.getDate() + 6)
        },
    },
    'pre-month': {
        label: t('dateDropdown.optionPrevMonth'),
        action: () => {
            const firstDayOfCurrentMonth = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                1
            )
            dateStart.value = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() - 1,
                1
            )
            dateEnd.value = new Date(firstDayOfCurrentMonth - 1)
        },
    },
    'pre-quarter': {
        label: t('dateDropdown.optionPrevQuarter'),
        action: () => {
            const currentMonth = currentDate.getMonth()
            const quarterStartMonth = Math.floor(currentMonth / 3) * 3
            const quarterAdjustment = quarterStartMonth - 3 // Subtract 3 to go to the previous quarter

            dateStart.value = new Date(
                currentDate.getFullYear(),
                quarterAdjustment,
                1
            )
            dateEnd.value = new Date(
                currentDate.getFullYear(),
                quarterAdjustment + 3,
                0
            )
        },
    },
    'pre-year': {
        label: t('dateDropdown.optionPrevYear'),
        action: () => {
            const startOfCurrentYear = new Date(currentDate.getFullYear(), 0, 1)
            const endOfCurrentYear = new Date(currentDate.getFullYear(), 11, 31)

            dateStart.value = new Date(startOfCurrentYear)
            dateStart.value.setFullYear(startOfCurrentYear.getFullYear() - 1)

            dateEnd.value = new Date(endOfCurrentYear)
            dateEnd.value.setFullYear(endOfCurrentYear.getFullYear() - 1)
        },
    },
    custom: {
        label: t('dateDropdown.optionCustom'),
        action: () => {
            showDatePicker.value = true
            let from = null
            let to = null

            if (
                localStorage.getItem(
                    `reports.${props.reportType}.dateRangeKey`
                ) === 'custom'
            ) {
                const dateRangeStorage = localStorage.getItem(
                    `reports.${props.reportType}.dateRangeValue`
                )
                const { start, end } = dateRangeStorage
                    ? JSON.parse(dateRangeStorage)
                    : { start: null, end: null }

                from = start
                to = end
            }

            dateStart.value = from ?? new Date()
            dateEnd.value = to ?? new Date()
            customDateRange.value = props.isSingleFilter
                ? dateEnd.value
                : [
                      shortServerFormat(dateStart.value),
                      shortServerFormat(dateEnd.value),
                  ]
        },
    },
})

const setDateOption = (key) => {
    const option = dateOptions.value[key]
    option.action()
    dateStart.value = shortServerFormat(dateStart.value)
    dateEnd.value = shortServerFormat(dateEnd.value)
    currentOption.value = option.label
    emit('set-dates', new Date(dateStart.value), new Date(dateEnd.value))
    emit('set-date-range-key', key)
}

watch(
    () => props.dateRangeKey,
    (key) => {
        setDateOption(key)
    },
    { immediate: true }
)

const onChangeDate = (value) => {
    if (isArray(value)) {
        dateStart.value = formatDate(new Date(value[0]))
        dateEnd.value = formatDate(new Date(value[1]))
    } else {
        dateEnd.value = formatDate(new Date(value))
    }
    currentOption.value = t('dateDropdown.optionCustom')
    emit('set-dates', new Date(dateStart.value), new Date(dateEnd.value))
    emit('set-date-range-key', 'custom')
}
</script>

<style lang="scss">
.date-dropdown-picker {
    .dp__main {
        @apply h-full;
    }

    .dp__outer_menu_wrap {
        @apply h-full;
    }

    .dp__menu {
        --dp-menu-border-color: white;
        @apply flex h-full flex-col;

        .dp__menu_content_wrapper {
            flex: 1;
        }

        .dp__menu_inner {
            @apply h-full;
        }
    }
}
</style>
