<template>
    <div class="flex w-full flex-col">
        <form-control-label :label="label" />

        <div class="relative">
            <form-control-highlight v-if="highlight" />

            <div v-if="editor" class="mb-2 bg-gray-50">
                <slot name="toolbar-container-top" v-bind="{ editor }" />

                <form-rich-text-toolbar-container>
                    <form-rich-text-toolbar-button
                        has-sub-menu
                        icon="line-icons:editor:heading-01"
                        :label="$t('richText.toolbar.heading')"
                    >
                        <form-rich-text-toolbar-dropdown-item
                            :label="$t('richText.toolbar.paragraph')"
                            @click="setParagraph"
                        />
                        <form-rich-text-toolbar-dropdown-item
                            :label="
                                $t('richText.toolbar.headingNumber', {
                                    number: 1,
                                })
                            "
                            @click="toggleHeading(1)"
                        />
                        <form-rich-text-toolbar-dropdown-item
                            :label="
                                $t('richText.toolbar.headingNumber', {
                                    number: 2,
                                })
                            "
                            @click="toggleHeading(2)"
                        />
                        <form-rich-text-toolbar-dropdown-item
                            :label="
                                $t('richText.toolbar.headingNumber', {
                                    number: 3,
                                })
                            "
                            @click="toggleHeading(3)"
                        />
                        <form-rich-text-toolbar-dropdown-item
                            :label="
                                $t('richText.toolbar.headingNumber', {
                                    number: 4,
                                })
                            "
                            @click="toggleHeading(4)"
                        />
                        <form-rich-text-toolbar-dropdown-item
                            :label="
                                $t('richText.toolbar.headingNumber', {
                                    number: 5,
                                })
                            "
                            @click="toggleHeading(5)"
                        />
                        <form-rich-text-toolbar-dropdown-item
                            :label="
                                $t('richText.toolbar.headingNumber', {
                                    number: 6,
                                })
                            "
                            @click="toggleHeading(6)"
                        />
                        <form-rich-text-toolbar-dropdown-item
                            :label="$t('richText.toolbar.blockquote')"
                            @click="toggleBlockquote"
                        />
                    </form-rich-text-toolbar-button>

                    <form-rich-text-toolbar-group>
                        <form-rich-text-toolbar-button
                            icon="line-icons:editor:bold-01"
                            :label="$t('richText.toolbar.bold')"
                            @click="toggleBold"
                        />
                        <form-rich-text-toolbar-button
                            icon="line-icons:editor:italic-01"
                            :label="$t('richText.toolbar.italic')"
                            @click="toggleItalic"
                        />
                        <form-rich-text-toolbar-button
                            icon="line-icons:editor:underline-01"
                            :label="$t('richText.toolbar.underline')"
                            @click="toggleUnderline"
                        />
                        <form-rich-text-toolbar-button
                            icon="line-icons:editor:strikethrough-01"
                            :label="$t('richText.toolbar.strike')"
                            @click="toggleStrike"
                        />
                    </form-rich-text-toolbar-group>

                    <form-rich-text-toolbar-group>
                        <form-rich-text-toolbar-button
                            icon="line-icons:editor:align-left"
                            :label="$t('richText.toolbar.alignLeft')"
                            @click="setTextAlign('left')"
                        />
                        <form-rich-text-toolbar-button
                            icon="line-icons:editor:align-center"
                            :label="$t('richText.toolbar.alignCenter')"
                            @click="setTextAlign('center')"
                        />
                        <form-rich-text-toolbar-button
                            icon="line-icons:editor:align-right"
                            :label="$t('richText.toolbar.alignRight')"
                            @click="setTextAlign('right')"
                        />
                        <form-rich-text-toolbar-button
                            icon="line-icons:editor:align-justify"
                            :label="$t('richText.toolbar.alignJustify')"
                            @click="setTextAlign('justify')"
                        />
                    </form-rich-text-toolbar-group>

                    <form-rich-text-toolbar-group>
                        <form-rich-text-toolbar-button
                            icon="line-icons:editor:code-snippet-01"
                            :label="$t('richText.toolbar.code')"
                            @click="toggleCode"
                        />
                        <form-rich-text-toolbar-button
                            icon="line-icons:development:code-square-01"
                            :label="$t('richText.toolbar.codeBlock')"
                            @click="toggleCodeBlock"
                        />
                    </form-rich-text-toolbar-group>

                    <form-rich-text-toolbar-group>
                        <form-rich-text-toolbar-button
                            icon="line-icons:arrows:corner-down-left"
                            :label="$t('richText.toolbar.undo')"
                            @click="undo"
                        />
                        <form-rich-text-toolbar-button
                            icon="line-icons:arrows:corner-down-right"
                            :label="$t('richText.toolbar.redo')"
                            @click="redo"
                        />
                    </form-rich-text-toolbar-group>

                    <slot name="toolbar" v-bind="{ editor }" />
                </form-rich-text-toolbar-container>

                <slot name="toolbar-container-bottom" v-bind="{ editor }" />
            </div>

            <div
                :class="{
                    'ProseMirror-error': errorMessage,
                    'ProseMirror-highlight': highlight,
                }"
            >
                <editor-content
                    :editor="editor"
                    :class="editorContainerClass"
                />
            </div>
        </div>

        <form-control-hint-text
            :hint-text="hintText"
            :error-message="errorMessage"
        />
    </div>
</template>

<script setup>
import { useEditor, EditorContent } from '@tiptap/vue-3'
import TextAlign from '@tiptap/extension-text-align'
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit'

const { t } = useI18n()

const props = defineProps({
    /**
     * Label displayed above the form field
     */
    label: {
        type: String,
        default: '',
    },
    /**
     * Short help text that goes below the input field
     */
    hintText: {
        type: String,
        default: '',
    },
    /**
     * Error message in red shown below the input field when it's destructive
     */
    errorMessage: {
        type: String,
        default: '',
    },
    /**
     * Placeholder
     */
    placeholder: {
        type: String,
        default: '',
    },
    /**
     * Highlight dot and outline in yellow shown above the input field
     */
    highlight: {
        type: Boolean,
        default: false,
    },
    /**
     * Component model value
     */
    modelValue: {
        type: String,
        default: '',
    },
    /**
     * Editor container class
     */
    editorContainerClass: {
        type: [String, Object, Array],
        default: null,
    },

    extensions: {
        type: Array,
        default: () => [],
    },
})

const emit = defineEmits(['update:modelValue'])

const editor = useEditor({
    extensions: [
        StarterKit,
        Underline,
        TextAlign.configure({
            types: ['heading', 'paragraph'],
        }),
        Placeholder.configure({
            placeholder: props.placeholder || t('richText.placeholder'),
        }),
        ...props.extensions,
    ],
    content: props.modelValue,
    onUpdate: () => {
        emit('update:modelValue', editor.value.getHTML())
    },
})

watch(
    () => props.modelValue,
    (value) => {
        const isSame = editor.value.getHTML() === value

        if (isSame) {
            return
        }

        editor.value.commands.setContent(value, false)
    }
)

const undo = () => {
    editor.value.commands.undo()
}

const redo = () => {
    editor.value.commands.redo()
}

const toggleBold = () => {
    editor.value.commands.toggleBold()
}

const toggleItalic = () => {
    editor.value.commands.toggleItalic()
}

const toggleUnderline = () => {
    editor.value.commands.toggleUnderline()
}

const toggleStrike = () => {
    editor.value.commands.toggleStrike()
}

const toggleCode = () => {
    editor.value.commands.toggleCode()
}

const toggleCodeBlock = () => {
    editor.value.commands.toggleCodeBlock()
}

const setTextAlign = (align) => {
    editor.value.commands.setTextAlign(align)
}

const setParagraph = () => {
    editor.value.commands.setParagraph()
}

const toggleHeading = (level) => {
    editor.value.commands.toggleHeading({ level })
}

const toggleBlockquote = () => {
    editor.value.commands.toggleBlockquote()
}

defineExpose({
    editor,
})
</script>

<style lang="scss">
.ProseMirror {
    @apply block min-h-[10rem] w-full rounded-lg border border-gray-300 px-3.5 py-2.5 text-md text-gray-900 placeholder-gray-500 outline-0;

    &-error & {
        @apply border-danger-300 focus:border-danger-300 focus:ring-danger-100;
    }

    &-focused {
        @apply border-primary-300 ring-4 ring-primary-100 ring-offset-0;
    }

    &-highlight & {
        @apply border-warning-300 ring-4 ring-warning-100 focus:border-warning-300 focus:ring-warning-100;
    }

    & p.is-editor-empty:first-child::before {
        @apply pointer-events-none float-left h-0 text-gray-500 content-[attr(data-placeholder)];
    }

    > * + * {
        @apply my-4;
    }

    ul {
        @apply list-disc px-5;
    }

    ol {
        @apply list-decimal px-5;
    }

    code {
        @apply rounded bg-primary-50 px-1.5 py-0.5 text-primary-700;
    }

    pre {
        @apply rounded-lg bg-gray-800 px-5 py-3 text-white;

        code {
            @apply bg-transparent p-0 text-xs text-inherit;
        }
    }

    img {
        @apply h-auto max-w-full;
    }

    blockquote {
        @apply border-l-4 border-gray-200 pl-4;
    }
}
</style>
