<template>
    <button
        type="button"
        class="border-1 relative inline-flex items-center rounded-lg border font-medium outline-none transition-all duration-150 disabled:pointer-events-none disabled:opacity-50"
        :class="buttonClasses"
        v-bind="attrs"
        :disabled="loading || attrs.disabled"
    >
        <template v-if="loading">
            <span
                class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
            >
                <base-loading size="sm" />
            </span>
        </template>

        <slot name="content" v-bind="{ loading }">
            <span :class="['flex items-center gap-2', loading && 'opacity-0']">
                <base-icon
                    v-if="icon"
                    :name="icon"
                    :class="iconClass"
                    variant="inherit"
                />
                <slot>
                    {{ label }}
                </slot>
            </span>
        </slot>
    </button>
</template>

<script>
export default {
    inheritAttrs: false,
}
</script>

<script setup>
import { computed, useAttrs } from 'vue'

const props = defineProps({
    label: {
        type: String,
    },

    icon: {
        type: String,
        default: '',
    },

    iconClass: {
        type: String,
        default: '',
    },

    fullWidth: {
        type: Boolean,
        default: false,
    },

    loading: {
        type: Boolean,
        default: false,
    },

    variant: {
        type: String,
        default: 'primary',
        required: false,
        validator(value) {
            return [
                'default',
                'white',
                'tertiary-color',
                'tertiary-gray',
                'primary',
                'success',
                'secondary-color',
                'gray',
                'danger',
                'danger-300',
                'danger-50',
                'link-color',
                'link-gray',
                'link-danger',
            ].includes(value)
        },
    },

    hasPadding: {
        type: Boolean,
        default: true,
    },

    outline: {
        type: Boolean,
        default: false,
    },

    size: {
        type: String,
        default: 'md',
    },
})

const attrs = useAttrs()

const buttonClasses = computed(() => {
    let classes = {
        'text-sm': props.size === 'sm' || props.size === 'md',
        'text-md': props.size === 'lg' || props.size === 'xl',
        'text-lg': props.size === '2xl',

        'w-full justify-center': props.fullWidth,
        'opacity-50': props.loading,

        'text-gray-700 border-gray-300 hover:bg-gray-100 focus:ring-gray-200':
            props.variant === 'default',
        'text-gray-700 border-gray-300 hover:bg-gray-100 focus:ring-gray-200 bg-white':
            props.variant === 'white',
        'text-primary-700 bg-transparent border-white hover:bg-primary-100 hover:border-primary-100 hover:text-primary-800 focus:ring-0':
            props.variant === 'tertiary-color',
        'text-gray-500 bg-transparent border-transparent hover:bg-gray-50 hover:text-gray-600 focus:ring-0':
            props.variant === 'tertiary-gray',
        'text-primary-700 bg-primary-50 border-primary-50 hover:bg-primary-100 hover:border-primary-100 hover:text-primary-800 focus:ring-primary-200':
            props.variant === 'secondary-color',
        'text-primary-700 bg-transparent border-transparent hover:text-primary-800':
            props.variant === 'link-color',
        'text-gray-500 bg-transparent border-transparent hover:text-gray-600':
            props.variant === 'link-gray',
        'text-danger-700 bg-transparent border-transparent hover:text-danger-800':
            props.variant === 'link-danger',
        'focus:ring-2': !['link-color', 'link-gray', 'link-danger'].includes(
            props.variant
        ),
    }

    if (props.outline) {
        classes = {
            ...classes,
            'text-primary-600 border-primary-600 hover:bg-primary-50 hover:text-primary-700 focus:ring-primary-200':
                props.variant === 'primary',
            'text-gray-700 border-gray-300 hover:bg-gray-50 hover:text-gray-800 focus:ring-gray-200':
                props.variant === 'gray',
            'text-danger-600 border-danger-600 hover:bg-danger-50 hover:text-danger-700 focus:ring-danger-200':
                props.variant === 'danger',
            'text-danger-500 border-danger-300 hover:bg-danger-50 hover:text-danger-700 focus:ring-danger-200':
                props.variant === 'danger-300',
        }
    } else {
        classes = {
            ...classes,
            'text-white bg-primary-600 border-primary-600 hover:bg-primary-700 hover:border-primary-700 focus:ring-primary-200':
                props.variant === 'primary',
            'text-white bg-success-600 border-success-600 hover:bg-success-700 hover:border-success-700 focus:ring-success-200':
                props.variant === 'success',
            'text-white bg-gray-300 border-gray-300 hover:bg-gray-400 hover:border-gray-400 focus:ring-gray-200':
                props.variant === 'gray',
            'text-white bg-danger-600 border-danger-600 hover:bg-danger-700 hover:border-danger-700 focus:ring-danger-200':
                props.variant === 'danger',
            'text-danger-700 bg-danger-50 border-danger-50 hover:bg-danger-100 hover:border-danger-100 focus:ring-danger-200':
                props.variant === 'danger-50',
        }
    }

    // Set the paddings for the different button sizes (only if the variant is not a Link Color or Link Gray)
    if (props.hasPadding) {
        classes = {
            ...classes,
            'px-3.5 py-2': props.size === 'sm',
            'px-4 py-2.5': props.size === 'md',
            'px-5 py-2.5': props.size === 'lg',
            'px-5 py-3': props.size === 'xl',
            'px-6 py-3.5': props.size === '2xl',
        }
    }

    return classes
})
</script>
