<template>
    <base-modal
        close
        size="lg"
        :show="show"
        @modal-close="closeModal"
        :loading="loading"
    >
        <template #modal-header>
            <span class="text-lg font-medium text-gray-900">
                {{ $t('modal.editBankAccount.title') }}
            </span>
        </template>

        <div class="flex flex-col gap-3.5">
            <form-text-input
                disabled
                :label="$t('modal.editBankAccount.yourBank.label')"
                size="sm"
                :model-value="bankName || paymentAccount.name"
            />

            <base-alert :variant="hasConnector ? 'success' : 'primary'">
                <template #title="{ titleClass }">
                    <span
                        v-if="hasConnector"
                        class="text-sm font-medium"
                        :class="titleClass"
                    >
                        {{
                            $t(
                                'modal.editBankAccount.warning.bankFeedsAvailable'
                            )
                        }}
                    </span>
                    <span
                        v-else
                        class="text-sm font-medium"
                        :class="titleClass"
                    >
                        {{
                            $t('modal.editBankAccount.warning.notAvailable', {
                                name: paymentAccount.name,
                            })
                        }}
                    </span>
                </template>
            </base-alert>

            <form-text-input
                v-model="name"
                :error-message="nameError"
                :label="$t('modal.editBankAccount.accountName.label')"
                size="sm"
                :hint-text="$t('modal.editBankAccount.accountName.hint')"
            />

            <form-text-input
                disabled
                :label="$t('modal.editBankAccount.currency.label')"
                :model-value="paymentAccount.currency.iso"
            />

            <form-text-input
                v-if="!hasConnector"
                v-model="iban"
                :error-message="ibanError"
                :label="$t('modal.editBankAccount.iban.label')"
            />
        </div>

        <template #modal-footer>
            <div class="flex justify-between gap-3 pt-3">
                <base-button
                    outline
                    full-width
                    variant="default"
                    @click="closeModal"
                >
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button full-width variant="primary" @click="onSave">
                    {{ $t('general.save') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
const emit = defineEmits(['modal-close', 'on-finish'])

const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    paymentAccount: {
        type: Object,
        required: true,
    },
})

const { execute, loading } = useApi('/api/payment-accounts/{id}', 'PATCH')

const { handleSubmit, setErrors } = useForm({
    initialValues: {
        name: props.paymentAccount.name,
        iban: props.paymentAccount.iban,
    },
})

const { value: name, errorMessage: nameError } = useField('name')
const { value: iban, errorMessage: ibanError } = useField('iban')

const bankName = computed(
    () => props.paymentAccount.payment_connector?.provider_name
)

const hasConnector = computed(() => !!props.paymentAccount.payment_connector)

const closeModal = () => {
    emit('modal-close')
}

const onSave = handleSubmit((data) => {
    execute({
        data,
        params: {
            id: props.paymentAccount.id,
        },
    })
        .catch(({ errors }) => {
            setErrors(errors)
        })
        .then((resp) => {
            emit('on-finish', resp)
        })
})
</script>
