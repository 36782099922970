<template>
    <data-grid
        :columns="creditNoteColumns"
        :data-source="entities.data"
        class="rounded-none"
    >
        <template #column-date="{ item }">
            {{ $filters.dateHumanFormat(item.date_of_credit_note) }}
        </template>

        <template #column-amount="{ item }">
            {{
                $filters.currency(
                    item.sale_document.amount_gross_total,
                    item.sale_document.currency.iso
                )
            }}
        </template>

        <template #column-number="{ item }">
            <router-link
                class="text-primary-700"
                :to="{
                    name: 'credit-notes.dispatch',
                    params: { id: item.id },
                }"
            >
                {{ item.sale_document.number }}
            </router-link>
        </template>

        <template #footer>
            <grid-pagination
                :total="entities.last_page"
                :page="entities.current_page"
                :replace-query="replaceQuery"
            />
        </template>

        <template #empty v-if="$slots['empty']">
            <slot name="empty" />
        </template>
    </data-grid>
</template>

<script setup>
import { useApiFactory } from '@tenant/composables'

const props = defineProps({
    replaceQuery: {
        type: Boolean,
        default: true,
    },
    title: {
        type: String,
        default: null,
    },
    compact: {
        type: Boolean,
        default: false,
    },
    queries: {
        type: Object,
        default: () => ({}),
    },
})

const { t } = useI18n()

const creditNoteColumns = [
    {
        property: 'date',
        label: t('invoices.appliedCredits.date'),
    },
    {
        property: 'number',
        label: t('invoices.appliedCredits.creditNo'),
    },
    {
        property: 'amount',
        label: t('invoices.appliedCredits.creditsApplied'),
    },
]

const { useApiSearch, entities } = useApiFactory('credit-notes')
useApiSearch({
    ...props.queries,
})
</script>
