<template>
    <div class="relative h-[1200px]">
        <template v-if="loading">
            <span
                class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
            >
                <base-loading />
            </span>
        </template>

        <template v-if="props.content && !loading">
            <div class="relative mx-auto h-full w-full">
                <object
                    :data="base64ToFile(props.content)"
                    type="application/pdf"
                    width="100%"
                    height="100%"
                    class="flex items-center justify-center"
                ></object>
            </div>
        </template>
    </div>
</template>

<script setup>
const props = defineProps({
    content: {
        type: [String, null],
        required: true,
    },
    loading: {
        type: Boolean,
        default: false,
    },
})

const base64ToFile = (base64String) => {
    // Decode Base64 to binary data
    const binaryData = atob(base64String)

    // Create a Blob from the binary data
    const blob = new Blob(
        [new Uint8Array([...binaryData].map((char) => char.charCodeAt(0)))],
        { type: 'application/pdf' }
    )

    // Create a URL for the Blob
    return URL.createObjectURL(blob)
}
</script>
