<template>
    <div class="flex border-b bg-gray-600">
        <!-- Logo -->
        <div
            class="flex w-80 items-center gap-2.5 border-r border-gray-700 px-6 py-5"
        >
            <div
                class="flex h-8 w-8 items-center justify-center rounded-md bg-gray-100"
            >
                <base-icon
                    name="line-icons:education:book-open-01"
                    class="text-primary-600"
                    variant="inherit"
                />
            </div>
            <span class="text-lg font-semibold text-gray-25">
                {{ $t('general.accountant') }}
            </span>
        </div>

        <div class="flex grow items-center justify-between gap-2.5 px-6 py-5">
            <!-- Search -->
            <accountant-partial-search-bar />

            <!-- Actions -->
            <div class="flex items-center gap-2.5">
                <!-- Notifications -->
                <!--                <base-tooltip-->
                <!--                    dark-->
                <!--                    hover-->
                <!--                    :content="$t('topBand.notificationTooltip')"-->
                <!--                >-->
                <!--                    <div class="relative">-->
                <!--                        <base-button-->
                <!--                            class="bg-white p-2"-->
                <!--                            icon="line-icons:alerts-&-feedback:bell-01"-->
                <!--                            variant="default"-->
                <!--                            size="sm"-->
                <!--                            :hasPadding="false"-->
                <!--                        />-->

                <!--                        &lt;!&ndash; Notification status/dot &ndash;&gt;-->
                <!--                        <span-->
                <!--                            class="absolute right-2 top-1.5 h-2.5 w-2.5 rounded-full bg-primary-600"-->
                <!--                        ></span>-->
                <!--                    </div>-->
                <!--                </base-tooltip>-->

                <!-- Settings -->
                <base-tooltip
                    dark
                    hover
                    :content="$t('topBand.settingsTooltip')"
                >
                    <base-button
                        class="bg-white p-2"
                        icon="line-icons:general:settings-01"
                        variant="default"
                        size="sm"
                        :hasPadding="false"
                        @click="onShowSetting"
                    />
                </base-tooltip>

                <!-- Profile -->
                <base-button
                    class="bg-white"
                    variant="default"
                    size="sm"
                    icon="line-icons:users:user-03"
                    @click="showUserMenu = true"
                >
                    {{ centralUser.full_name }}
                </base-button>
            </div>

            <partial-accountant-slide-out
                :show="showUserMenu"
                @close="showUserMenu = false"
            />
        </div>
    </div>
</template>

<script setup>
const { centralUser } = useAuth()

const showUserMenu = ref(false)
const router = useRouter()

const onShowSetting = () => {
    router.push({
        name: 'accountant.setting',
    })
}
</script>
