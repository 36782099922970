<template>
    <div class="rounded-lg border border-gray-200">
        <div
            ref="dropzoneRef"
            class="w-full px-6 py-4"
            :class="[
                {
                    'pointer-events-none': loading || uploadFile,
                    'cursor-pointer': !uploadFile,
                },
                containerClass,
            ]"
        >
            <div
                v-if="uploadFile"
                class="pointer-events-none relative flex min-h-[300px] items-center p-4"
            >
                <div
                    v-if="loading"
                    class="absolute bottom-0 left-0 right-0 top-0 z-50 bg-white opacity-50"
                >
                    <span
                        class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
                    >
                        <base-loading size="lg" />
                    </span>
                </div>
                <div
                    v-if="isImage(uploadFile)"
                    class="relative flex w-full items-center"
                >
                    <slot name="before-img" />
                    <img
                        :src="getUrlByFile(uploadFile)"
                        class="w-full object-contain"
                    />
                </div>
                <div
                    v-else
                    class="flex w-full flex-col items-center justify-center gap-2.5"
                >
                    <img
                        v-if="isPdfFile(uploadFile)"
                        :src="$filters.asset('static/images/pdf.png')"
                        class="w-20 object-contain"
                    />
                    <base-icon
                        v-else
                        name="line-icons:files:file-04"
                        size="4xl"
                    />
                    <span>{{ uploadFile.name }}</span>
                </div>
            </div>
            <div
                v-else
                class="pointer-events-none relative flex h-full flex-col items-center justify-center"
            >
                <span
                    class="mb-4 flex items-center justify-center rounded-full bg-gray-100 p-2 text-gray-600 ring-8 ring-gray-50"
                >
                    <div
                        v-if="loading"
                        class="absolute bottom-0 left-0 right-0 top-0 z-50 bg-white opacity-50"
                    >
                        <span
                            class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
                        >
                            <base-loading size="lg" />
                        </span>
                    </div>
                    <base-icon
                        name="line-icons:general:upload-cloud-02"
                        size="lg"
                        variant="inherit"
                    />
                </span>
                <i18n-t
                    keypath="fileUpload.uploadLabel"
                    tag="span"
                    class="mb-1 text-sm text-gray-500"
                >
                    <span class="text-link font-medium">
                        {{ $t('fileUpload.clickToUploadLabel') }}
                    </span>
                </i18n-t>
                <span class="text-xs text-gray-500" v-if="description">
                    {{ description }}
                </span>
            </div>
        </div>

        <div
            v-if="uploadFile"
            class="flex items-center justify-center gap-2.5 border-t border-gray-200 px-6 py-3"
        >
            <base-button variant="default" full-width @click="triggerUpload">
                {{ $t('general.upload') }}
            </base-button>
            <base-button variant="danger" full-width @click="onShowDelete">
                {{ $t('general.delete') }}
            </base-button>
        </div>

        <div
            v-if="errorMessage"
            class="flex flex-col rounded-lg border border-danger-300 bg-danger-25 p-4"
        >
            <div class="flex justify-between">
                <div class="flex flex-col">
                    <span class="text-sm font-medium text-danger-700">
                        {{ errorMessage }}
                    </span>
                </div>
            </div>
        </div>

        <base-delete-modal
            :show="state.modalDelete"
            @modal-close="onDeleteClose"
            @confirm-delete="confirmDelete"
        >
            <template #title>
                <slot name="modal-title" />
            </template>
            <template #description>
                <slot name="modal-description" />
            </template>
        </base-delete-modal>
    </div>
</template>

<script setup>
import Dropzone from 'dropzone'
import { isEmpty } from 'lodash-es'
import { sameOrigin } from '@tenant/utils/helper'
import axios from 'axios'
import coreAxios from '@tenant/core/init/axios'

const emit = defineEmits(['update:modelValue', 'onRemoveFile', 'onChangeFiles'])

const props = defineProps({
    containerClass: {
        type: String,
        default: null,
    },
    modelValue: {
        type: [Array, File, Object],
        default: () => [],
    },
    errorMessage: {
        type: String,
        default: null,
    },
    onCustomUploadFile: {
        type: Function,
        required: false,
        default: null,
    },
    // ['.svg', '.jpeg'...]
    acceptedFiles: {
        type: Array,
        default: () => [],
    },
    description: {
        type: String,
        default: '',
    },
    customPath: {
        type: Function,
        required: false,
        default: null,
    },
    customName: {
        type: Function,
        required: false,
        default: null,
    },
})

const dropzoneRef = ref(null)
const uploadFiles = ref([])
const loading = ref(false)
const state = reactive({
    modalDelete: false,
})
let dropzoneInstance = null

const uploadFile = computed(() => uploadFiles.value[0] || null)

onMounted(() => {
    dropzoneInstance = new Dropzone(dropzoneRef.value, {
        url: '#',
        autoProcessQueue: false,
        maxFiles: 1,
        previewTemplate: '<div></div>',
        acceptedFiles: props.acceptedFiles.toString(),
    })

    dropzoneInstance.on('addedfile', function (file) {
        if (uploadFile.value) {
            dropzoneInstance.removeFile(uploadFile.value)
        }

        uploadFiles.value.push(file)
    })

    dropzoneInstance.on('addedfiles', async function (files) {
        if (props.onCustomUploadFile) {
            await props.onCustomUploadFile(files[0], {
                setLoading,
                isPdfFile,
                isImage,
            })
        }

        emitModelValue()
    })

    dropzoneInstance.on('removedfile', (file) => {
        uploadFiles.value = uploadFiles.value.filter(
            (uploadFile) => uploadFile.upload.uuid !== file.upload.uuid
        )

        if (!file.accepted) {
            return
        }

        emit('onRemoveFile', file)
        emitModelValue()
    })
})

onBeforeUnmount(() => {
    dropzoneInstance.removeEventListeners()
})

onBeforeUnmount(() => {
    dropzoneInstance.off('addedfile')
    dropzoneInstance.off('addedfiles')
    dropzoneInstance.off('removedfile')
})

const triggerUpload = () => {
    dropzoneInstance.hiddenFileInput.click()
}

watch(
    () => props.modelValue,
    (files) => {
        if (!files) {
            return
        }

        initialModel(files)
    }
)

onMounted(() => {
    const files = props.modelValue
    if (!files) {
        return
    }

    initialModel(files)
})

const initialModel = async (initialFile) => {
    try {
        loading.value = true
        const files = Array.isArray(initialFile) ? initialFile : [initialFile]

        await Promise.all(
            files.map(async (file) => {
                if (file instanceof File) {
                    return
                }

                const path = props.customPath(file)
                let content
                if (sameOrigin(path, window.location)) {
                    content = await coreAxios.get(props.customPath(file), {
                        responseType: 'blob',
                    })
                } else {
                    content = await axios
                        .get(props.customPath(file), {
                            responseType: 'blob',
                        })
                        .then((data) => data.data)
                }

                dropzoneInstance.addFile(
                    new File([content], props.customName(file), {
                        type: content.type,
                    })
                )

                emitModelValue()
            })
        )
    } finally {
        loading.value = false
    }
}

const setLoading = (value) => {
    loading.value = value
}

const emitModelValue = () => {
    const emitFiles = uploadFiles.value.reduce((uploadFiles, uploadFile) => {
        if (!uploadFile.accepted) {
            return uploadFiles
        }

        return [...uploadFiles, uploadFile]
    }, [])

    const emitData = emitFiles[0]
    emit('update:modelValue', emitData)
    emit('onChangeFiles', emitData)
}

const getUrlByFile = (uploadedFile) => {
    if (isEmpty(uploadedFile)) {
        return ''
    }

    return URL.createObjectURL(uploadedFile)
}

const isImage = (uploadFile) => {
    const fileType = uploadFile?.type
    if (!fileType) {
        return false
    }

    return fileType.includes('image/')
}

const isPdfFile = (uploadFile) => {
    const fileType = uploadFile?.type
    if (!fileType) {
        return false
    }

    return fileType === 'application/pdf' || fileType.includes('pdf')
}

const onShowDelete = () => {
    state.modalDelete = true
}

const onDeleteClose = () => {
    state.modalDelete = false
}

const confirmDelete = () => {
    dropzoneInstance.removeFile(uploadFile.value)
    onDeleteClose()
}
</script>
