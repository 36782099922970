<template>
    <div
        class="group cursor-pointer select-none px-4 py-2.5 hover:bg-gray-50"
        :class="disabledClasses"
    >
        <span class="flex items-center gap-3">
            <slot name="icon">
                <base-icon v-if="icon" :name="icon" variant="inherit" />
            </slot>

            <span class="flex-1" :class="contentClass">
                <slot />
            </span>
        </span>
    </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
    icon: {
        type: String,
        default: '',
    },

    isDisabled: {
        type: Boolean,
        default: false,
    },

    contentClass: {
        type: String,
        default: '',
    },
})

const disabledClasses = computed(() => ({
    'text-gray-300 pointer-events-none user-select-none':
        props.isDisabled === true,
}))
</script>
