<template>
    <div class="fixed bottom-16 right-8 z-50" v-if="showChat">
        <base-button
            class="!rounded-full p-3"
            :hasPadding="false"
            @click="onToggleChat"
        >
            <base-icon
                name="line-icons:communication:message-square-02"
                size="lg"
                variant="inherit"
            />
            <div
                v-if="totalUnAnswer > 0"
                class="absolute -top-0.5 right-full -mr-5 min-w-[22px] rounded-full border border-danger-400 bg-danger-50 px-1 py-px text-xs font-medium text-danger-700"
            >
                {{ totalUnAnswer }}
            </div>
        </base-button>

        <div
            class="absolute bottom-[calc(100%+20px)] right-0 h-[81vh] max-h-[1080px] w-[400px] origin-bottom-right rounded-lg border bg-white shadow-xl transition-transform"
            :class="state.showChat ? 'scale-100' : 'scale-0'"
        >
            <partial-chat-window
                v-if="state.showChatWindow && state.room"
                :accountant="accountant"
                :room="state.room"
                @return="onCloseChatWindow"
                @close="onToggleChat"
            />

            <partial-chat-new-conversation
                v-else-if="state.showChatWindow"
                :accountant="accountant"
                @new-conversation="newConversation"
                @return="onCloseChatWindow"
                @close="onToggleChat"
            />

            <partial-chat-feeds
                v-else
                :group-id="groupId"
                @new-chat="onNewChat"
                @load-chat="onLoadChat"
                @close="onToggleChat"
            />
        </div>
    </div>
</template>

<script setup>
import { replaceQuery } from '@tenant/utils/helper'
import sendSound from '@tenant/assets/audio/sendMessage.mp3'
const audioSend = new Audio(sendSound)

const props = defineProps({
    groupId: {
        type: String,
        default: null,
    },
})

const route = useRoute()
const { accountants } = useTenantAccountant()
const { centralUser } = useAuth()
const { totalUnAnswer } = useUnAnswerConversation(props.groupId)
const state = reactive({
    room: null,
    showChatWindow: false,
})

const onToggleChat = () => {
    state.showChat = !state.showChat
    state.showChatWindow = false
    state.room = null
}

const onCloseChatWindow = () => {
    state.showChatWindow = false
    state.room = null
}

const onNewChat = () => {
    state.showChatWindow = true
}

const onLoadChat = (conversation) => {
    showRoom(conversation)
}

const showRoom = (room) => {
    state.showChatWindow = true
    state.room = room
}

onMounted(() => {
    initRoom()
})

watch(
    () => route.query.conversation,
    (value) => {
        if (!value) {
            return
        }

        initRoom()
    }
)

const initRoom = () => {
    const { conversation, ...restQuery } = route.query
    if (!conversation) {
        return
    }

    state.showChat = true
    showRoom(conversation)
    replaceQuery(restQuery)
}

const newConversation = (message) => {
    state.room = message.room
    audioSend.play()
}

const accountant = computed(() => accountants.value[0] || null)

const showChat = computed(() => {
    if (!accountant.value) {
        return false
    }

    return accountant.value?.global_id !== centralUser.value?.global_id
})
</script>
