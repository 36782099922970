<template>
    <form-control-group>
        <div
            class="flex w-full grow items-center gap-x-4"
            v-for="(item, idx) in fields"
            :key="item.key"
            :class="itemClass"
        >
            <slot
                v-bind="{
                    item,
                    idx,
                    isReachMin,
                    isReachMax,
                    onRemoveItem,
                    onAddItem,
                    disabled,
                }"
            />

            <slot
                v-if="!hideDelete"
                name="delete-button"
                v-bind="{ isReachMin, onRemoveItem, item, idx, disabled }"
            >
                <base-button
                    outline
                    size="xl"
                    icon="line-icons:general:trash-01"
                    class="!rounded-full border-dashed !p-3 text-gray-400"
                    variant="gray"
                    :disabled="isReachMin || disabled"
                    @click="onRemoveItem(idx, item.key, item)"
                />
            </slot>
        </div>

        <slot
            v-if="!hideAdd"
            name="add-button"
            v-bind="{ isReachMax, onAddItem, disabled }"
        >
            <div>
                <base-button
                    outline
                    variant="default"
                    icon="line-icons:general:plus"
                    :disabled="isReachMax || disabled"
                    @click="onAddItem"
                >
                    <template v-if="labelAdd">
                        {{ labelAdd }}
                    </template>
                </base-button>
            </div>
        </slot>
    </form-control-group>
</template>

<script setup>
const props = defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
    /**
     * Min length of array data
     */
    itemClass: {
        type: String,
        required: false,
    },
    /**
     * Min length of array data
     */
    min: {
        type: Number,
        required: false,
        default: 0,
    },
    /**
     * Max length of array data
     */
    max: {
        type: [Number, null],
        required: false,
        default: null,
    },
    /**
     * Label of add button
     */
    labelAdd: {
        type: String,
        required: false,
    },
    /**
     * Array data
     */
    fields: {
        type: Array,
        required: true,
        default: () => [],
    },
    hideDelete: {
        type: Boolean,
        default: false,
    },
    hideAdd: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits(['onRemoveItem', 'onAddItem'])

const { isReachMin, isReachMax } = useFormArray({
    fields: toRef(props, 'fields'),
    min: toRef(props, 'min'),
    max: toRef(props, 'max'),
})

const onRemoveItem = (idx, key, item) => {
    emit('onRemoveItem', idx, key, item)
}

const onAddItem = () => {
    emit('onAddItem')
}
</script>
