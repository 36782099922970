<template>
    <data-grid
        :table-borderless="!compact"
        :data-source="entities.data"
        selectable
        :columns="columns"
        :loading="!mounted && suppressRefreshLoading ? false : loading"
        identify-field="id"
        @row-click="onRowClick"
        v-bind="$attrs"
        :empty-text="$t('documentExtraction.emptyText')"
        :empty-sub="$t('documentExtraction.emptySubText')"
        :pre-selected-items="selectedItems"
    >
        <template #header-select-all>
            <form-checkbox v-model="isSelectedAll" />
        </template>

        <template #column-checkbox="{ item }">
            <form-checkbox v-model="selectedItems" :value="item" />
        </template>

        <template v-if="!compact" #filter>
            <div class="flex w-full items-center justify-between gap-2">
                <div class="grow">
                    <slot name="grow" />
                </div>
                <div class="flex grow gap-3">
                    <form-text-input
                        size="sm"
                        icon-name="line-icons:general:search-lg"
                        :placeholder="
                            $t('documentExtraction.searchByDocNumber')
                        "
                        :model-value="filterValues.search"
                        @change="onChangeSearch($event.target.value)"
                    >
                        {{ $t('general.search') }}
                    </form-text-input>
                    <document-extraction-filter-slide-out />
                </div>
            </div>
        </template>

        <template #column-documents="{ item }">
            <div class="flex gap-4">
                <base-icon
                    v-if="$acl.can('update_document_extractions')"
                    :variant="item.flag ? 'primary' : 'inherit'"
                    name="line-icons:maps-&-travel:flag-03"
                    @click.stop="toggleFlag(item)"
                />
                <base-icon
                    v-if="item.documents && item.documents.length > 0"
                    variant="inherit"
                    name="line-icons:images:image-01"
                    @click.stop="showPreviews(item.documents)"
                />
                <span v-else />
            </div>
        </template>

        <template #column-status="{ item }">
            <document-extraction-item-status :item="item" />
        </template>

        <template #column-user="{ item }">
            <div class="text-sm font-normal text-gray-500">
                {{ item.created_by.full_name }}
            </div>
        </template>

        <template #column-date="{ item }">
            <div class="text-sm font-normal text-gray-500">
                {{ $filters.dateHumanFormat(item.date_of_document) }}
            </div>
        </template>

        <template #column-supplier="{ item }">
            {{ getContactName(item.contact) }}
        </template>

        <template #column-amount_gross_total="{ item }">
            {{ $filters.currency(item.amount_gross_total, item.currency.iso) }}
        </template>

        <template #column-tax="{ item }">
            {{
                $filters.currency(
                    Math.abs(item.amount_net_total - item.amount_gross_total),
                    item.currency.iso
                )
            }}
        </template>

        <template #footer>
            <grid-pagination
                :total="entities.last_page"
                :page="entities.current_page"
            />
        </template>
        <template #empty v-if="$slots['empty']">
            <slot name="empty" />
        </template>
    </data-grid>

    <document-extraction-preview-file
        :preview-files="state.previewFiles"
        @modal-close="removePreviews"
    />
</template>

<script>
export default {
    inheritAttrs: false,
}
</script>

<script setup>
import map from 'lodash/map'
import { useFilters } from '@tenant/composables'
import { useGetDocumentExtractions } from '@tenant/modules/tenant/document-extraction/composables/use-document-extraction'
import { getContactName } from '@tenant/utils/helper'

const props = defineProps({
    statuses: {
        type: Array,
        default: () => [],
    },
    editRoute: {
        type: String,
        default: null,
    },
    isBill: {
        type: Boolean,
        default: false,
    },
    compact: {
        type: Boolean,
        default: false,
    },
    queries: {
        type: Object,
        default: () => ({}),
    },
    suppressRefreshLoading: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits(['bills-total'])

const router = useRouter()
const { t } = useI18n()

const state = reactive({
    previewFiles: null,
})

const { entities, loading, executeRefreshSearch } = useGetDocumentExtractions({
    statuses: props.statuses,
    ...props.queries,
})

watch(
    () => entities?.value?.total,
    (value) => {
        emit('bills-total', value)
    }
)

const { errorNotify } = useNotification()
const { execute: executeFlag } = useApi(
    '/api/document-extraction/flag/{id}',
    'PATCH'
)

const columns = [
    {
        property: 'documents',
        label: ' ',
    },
    {
        property: 'status',
        label: t('documentExtraction.list.columns.status'),
    },
    {
        property: 'user',
        label: t('documentExtraction.list.columns.user'),
    },
    {
        property: 'date',
        label: t('documentExtraction.list.columns.date'),
    },
    {
        property: 'supplier',
        label: t('documentExtraction.list.columns.supplier'),
    },
    {
        property: 'amount_gross_total',
        label: t('documentExtraction.list.columns.total'),
    },
    {
        property: 'tax',
        label: t('documentExtraction.list.columns.tax'),
    },
]

const toggleFlag = (item) => {
    executeFlag({
        params: {
            id: item.id,
        },
    })
        .then(() => {
            item.flag = !item.flag
        })
        .catch(() => {
            errorNotify({
                title: t('messages.internalServerError'),
            })
        })
}

const showPreviews = (documents) => {
    state.previewFiles = map(documents, 'preview')
}

const removePreviews = () => {
    state.previewFiles = null
}

const onRowClick = (id) => {
    return router.push({
        name: props.editRoute || 'document-extraction.inbox.edit',
        params: { id },
    })
}

const mounted = ref(true)
const refreshSearch = () => {
    mounted.value = false
    executeRefreshSearch()
}
defineExpose({ executeRefreshSearch, refreshSearch })

// SEARCH FILTER
const queryKeys = {
    search: 'q',
}
const { filterValues, updateFilter } = useFilters(queryKeys)

const onChangeSearch = (value) => updateFilter(queryKeys.search, value)
// END: SEARCH FILTER

const selectedItems = ref([])

const isSelectedAll = ref(false)
watch(
    () => isSelectedAll.value,
    (value) => {
        if (value) {
            selectedItems.value = entities.value.data
        } else {
            selectedItems.value = []
        }
    }
)
</script>
