<template>
    <div class="flex w-full items-end gap-3">
        <form-date-picker
            :label="label || $t('filters.startDate')"
            single
            v-model="fromValue"
        />
        <span class="block py-2">
            <span class="block py-px">~</span>
        </span>

        <form-date-picker
            :label="label ? null : $t('filters.endDate')"
            single
            v-model="toValue"
        />
    </div>
</template>
<script setup>
const props = defineProps({
    label: {
        type: String,
        default: null,
    },
    queryKey: {
        type: String,
        required: true,
    },
    queryValue: {
        type: String,
        required: true,
    },
})

const queries = {
    [props.queryKey]: props.queryValue,
}

const { useRangeFilter } = useFilters(queries)
const { fromValue, toValue } = useRangeFilter(
    props.queryKey,
    props.queryValue,
    true
)
</script>
