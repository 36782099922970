<template>
    <BarChart
        ref="bar"
        :data="chartData"
        :options="chartOptions"
        :height="height"
        :plugins="pluginOptions"
    />
</template>

<script setup>
import { Bar as BarChart } from 'vue-chartjs'
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import { isArray } from 'lodash-es'

const props = defineProps({
    height: {
        type: [Number, String],
        default: 100,
    },
    grace: {
        type: Number,
        default: 5,
    },
    chartData: {
        type: Object,
        required: true,
    },
    chartOptions: {
        type: Object,
        default: () => ({}),
    },
    customDataSet: {
        type: Function,
        default: null,
    },
    plugins: {
        type: Array,
        default: () => [],
    },
})

const bar = ref(null)

const pluginOptions = computed(() => [
    ...props.plugins,
    {
        id: 'customScale',
        beforeLayout: (chart) => {
            let max = 0
            let min = 0
            const grace = props.grace / 100

            chart.data.datasets.forEach((dataset) => {
                max = Math.max(
                    max,
                    Math.max(
                        ...(props.customDataSet
                            ? props.customDataSet(dataset.data)
                            : dataset.data)
                    )
                )
                min = Math.min(
                    min,
                    Math.min(
                        ...(props.customDataSet
                            ? props.customDataSet(dataset.data)
                            : dataset.data)
                    )
                )
            })

            chart.options.scales.y.suggestedMax =
                max + (Math.abs(max) + Math.abs(min)) * grace
            chart.options.scales.y.suggestedMin = min
        },
    },
    {
        id: 'barBorderDashed',
        afterDatasetDraw: (chart, args) => {
            args.meta.data.forEach((element, index) => {
                const vm = element
                const borderDashedOption = args.meta._dataset.borderDashed
                const borderDashedEnabled = isArray(borderDashedOption)
                    ? args.meta._dataset.borderDashed[index]
                    : borderDashedOption
                if (!borderDashedEnabled) return

                const borderWidth = vm.options.borderWidth
                const ctx = chart.ctx
                const half = vm.width / 2
                const left = vm.x - half
                const right = vm.x + half
                const top = vm.y
                const bottom = vm.base
                const height = element.height
                ctx.beginPath()
                ctx.lineWidth = borderWidth
                ctx.strokeStyle = vm.options.borderColor
                ctx.setLineDash([5, 5])

                const point = args.meta._parsed[index]
                const offset = point.y > 0 ? top + 0.7 : bottom - 0.7

                ctx.moveTo(left, offset) // Start from the top-right corner
                ctx.lineTo(right, offset) // Draw the top side

                ctx.lineTo(right, offset + height) // Right side
                ctx.lineTo(left, offset + height) // Bottom side
                ctx.lineTo(left, offset) // Left side
                ctx.closePath()

                ctx.stroke()
                ctx.save()
            })
        },
    },
])

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement
)

defineExpose({ bar })
</script>
