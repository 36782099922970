<template>
    <label
        class="inline-flex cursor-pointer"
        :class="[rootClass, containerClass]"
    >
        <span class="relative mr-3" :class="toggleWrapperClasses">
            <input
                v-bind="restAttrs"
                v-model="value"
                type="checkbox"
                class="peer h-0 w-0 opacity-0"
                :disabled="disabled"
            />
            <span
                class="absolute bottom-[2px] left-[2.5px] z-10 block rounded-[50%] bg-white shadow-sm transition duration-300"
                :class="circleClasses"
            ></span>
            <span
                class="absolute bottom-0 left-0 right-0 top-0 cursor-pointer rounded-[12px] transition duration-300 peer-focus:ring-4 peer-focus:ring-offset-0"
                :class="backgroundClasses"
            ></span>
        </span>

        <span class="flex flex-col">
            <span
                v-if="label"
                class="font-medium text-gray-700"
                :class="textClasses"
            >
                {{ label }}
            </span>

            <span
                v-if="description"
                class="text-sm font-normal text-gray-500"
                v-html="description"
            />

            <slot name="hint">
                <form-control-hint-text
                    :hint-text="supportingText && label"
                    :class="textClasses"
                    :error-message="errorMessage"
                />
            </slot>
        </span>
    </label>
</template>

<script>
// A normal <script> is needed to declare the inheritAttrs option
export default {
    inheritAttrs: false,
}
</script>

<script setup>
const props = defineProps({
    label: {
        type: String,
        default: '',
    },
    description: {
        type: String,
        required: false,
    },
    supportingText: {
        type: String,
        default: '',
    },
    /**
     * Error message in red shown below the input field when it's destructive
     */
    errorMessage: {
        type: String,
        default: '',
    },
    variant: {
        type: String,
        default: 'primary',
        required: false,
        validator(value) {
            return ['primary', 'danger'].includes(value)
        },
    },
    size: {
        type: String,
        default: 'md',
    },
    modelValue: {
        type: [Boolean, Array],
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
})

// Access the component's fallthrough attributes
const attrs = useAttrs()

const { class: containerClass, ...restAttrs } = attrs

// Input handler
const emit = defineEmits(['update:modelValue'])
const value = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
    },
})

const rootClass = computed(() => {
    return {
        'cursor-default': props.disabled,
    }
})

const toggleWrapperClasses = computed(() => {
    const { size } = props

    return {
        'w-9 h-5': size === 'sm',
        'w-11 h-6': size === 'md',
    }
})

const circleClasses = computed(() => {
    const { size } = props

    return {
        'w-4 h-4 peer-checked:translate-x-[15px]': size === 'sm',
        'w-5 h-5 peer-checked:translate-x-[19px]': size === 'md',
    }
})

const textClasses = computed(() => {
    const { size } = props

    return {
        'text-sm': size === 'sm',
        'text-base': size === 'md',
    }
})

const backgroundClasses = computed(() => {
    const { variant, disabled, errorMessage } = props

    if (disabled) {
        return 'bg-gray-100 cursor-default'
    }

    if (errorMessage) {
        return 'bg-danger-300 hover:bg-danger-400 peer-checked:bg-danger-600 peer-checked:hover:bg-danger-700 peer-focus:ring-danger-100'
    }

    if (variant === 'primary') {
        return 'bg-primary-50 hover:bg-gray-200 peer-checked:bg-primary-600 peer-checked:hover:bg-primary-700 peer-focus:ring-primary-100'
    }

    if (variant === 'danger') {
        return 'bg-danger-50 hover:bg-danger-200 peer-checked:bg-danger-600 peer-checked:hover:bg-danger-700 peer-focus:ring-danger-100'
    }

    return ''
})
</script>
