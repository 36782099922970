<template>
    <div class="rounded-full" :class="classes">
        <base-icon :name="name" :size="size" :variant="iconVariant" />
    </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
    variant: {
        type: String,
        default: 'primary',
        required: false,
        validator(value) {
            return [
                'primary',
                'success',
                'warning',
                'danger',
                'gray',
                'white',
            ].includes(value)
        },
    },
    size: {
        type: String,
        default: 'md',
        required: false,
        validator(value) {
            return ['sm', 'md', 'lg', 'xs'].includes(value)
        },
    },
    name: {
        type: String,
        required: true,
    },
    type: {
        type: String,
        default: 'default',
        required: false,
        validator(value) {
            return ['default', 'highlight', 'dark'].includes(value)
        },
    },
})

const classes = computed(() => {
    const { variant, type, size } = props

    const borderWidth = size === 'sm' ? 'border-4' : 'border-6'
    const paddingClass = type === 'default' ? 'p-2' : 'p-2.5'
    const typeClass = `${borderWidth} ${paddingClass}`

    const colorData = {
        highlight: {
            primary: `${typeClass} border-primary-50 bg-primary-100`,
            success: `${typeClass} border-success-50 bg-success-100`,
            warning: `${typeClass} border-warning-50 bg-warning-100`,
            danger: `${typeClass} border-danger-50 bg-danger-100`,
            gray: `${typeClass} border-gray-50 bg-gray-100`,
        },
        default: {
            primary: `${typeClass} border-primary-100 bg-primary-50`,
            success: `${typeClass} border-success-100 bg-success-50`,
            warning: `${typeClass} border-warning-100 bg-warning-50`,
            danger: `${typeClass} border-danger-100 bg-danger-50`,
            gray: `${typeClass} border-gray-100 bg-gray-50`,
        },
        dark: {
            primary: `${typeClass} border-primary-100 bg-primary-50`,
            success: `${typeClass} border-success-100 bg-success-50`,
            warning: `${typeClass} border-warning-100 bg-warning-50`,
            danger: `${typeClass} border-danger-600 bg-danger-500`,
            gray: `${typeClass} border-gray-100 bg-gray-50`,
        },
    }

    return colorData[type][variant]
})

const iconVariant = computed(() => {
    const { type, variant } = props
    return type === 'dark' ? 'white' : variant
})
</script>
