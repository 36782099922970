<template>
    <div
        v-if="searchEntity"
        ref="dropdown"
        class="absolute left-0 top-full z-20 mt-2 w-80 rounded-lg border border-gray-100 bg-white p-4 text-sm text-gray-700 shadow-md"
    >
        <base-loading
            v-if="loading"
            class="justify-center bg-white py-2"
            size="sm"
        />

        <template v-else>
            <div class="flex w-full flex-col gap-3">
                <partial-search-bar-more-character
                    v-if="searchTerm.length < 3"
                />
                <partial-search-bar-empty-state v-else-if="!results.length" />
                <component
                    v-else
                    :is="getComponent(searchEntity)"
                    v-for="item in results"
                    :key="item.id"
                    :item="item"
                    @click="onClickItem(item)"
                />
            </div>
        </template>
    </div>
</template>

<script setup>
import SearchCustomersItem from '@tenant/components/partial/search-bar/items/search-customers-item.vue'
import SearchChartOfAccountsItem from '@tenant/components/partial/search-bar/items/search-chart-of-accounts-item.vue'
import SearchDocumentsItem from '@tenant/components/partial/search-bar/items/search-documents-item.vue'
import SearchExpensesItem from '@tenant/components/partial/search-bar/items/search-expenses-item.vue'
import SearchInvoicesItem from '@tenant/components/partial/search-bar/items/search-invoices-item.vue'

const dropdown = ref(null)
const emit = defineEmits(['hide-search-result'])

const props = defineProps({
    results: {
        type: Array,
        default: () => [],
    },

    loading: {
        type: Boolean,
        default: false,
    },

    searchTerm: {
        type: String,
        default: '',
    },

    searchEntity: {
        type: String,
        required: true,
    },
})

const itemComponents = {
    'search-customers-item': SearchCustomersItem,
    'search-chart-of-accounts-item': SearchChartOfAccountsItem,
    'search-documents-item': SearchDocumentsItem,
    'search-expenses-item': SearchExpensesItem,
    'search-invoices-item': SearchInvoicesItem,
}

const getComponent = (component) => {
    return itemComponents[`search-${component}-item`]
}

const close = (e) => {
    if (e.target.id === 'searchEntities') {
        return
    }

    if (!dropdown.value.contains(e.target)) {
        emit('hide-search-result')
    }
}

onMounted(() => {
    document.addEventListener('click', close)
})

onBeforeUnmount(() => {
    document.removeEventListener('click', close)
})

const router = useRouter()
const onClickItem = (item) => {
    switch (props.searchEntity) {
        case 'customers':
            router.push({ name: 'contacts.detail', params: { id: item.id } })
            break
        case 'invoices':
            router.push({
                name: 'invoices.dispatch',
                params: {
                    id: item.id,
                },
            })
            break
        case 'banking':
            router.push({
                name: 'tenant.banking.show',
                params: {
                    id: item.id,
                },
            })
            break
        case 'expenses':
            router.push({
                name: 'purchases.expense.detail',
                params: {
                    id: item.id,
                },
            })
            break
        case 'documents':
            router.push({
                name: 'documents.overview',
                params: {
                    id: item.uuid,
                },
            })
            break
        case 'chart-of-accounts':
            router.push({
                name: 'bookeeping.accounts.detail',
                params: {
                    id: item.id,
                },
            })
            break
    }

    emit('hide-search-result')
}
</script>
