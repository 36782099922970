<template>
    <form-single-select
        :options="options"
        :input-text-style="inputTextStyle"
        v-bind="$attrs"
        @on-open="onOpen"
        @on-input="onStartInput"
        @on-close="onClose"
    >
        <template #before-input="bind">
            <slot name="before-input" v-bind="bind" />
        </template>

        <template
            #option-list="{
                isOpenSelection,
                results,
                currentOptions,
                optionValue,
                optionLabel,
                onClickOption,
                inputValue,
            }"
        >
            <form-control-option-list
                v-if="isOpenSelection"
                has-infinity-scroll
                :results="results"
                :options="currentOptions"
                :option-value="optionValue"
                :option-label="optionLabel"
                :is-loading-more="loading"
                @select-option="onClickOption"
                @on-load-more="onLoadMore"
                :bordered-option="borderedOption"
                :show-empty="showEmpty"
            >
                <template #option="{ item, index, selected }">
                    <slot
                        name="option"
                        v-bind="{ item, index, selected, inputValue }"
                    />
                </template>

                <template #option-label="{ item, index, selected }">
                    <slot
                        name="option-label"
                        v-bind="{ item, index, selected, inputValue }"
                    />
                </template>

                <template #create>
                    <slot name="create" />
                </template>
            </form-control-option-list>
        </template>

        <template #after-options>
            <slot name="after-options" />
        </template>

        <template #badge>
            <slot name="badge" />
        </template>
    </form-single-select>
</template>

<script setup>
import qs from 'qs'
import { useDebounceFn } from '@vueuse/core'

const emit = defineEmits(['on-input'])

const props = defineProps({
    endpoint: {
        type: String,
        required: true,
    },
    method: {
        type: String,
        default: 'GET',
    },
    queries: {
        type: Object,
        default: () => ({}),
    },
    /**
     * The returned string value is what is internally used within a component to validate the following:
     *
     * - Which options are matched to a search term (searchableFields)
     * - Which options are already selected
     */
    itemValue: {
        type: String,
        default: 'id',
    },
    /**
     * The returned string value is the string that's rendered:
     *
     * - As the label for options in the menu
     * - As the label for value(s) in the control.
     */
    itemLabel: {
        type: [String, Function],
        default: 'name',
    },

    /**
     * Add border bottom to each option
     */
    borderedOption: {
        type: Boolean,
        default: false,
    },

    /**
     * Show empty state of an option list or not
     */
    showEmpty: {
        type: Boolean,
        default: true,
    },

    inputTextStyle: {
        type: String,
        default: 'normal',
        validator(value) {
            return [
                'italic',
                'normal',
                'oblique',
                'initial',
                'inherit',
                'unset',
            ].includes(value)
        },
    },
})

const state = reactive({
    items: [],
    open: false,
    page: 1,
    totalPage: 1,
})

const options = computed(() =>
    state.items.map((item) => ({
        ...item,
        value: item[props.itemValue],
        label:
            typeof props.itemLabel === 'string'
                ? item[props.itemLabel]
                : props.itemLabel(item),
    }))
)

const { loading, execute, replaceEndpoint } = useApi(
    props.endpoint,
    props.method
)

const onOpen = () => {
    state.open = true

    // Load data on open
    replaceEndpoint(
        searchingEndpoint({
            ...props.queries,
            page: 1,
            limit: 20,
        })
    )
    execute().then(({ data, last_page }) => {
        state.totalPage = last_page
        state.items = data
    })
}

const loadData = () => {
    execute({
        queries: {
            ...props.queries,
            page: state.page,
            limit: 20,
        },
    }).then(({ data, last_page }) => {
        state.totalPage = last_page
        state.items = [...state.items, ...data]
    })
}

const onLoadMore = () => {
    if (state.page >= state.totalPage) {
        return
    }

    state.page++
    loadData()
}

const searchingEndpoint = (queryParams) => {
    queryParams = qs.stringify(unref(queryParams))
    return `${props.endpoint}?${queryParams}`
}

// Load data on queries change
watch(
    () => props.queries,
    () => {
        if (!state.open) {
            return
        }

        replaceEndpoint(
            searchingEndpoint({
                ...props.queries,
                page: state.page,
                limit: 20,
            })
        )
        execute().then(({ data, last_page }) => {
            state.totalPage = last_page
            state.items = data
        })
    },
    { deep: true, immediate: true }
)

const onStartInput = (inputValue = '') => {
    emit('on-input')
    onInput(inputValue)
}

// Get input value and execute search
const onInput = useDebounceFn((inputValue = '') => {
    const params = {
        ...props.queries,
        page: 1,
        limit: 20,
    }

    if (inputValue) {
        params['q'] = inputValue
    }

    replaceEndpoint(searchingEndpoint(params))
    execute().then(({ data, last_page }) => {
        state.totalPage = last_page
        state.items = data
    })
}, 500)

const onClose = () => {
    state.open = false
    state.page = 1
}
</script>
