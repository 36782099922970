export const corsair = {
    id: 'corsair',
    defaults: {
        width: 1,
        horizontal: true,
        vertical: true,
        color: '#000000',
        dash: [4, 5],
    },
    afterInit: (chart) => {
        chart.corsair = {
            x: 0,
            y: 0,
        }
    },
    afterEvent: (chart, args) => {
        const { inChartArea } = args
        const { x, y } = args.event

        chart.corsair = { x, y, draw: inChartArea }
        chart.draw()
    },
    beforeDatasetsDraw: (chart, args, opts) => {
        const { ctx, tooltip } = chart
        const { top, bottom, left, right } = chart.chartArea
        const { draw } = chart.corsair
        if (!draw) return

        ctx.save()

        ctx.beginPath()
        ctx.lineWidth = opts.width
        ctx.strokeStyle = opts.color
        ctx.setLineDash(opts.dash)

        const element = tooltip.dataPoints?.[0]?.element
        if (opts.horizontal && element) {
            ctx.moveTo(element.x, bottom)
            ctx.lineTo(element.x, top)
        }

        if (opts.vertical && element) {
            ctx.moveTo(left, element.y)
            ctx.lineTo(right, element.y)
        }

        ctx.stroke()
        ctx.restore()
    },
}
