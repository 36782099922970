<template>
    <Teleport to="body">
        <transition
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-if="show"
                class="fixed left-0 top-0 z-50 h-screen w-screen bg-gray-700 bg-opacity-30 duration-200"
            >
                <div
                    class="absolute bottom-0 left-0 right-0 top-0"
                    @click.prevent="closeSlideOutMenu"
                />
                <div
                    class="fixed right-0 top-0 z-50 flex max-h-[100%] min-h-full flex-col overflow-auto border-l border-gray-200 bg-white shadow-lg"
                    :class="containerClasses"
                    v-bind="$attrs"
                >
                    <div class="relative" :class="{ 'p-3 sm:p-6': hasPadding }">
                        <!-- Slide out menu header -->
                        <header
                            class="flex flex-row-reverse flex-wrap items-start justify-between pb-6"
                            :class="headerClasses"
                        >
                            <slot name="close-icon">
                                <base-icon
                                    size="lg"
                                    variant="gray"
                                    class="cursor-pointer"
                                    :name="icon || 'line-icons:general:x'"
                                    @click.prevent="closeSlideOutMenu"
                                />
                            </slot>

                            <div class="flex flex-col gap-1">
                                <slot name="slide-out-menu-header">
                                    <div class="text-xl text-gray-900">
                                        {{ title }}
                                    </div>
                                    <div class="text-sm text-gray-500">
                                        {{ subtitle }}
                                    </div>
                                </slot>
                            </div>
                        </header>

                        <!-- Slide out menu body -->
                        <div>
                            <slot />
                        </div>

                        <!-- Slide out menu footer -->
                        <footer>
                            <slot name="slide-out-menu-footer" />
                        </footer>

                        <div
                            v-if="loading"
                            class="absolute bottom-0 left-0 right-0 top-0 z-50 bg-white opacity-50"
                        >
                            <span
                                class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
                            >
                                <base-loading size="lg" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </Teleport>
</template>

<script>
export default {
    inheritAttrs: false,
}
</script>

<script setup>
import { useForm } from 'vee-validate'

const emit = defineEmits(['close'])

const props = defineProps({
    /**
     * Show slide out menu
     */
    show: {
        type: [Boolean, Number, String],
        default: false,
    },
    /**
     * Show loading
     */
    loading: {
        type: Boolean,
        default: false,
    },
    /**
     * Slide out menu title
     */
    title: {
        type: String,
    },
    /**
     * Slide out menu subtitle
     */
    subtitle: {
        type: String,
    },
    /**
     * Slide out icon
     */
    icon: {
        type: String,
    },
    /**
     * Size
     */
    size: {
        type: String,
        default: 'md',
        validator(value) {
            return ['xs', 'sm', 'md', 'auto'].includes(value)
        },
    },
    hasPadding: {
        type: Boolean,
        default: true,
    },
    hasSeparator: {
        type: Boolean,
        default: true,
    },
    headerClass: {
        type: [Array, String, Object],
        default: '',
    },
})

const { setValues } = useForm()

const closeSlideOutMenu = () => emit('close')

const closeModalOnEscapeKey = (e) => {
    if (e.keyCode === 27 && props.show) {
        closeSlideOutMenu()
    }
}

onMounted(() => {
    document.addEventListener('keyup', closeModalOnEscapeKey)

    setValues({
        includes: [
            {
                description: null,
                contains: null,
                bank: null,
            },
        ],
    })
})

onBeforeUnmount(() => {
    document.removeEventListener('keyup', closeModalOnEscapeKey)
})

const headerClasses = computed(() => {
    const { headerClass, hasSeparator } = props

    return [
        {
            'border-b border-gray-200': hasSeparator,
        },
        headerClass,
    ]
})

const containerClasses = computed(() => {
    const { size } = props

    return {
        'w-full max-w-[50%]': size === 'md',
        'w-full max-w-[32rem]': size === 'sm',
        'w-full max-w-[25rem]': size === 'xs',
    }
})
</script>
