<template>
    <div class="flex flex-col">
        <form-control-label :label="label" />

        <div class="flex justify-between gap-x-2">
            <template v-for="(value, index) in values" :key="index">
                <input
                    required
                    maxlength="1"
                    type="number"
                    pattern="[0-9]"
                    placeholder="0"
                    class="flex appearance-none items-center justify-items-center rounded-lg border-gray-300 text-center font-medium text-primary-600 outline-none ring-1 ring-gray-300 placeholder:text-gray-300 focus:border-primary-200 focus:ring-4 focus:ring-primary-100"
                    :autoFocus="index === autoFocusIndex"
                    :data-id="index"
                    :value="value"
                    :ref="
                        (el) => {
                            if (el) inputs[index + 1] = el
                        }
                    "
                    :class="sizeClasses"
                    :disabled="props.disabled"
                    @input="onValueChange"
                    @keydown="onKeyDown"
                />
            </template>
        </div>

        <form-control-hint-text
            :hint-text="hintText"
            :error-message="errorMessage"
        />
    </div>
</template>

<script setup>
import {
    ref,
    toRef,
    defineProps,
    defineEmits,
    onBeforeUpdate,
    onMounted,
    computed,
} from 'vue'

const KEY_CODE = {
    BACKSPACE: 8,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
}

const emit = defineEmits(['change', 'complete', 'update:modelValue'])

const props = defineProps({
    digits: {
        type: Number,
        default: 4,
        validator(value) {
            return [4, 6].includes(value)
        },
    },

    label: {
        type: String,
        default: '',
    },

    hintText: {
        type: String,
        default: '',
    },

    errorMessage: {
        type: String,
        default: '',
    },

    size: {
        type: String,
        default: 'md',
        validator(value) {
            return ['sm', 'md', 'lg'].includes(value)
        },
    },
})

const values = ref([])
const iRefs = ref([])
const inputs = ref([])
const internalDigits = toRef(props, 'digits')
const autoFocusIndex = ref(0)

onMounted(() => {
    let results

    if (values.value && values.value.length) {
        results = []

        for (let i = 0; i < internalDigits.value; i++) {
            results.push(values.value[i] || '')
        }

        autoFocusIndex.value =
            values.value.length >= internalDigits.value
                ? 0
                : values.value.length
    } else {
        results = Array(internalDigits.value).fill('')
    }

    iRefs.value = []
    for (let i = 0; i < internalDigits.value; i++) {
        iRefs.value.push(i + 1)
    }

    values.value = results
})

onBeforeUpdate(() => {
    inputs.value = []
})

const onValueChange = (e) => {
    const index = parseInt(e.target.dataset.id)

    // Remove non-number characters
    e.target.value = e.target.value.replace(/[^\d]/gi, '')

    // Check empty and valid number
    if (e.target.value === '' || !e.target.validity.valid) {
        return
    }

    let next
    const inputValue = e.target.value
    values.value = Object.assign([], values.value)

    // If number has more than 1 digit is entered
    if (inputValue.length > 1) {
        let nextIndex = inputValue.length + index - 1
        if (nextIndex >= internalDigits.value) {
            nextIndex = internalDigits.value - 1
        }

        next = iRefs.value[nextIndex]
        const split = inputValue.split('')

        split.forEach((item, i) => {
            const cursor = index + i
            if (cursor < internalDigits.value) {
                values.value[cursor] = item
            }
        })

        // If only one digit is entered
    } else {
        next = iRefs.value[index + 1]
        values.value[index] = inputValue
    }

    if (next) {
        const element = inputs.value[next]
        element.focus()
        element.select()
    }

    triggerChange(values.value)
}

const onKeyDown = (e) => {
    const index = parseInt(e.target.dataset.id)
    const prevIndex = index - 1
    const nextIndex = index + 1

    const prev = iRefs.value[prevIndex]
    const next = iRefs.value[nextIndex]

    switch (e.keyCode) {
        case KEY_CODE.BACKSPACE: {
            e.preventDefault()
            const vals = [...values.value]

            // Current input has value, remove it's value
            if (values.value[index]) {
                vals[index] = ''
                values.value = vals
                triggerChange(vals)

                // Current input has no value, remove previous input's value
            } else if (prev) {
                vals[prevIndex] = ''
                inputs.value[prev].focus()
                values.value = vals
                triggerChange(vals)
            }

            break
        }

        // Focus cursor to previous input, if exists
        case KEY_CODE.LEFT:
            e.preventDefault()
            if (prev) {
                inputs.value[prev].focus()
            }
            break

        // Focus cursor to next input, if it exists
        case KEY_CODE.RIGHT:
            e.preventDefault()
            if (next) {
                inputs.value[next].focus()
            }
            break

        // Don't allow to increase/decrease value with arrow keys
        case KEY_CODE.UP:
        case KEY_CODE.DOWN:
            e.preventDefault()
            break

        default:
            break
    }
}

const triggerChange = (values = values.value) => {
    const fullString = values.join('')
    emit('change', fullString)

    if (fullString.length >= internalDigits.value) {
        emit('complete', fullString)
        emit('update:modelValue', fullString)
    } else {
        emit('update:modelValue', null)
    }
}

const sizeClasses = computed(() => {
    const { size } = props

    const classes = {
        sm: 'w-16 h-16 text-5xl',
        md: 'w-20 h-20 text-5xl',
        lg: 'w-24 h-24 text-6xl',
    }

    return classes[size]
})
</script>

<style scoped>
input:not(:placeholder-shown) {
    border-color: var(--primary-300);
    box-shadow: none;
}
</style>
