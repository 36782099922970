<template>
    <div
        v-if="variant === 'small'"
        class="flex flex-row items-center justify-center rounded-full px-2 py-0.5 text-xs"
        :class="[backgroundColor, textColor]"
    >
        <slot name="text">
            {{ text }}
        </slot>
    </div>

    <div v-else class="flex flex-row items-center gap-3">
        <div
            class="flex h-10 w-10 flex-row items-center justify-center rounded-full"
            :class="backgroundColor"
        >
            <base-icon :name="iconName" :variant="iconVariant" />
        </div>
        <div class="text-sm font-medium capitalize text-gray-900">
            {{ status }}
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import {
    SM_STATUS_BACKGROUND_COLORS,
    SM_STATUS_COLORS,
    STATUS_COLORS,
    STATUS_ICONS,
    STATUS_VARIANTS,
} from '@tenant/utils/constants'

const props = defineProps({
    status: {
        type: String,
        required: true,
        validator(value) {
            return [
                'draft',
                'unpaid',
                'part-paid',
                'paid',
                'due',
                'sent',
                'ready',
                'calculated',
                'invoiced',
                'accepted',
                'declined',
                'expired',
                'published',
                'open',
                'closed',
                'repeating',
            ].includes(value)
        },
    },
    variant: {
        type: String,
        default: 'default',
        validator(value) {
            return ['default', 'small'].includes(value)
        },
    },
})

const backgroundColor = computed(() =>
    props.variant === 'default'
        ? STATUS_COLORS[props.status]
        : SM_STATUS_BACKGROUND_COLORS[props.status]
)
const { t } = useI18n()
const textColor = computed(() => SM_STATUS_COLORS[props.status])
const iconName = computed(() => STATUS_ICONS[props.status])
const iconVariant = computed(() => STATUS_VARIANTS[props.status])
const text = computed(() => t(`general.partialStatus.${props.status}`))
</script>
