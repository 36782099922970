<template>
    <canvas id="myChart" class="max-w-full"></canvas>
</template>
<script setup>
import { Chart, LinearScale, Tooltip } from 'chart.js'
Chart.defaults.font.family = 'Inter'

import { TreemapController, TreemapElement } from 'chartjs-chart-treemap'
Chart.register(Tooltip, TreemapController, TreemapElement, LinearScale)

const props = defineProps({
    chartData: {
        type: Object,
        default: () => ({}),
    },
    chartOptions: {
        type: Object,
        default: () => ({}),
    },
    plugins: {
        type: Array,
        default: () => [],
    },
})

const config = computed(() => {
    return {
        type: 'treemap',
        data: {
            ...props.chartData,
        },
        options: {
            ...props.chartOptions,
        },
    }
})

onMounted(() => {
    const ctx = document.getElementById('myChart')
    new Chart(ctx, config.value)
})
</script>
