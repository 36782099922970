<template>
    <div>
        <div ref="dropzoneRef" class="hidden" />
        <slot
            v-if="$slots['default']"
            v-bind="{
                files: uploadFiles,
                getUploadFile,
                getUploadFileByIdx,
                onRemoveFile,
                triggerDropdown,
                getUrlByFile,
                getTypeByFile,
                hasFiles,
                loading,
            }"
        >
            <div
                class="relative mb-4 flex w-full cursor-pointer flex-col items-center justify-center rounded-lg border border-gray-200 px-6 py-4"
                :class="containerClass"
                @click="triggerDropdown"
            >
                <div
                    v-if="loading"
                    class="absolute bottom-0 left-0 right-0 top-0 z-50 bg-white opacity-50"
                >
                    <span
                        class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
                    >
                        <base-loading size="lg" />
                    </span>
                </div>

                <span
                    class="mb-4 flex items-center justify-center rounded-full bg-gray-100 p-2 text-gray-600 ring-8 ring-gray-50"
                >
                    <base-icon
                        v-if="canUpload"
                        name="line-icons:general:upload-cloud-02"
                        size="lg"
                        variant="inherit"
                    />

                    <base-icon
                        v-else
                        name="line-icons:files:folder-lock"
                        size="lg"
                        variant="inherit"
                    />
                </span>

                <i18n-t
                    v-if="canUpload"
                    keypath="fileUpload.uploadLabel"
                    tag="span"
                    class="mb-1 text-sm text-gray-500"
                >
                    <span class="text-link font-medium">
                        {{ $t('fileUpload.clickToUploadLabel') }}
                    </span>
                </i18n-t>

                <i18n-t
                    v-else
                    keypath="fileUpload.uploadLabel"
                    tag="span"
                    class="mb-1 text-sm text-gray-500"
                >
                    <span class="text-link font-medium">
                        {{ $t('fileUpload.canNotUpload') }}
                    </span>
                </i18n-t>

                <span
                    class="text-xs text-gray-500"
                    v-if="description && canUpload"
                >
                    {{ description }}
                </span>
            </div>

            <slot
                name="upload-files"
                v-bind="{
                    files: uploadFiles,
                    getUploadFile,
                    getUploadFileByIdx,
                    onRemoveFile,
                    triggerDropdown,
                    getUrlByFile,
                    getTypeByFile,
                    hasFiles,
                    loading,
                }"
            >
                <div
                    v-for="uploadFile in uploadFiles"
                    :key="uploadFile.file.upload.uuid"
                    class="mb-3"
                >
                    <slot
                        name="upload-file"
                        v-bind="{
                            file: uploadFile.file,
                            process: uploadFile.process,
                        }"
                    >
                        <div
                            v-if="
                                uploadFile.file.accepted && !uploadFile.failed
                            "
                            class="flex flex-col rounded-lg border border-gray-200 p-4"
                        >
                            <div class="flex justify-between">
                                <div class="flex flex-col">
                                    <span
                                        class="text-sm font-medium text-gray-700"
                                    >
                                        {{ uploadFile.file.name }}
                                    </span>
                                    <span class="text-sm text-gray-500">
                                        {{
                                            $filters.humanFileSize(
                                                uploadFile.file.size
                                            )
                                        }}
                                    </span>
                                </div>
                                <base-icon
                                    name="line-icons:general:trash-01"
                                    size="md"
                                    variant="gray"
                                    class="cursor-pointer"
                                    :disabled="!isUploaded(uploadFile)"
                                    @click="onRemoveFile(uploadFile)"
                                />
                            </div>

                            <transition name="fade">
                                <div
                                    class="mt-1 flex items-center"
                                    v-show="!isUploaded(uploadFile)"
                                >
                                    <div
                                        class="h-2 grow rounded-lg bg-gray-400"
                                    >
                                        <span
                                            class="block h-full w-0 rounded-lg bg-primary-700 transition-all duration-200"
                                            :style="processStyle(uploadFile)"
                                        />
                                    </div>
                                    <span
                                        class="w-14 text-right text-sm font-medium text-gray-700"
                                    >
                                        {{ uploadFile.process }}%
                                    </span>
                                </div>
                            </transition>
                        </div>

                        <div
                            v-else
                            class="flex flex-col rounded-lg border border-danger-300 bg-danger-25 p-4"
                        >
                            <div class="flex justify-between">
                                <div class="flex flex-col">
                                    <span
                                        class="text-sm font-medium text-danger-700"
                                    >
                                        {{ $t('fileUpload.uploadFailedLabel') }}
                                    </span>
                                    <span
                                        class="text-sm font-normal text-danger-600"
                                    >
                                        {{ uploadFile.file.name }}
                                    </span>
                                </div>
                                <base-icon
                                    name="line-icons:general:trash-01"
                                    size="md"
                                    variant="danger"
                                    class="cursor-pointer"
                                    @click="onRemoveFile(uploadFile)"
                                />
                            </div>
                        </div>
                    </slot>
                </div>
            </slot>
        </slot>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
}
</script>

<script setup>
import Dropzone from 'dropzone'
import { useUploadFile } from '@tenant/composables/use-upload-file'
import { isEmpty } from 'lodash-es'
import coreAxios from '@tenant/core/init/axios'
import axios from 'axios'
import { sameOrigin } from '@tenant/utils/helper'

const emit = defineEmits([
    'update:modelValue',
    'onUploadedFile',
    'onRemoveFile',
])

const props = defineProps({
    canUpload: {
        type: Boolean,
        required: false,
        default: true,
    },
    // ['.svg', '.jpeg'...]
    acceptedFiles: {
        type: Array,
        default: () => [],
    },
    maxFiles: {
        type: Number,
        required: false,
        default: null,
    },
    description: {
        type: String,
        default: '',
    },
    apiEndpoint: {
        type: String,
        required: false,
        default: null,
    },
    apiField: {
        type: String,
        required: false,
        default: null,
    },
    containerClass: {
        type: String,
        required: false,
        default: '',
    },
    onCustomUploadFiles: {
        type: Function,
        required: false,
        default: null,
    },
    customPath: {
        type: Function,
        required: false,
        default: null,
    },
    customName: {
        type: Function,
        required: false,
        default: null,
    },
    modelValue: {
        type: Array,
        default: () => [],
        required: false,
    },
})

const { executeSingle } = useUploadFile(props.apiEndpoint)

const dropzoneRef = ref(null)
const uploadFiles = ref([])
const modelFiles = ref([])
const loading = ref(false)
let dropzoneInstance = null

onMounted(() => {
    dropzoneInstance = new Dropzone(dropzoneRef.value, {
        url: '#',
        autoProcessQueue: false,
        maxFiles: props.maxFiles,
        previewTemplate: '<div></div>',
        acceptedFiles: props.acceptedFiles.toString(),
    })

    dropzoneInstance.on('addedfile', function (file) {
        uploadFiles.value.push({ process: 0, failed: false, file })
    })

    dropzoneInstance.on('addedfiles', (addedFiles) => {
        const files = []
        for (const addedFile of addedFiles) {
            if (!addedFile.accepted) {
                continue
            }

            files.push(addedFile)
        }

        if (isEmpty(files)) {
            return
        }

        if (props.onCustomUploadFiles) {
            props.onCustomUploadFiles(files)
        } else {
            Promise.all(files.map((file) => processUploadFile(file)))
        }

        emitModelValue()
    })

    dropzoneInstance.on('removedfile', (file) => {
        uploadFiles.value = uploadFiles.value.filter(
            (uploadFile) => uploadFile.file.upload.uuid !== file.upload.uuid
        )

        if (!file.accepted) {
            return
        }

        emit('onRemoveFile', file)
        emitModelValue()
    })
})

onBeforeUnmount(() => {
    dropzoneInstance.off('addedfile')
    dropzoneInstance.off('addedfiles')
    dropzoneInstance.off('removedfile')
})

watch(
    () => props.modelValue,
    (files) => {
        if (!files) {
            return
        }

        initialModel(files)
    }
)

watch(
    () => modelFiles.value,
    (files) => {
        emit('update:modelValue', files)
    }
)

const initialModel = async (initialFiles) => {
    try {
        loading.value = true
        await Promise.all(
            initialFiles.map(async (file) => {
                if (file instanceof File) {
                    return
                }

                const path = props.customPath(file)
                let content
                if (sameOrigin(path, window.location)) {
                    content = await coreAxios.get(props.customPath(file), {
                        responseType: 'blob',
                    })
                } else {
                    content = await axios
                        .get(props.customPath(file), {
                            responseType: 'blob',
                        })
                        .then((data) => data.data)
                }

                dropzoneInstance.addFile(
                    new File([content], props.customName(file), {
                        type: content.type,
                    })
                )
                emitModelValue()
            })
        )
    } finally {
        loading.value = false
    }
}

const isUploaded = (uploadFile) => uploadFile.process === 100

const processStyle = (uploadFile) => ({ width: `${uploadFile.process}%` })

const onRemoveFile = (uploadFile) => {
    dropzoneInstance.removeFile(uploadFile.file)
}

const triggerDropdown = () => {
    if (!props.canUpload) {
        return
    }

    dropzoneInstance.hiddenFileInput.click()
}

const processUploadFile = async (file) => {
    const uploadFile = getUploadFile(file)

    if (!uploadFile) {
        return Promise.resolve()
    }

    try {
        const data = await executeSingle(file, props.apiField, {
            onUploadProgress: (progress) => {
                uploadFile.process = Math.floor(
                    (progress.loaded * 100) / (progress.total * 3)
                )
            },
        })

        uploadFile.process = 100
        emit('onUploadedFile', data, file)
    } catch (e) {
        uploadFile.failed = true
    }
}

const getUploadFile = (file) =>
    uploadFiles.value.find(
        (uploadFile) => uploadFile.file.upload.uuid === file.upload.uuid
    )

const getUploadFileByIdx = (index) => {
    return uploadFiles.value[index]
}

const getUrlByFile = (uploadFile) => {
    if (isEmpty(uploadFile.file)) {
        return ''
    }

    return URL.createObjectURL(uploadFile.file)
}

const getTypeByFile = (uploadFile) => {
    return uploadFile?.file?.type
}

const emitModelValue = () => {
    modelFiles.value = uploadFiles.value.reduce((uploadFiles, uploadFile) => {
        if (!uploadFile.file.accepted) {
            return uploadFiles
        }

        return [...uploadFiles, uploadFile.file]
    }, [])
}

const hasFiles = computed(() => !isEmpty(unref(modelFiles)))
</script>
