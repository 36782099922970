<template>
    <div
        class="flex min-w-72 flex-col overflow-hidden rounded-md border border-gray-300 bg-white text-md font-normal text-gray-900"
    >
        <template v-if="items.length">
            <div
                class="flex max-h-72 flex-col overflow-y-auto"
                ref="containerRef"
            >
                <button
                    class="flex items-center gap-2 px-3.5 py-2.5 transition-all duration-100 hover:bg-gray-100"
                    :class="{ 'bg-gray-100': index === state.selectedIndex }"
                    v-for="(user, index) in items"
                    :key="index"
                    ref="itemsRef"
                    @click="selectItem(index)"
                >
                    <base-avatar size="xs" :user="user" />

                    <span class="truncate">
                        {{ getUserDisplayName(user) }}
                    </span>
                </button>
            </div>
        </template>

        <span v-else class="px-3.5 py-2.5">
            {{ $t('searches.emptyState') }}
        </span>
    </div>
</template>

<script setup lang="ts">
import { getUserDisplayName, scrollParentToChild } from '@tenant/utils/helper'
import { User } from '@tenant/core/store/auth'

export interface Props {
    items: User[]
    command: (...args: any) => void
}

const props = defineProps<Props>()

const containerRef = ref(null)
const itemsRef = ref(null)

const state = reactive({
    selectedIndex: 0,
})

watch(
    () => props.items,
    () => {
        state.selectedIndex = 0
    }
)

const onKeyDown = ({ event }: { event: KeyboardEvent }) => {
    if (event.key === 'ArrowUp') {
        upHandler()
        return true
    }

    if (event.key === 'ArrowDown') {
        downHandler()
        return true
    }

    if (event.key === 'Enter') {
        enterHandler()
        return true
    }

    return false
}

watch(
    () => state.selectedIndex,
    (idx) => {
        if (!itemsRef.value || !containerRef.value) {
            return
        }

        scrollParentToChild(containerRef.value, itemsRef.value[idx])
    }
)

const upHandler = () => {
    state.selectedIndex =
        (state.selectedIndex + props.items.length - 1) % props.items.length
}

const downHandler = () => {
    state.selectedIndex = (state.selectedIndex + 1) % props.items.length
}

const enterHandler = () => {
    selectItem(state.selectedIndex)
}

const selectItem = (index: number) => {
    const item = props.items[index]

    if (item) {
        props.command({
            id: item.id,
            label: getUserDisplayName(item),
        })
    }
}

defineExpose({
    onKeyDown,
    upHandler,
    downHandler,
    enterHandler,
    selectItem,
})
</script>
