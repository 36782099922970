<template>
    <table class="w-full border-b border-t border-gray-200">
        <data-grid-header-row v-if="showTableHeader">
            <data-grid-header-cell
                v-for="column in columns"
                :key="column.property"
            >
                <div :class="skeletonClass">.</div>
            </data-grid-header-cell>
            <data-grid-header-cell v-if="showActions">
                <div :class="skeletonClass">.</div>
            </data-grid-header-cell>
        </data-grid-header-row>

        <data-grid-row v-for="row in records" :key="row">
            <data-grid-cell v-for="column in columns" :key="column.property">
                <div :class="skeletonClass">.</div>
            </data-grid-cell>
            <data-grid-action-cell v-if="showActions">
                <div :class="skeletonClass">.</div>
            </data-grid-action-cell>
        </data-grid-row>
    </table>
</template>

<script setup>
import { range } from 'lodash-es'

const props = defineProps({
    totalRow: {
        type: Number,
        default: 5,
        required: false,
    },

    columns: {
        type: Array,
        required: true,
    },

    showActions: {
        type: Boolean,
        default: true,
        required: false,
    },

    showTableHeader: {
        type: Boolean,
        default: true,
        required: false,
    },
})

const skeletonClass =
    'animate-pulse rounded bg-gray-200 text-gray-200 w-full h-full'

const records = range(props.totalRow)
</script>
