<template>
    <div
        class="flex flex-col"
        :class="[fullWidth ? 'w-full' : null, containerClass]"
    >
        <form-control-label :label="label" />

        <div class="relative">
            <form-control-highlight v-if="highlight" />

            <div
                v-if="iconName"
                class="pointer-events-none absolute inset-y-0 left-0 z-10 flex items-center pl-3"
            >
                <base-icon
                    :name="iconName"
                    :variant="
                        success ? 'success' : errorMessage ? 'danger' : 'gray'
                    "
                    size="md"
                />
            </div>

            <input
                ref="input"
                v-bind="restAttrs"
                v-model="value"
                class="relative block w-full rounded-lg border text-md font-normal text-gray-900 placeholder-gray-500 outline-0 focus:ring-4 focus:ring-offset-0 disabled:cursor-not-allowed"
                :class="inputClasses"
                :disabled="disabled"
                :id="id"
            />

            <div
                v-if="tooltip && !errorMessage"
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
            >
                <base-icon
                    name="line-icons:general:help-circle"
                    variant="grayLight"
                    size="sm"
                />
            </div>

            <div
                v-if="errorMessage && !disabled"
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
            >
                <base-icon
                    name="line-icons:alerts-&-feedback:alert-circle"
                    variant="danger"
                    size="sm"
                />
            </div>
        </div>

        <slot name="hint">
            <form-control-hint-text
                v-if="!hideMessage"
                :hint-text="hintText"
                :error-message="errorMessage"
            />
        </slot>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
}
</script>

<script setup>
import { computed, useAttrs } from 'vue'

const props = defineProps({
    /**
     * Label displayed above the form field
     */
    label: {
        type: String,
        default: '',
    },
    /**
     * Short help text that goes below the input field
     */
    hintText: {
        type: String,
        default: '',
    },
    /**
     * A floating, non-actionable label used to explain an input
     */
    tooltip: {
        type: String,
        default: '',
    },
    /**
     * Error message in red shown below the input field when it's destructive
     */
    errorMessage: {
        type: String,
        default: '',
    },
    /**
     * If true apply success styles
     */
    success: {
        type: Boolean,
        default: false,
    },
    /**
     * Set the amount of padding in the input
     */
    size: {
        type: String,
        default: 'md',
        validator(value) {
            return ['sm', 'md'].includes(value)
        },
    },
    /**
     * Highlight dot and outline in yellow shown above the input field
     */
    highlight: {
        type: Boolean,
        default: false,
    },
    /**
     * Name of the icon displayed on the left corner of the input
     */
    iconName: {
        type: String,
        default: '',
    },
    /**
     * Component model value
     */
    modelValue: {
        type: [String, Number],
        default: '',
    },
    /**
     * fullWidth
     */
    fullWidth: {
        type: Boolean,
        default: true,
    },
    /**
     * Hide message
     */
    hideMessage: {
        type: Boolean,
        default: false,
    },
})

// Access the component's fallthrough attributes
const attrs = useAttrs()

const input = ref(null)
const { disabled, id, class: containerClass, ...restAttrs } = attrs

// Input handler
const emit = defineEmits(['update:modelValue'])
const value = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
    },
})

const inputClasses = computed(() => {
    const { iconName, size, errorMessage, highlight, success } = props

    return {
        'pl-10': iconName,
        'px-3 py-2': size === 'sm',
        'px-3.5 py-2.5': size === 'md',
        // Only apply the styles with Primary color when it's non-destructive (default state) to avoid override issues
        'border-gray-300 focus:border-primary-300 focus:ring-primary-100':
            !errorMessage,
        'bg-danger-50 border-danger-300 focus:border-danger-300 focus:ring-danger-100':
            errorMessage && !disabled,
        'text-gray-500 border-gray-300 disabled:bg-gray-50': disabled,
        'border-warning-300 focus:border-warning-300 ring-4 ring-warning-100 focus:ring-warning-100':
            highlight,
        'bg-success-50 border-success-300 focus:border-success-300 focus:ring-success-100':
            success,
    }
})

const focus = () => {
    input.value && input.value.focus()
}

defineExpose({
    focus,
})
</script>
