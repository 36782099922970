<template>
    <div
        class="grid cursor-pointer grid-cols-1 rounded-lg bg-gray-50 px-3 py-2 hover:!bg-primary-50"
    >
        <div class="flex flex-1 items-center gap-x-3">
            <template v-if="isFolder(item)">
                <div class="rounded-full bg-primary-100 p-2.5">
                    <base-icon
                        name="line-icons:files:folder"
                        variant="primary"
                    />
                </div>
                <div class="flex flex-col">
                    <span class="text-sm font-medium text-gray-900">
                        {{ getFolderName(item) }}
                    </span>
                    <span
                        v-if="item.contact"
                        class="text-sm font-normal text-gray-500"
                    >
                        <base-tooltip
                            dark
                            hover
                            :content="$t('documents.list.customerNumber')"
                        >
                            {{ item.contact.customer_number }}
                        </base-tooltip>
                    </span>
                    <span
                        v-else-if="
                            item.employee?.private_information?.date_of_birth
                        "
                        class="text-sm font-normal text-gray-500"
                    >
                        <base-tooltip
                            dark
                            hover
                            :content="$t('documents.list.customerNumber')"
                        >
                            {{
                                $filters.dateShortFormat(
                                    item.employee.private_information
                                        .date_of_birth
                                )
                            }}
                        </base-tooltip>
                    </span>
                </div>
            </template>
            <template v-else>
                <div class="rounded-full bg-gray-100 p-2.5 text-gray-600">
                    <base-icon :name="iconFileName(item)" variant="inherit" />
                </div>
                <div class="flex flex-col">
                    <div class="text-sm font-medium text-gray-900">
                        {{ item.full_name }}
                    </div>
                    <span class="text-sm font-normal text-gray-500">
                        {{ $filters.humanFileSize(item.size) }}
                    </span>
                </div>
            </template>
        </div>
    </div>
</template>
<script setup>
import { DOCUMENTS } from '@tenant/utils/constants'
import { getFolderName } from '@tenant/modules/tenant/documents/utils/helper.utils'

const iconFileName = (item) => {
    let iconName = 'line-icons:files:file-04'
    switch (item.mime) {
        case 'png':
        case 'jpg':
        case 'jpeg':
            iconName = 'line-icons:images:image-01'
            break
        case 'mp4':
            iconName = 'line-icons:media-&-devices:film-02'
            break
        case 'fig':
            iconName = 'line-icons:editor:figma'
            break
        case 'framer':
            iconName = 'line-icons:editor:framer'
            break
        default:
            break
    }

    return iconName
}
const isFolder = (item) => item.type === DOCUMENTS.FOLDER

defineProps({
    item: {
        type: Object,
        required: true,
    },
})
</script>
