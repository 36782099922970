<template>
    <li>
        <a
            v-if="!isCollapsed"
            class="group mb-3 block cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap px-4"
            @click="onClickRootMenu"
        >
            <partial-sidebar-item-main
                class="border-b border-gray-200 bg-white py-2.5 pl-0 pr-0 text-gray-700 group-hover:bg-white group-hover:text-primary-700"
                :name="item.path"
                :is-active="isActive"
                :has-children="hasChildren"
            >
                <span v-if="item.label && !isCollapsed" class="flex-1 text-md">
                    {{ $t(item.label) }}
                </span>
                <base-icon
                    v-if="hasChildren && !isCollapsed"
                    variant="inherit"
                    :name="
                        show || isActive
                            ? 'line-icons:arrows:chevron-up'
                            : 'line-icons:arrows:chevron-down'
                    "
                />
            </partial-sidebar-item-main>
        </a>

        <transition
            v-if="hasChildren"
            name="expand"
            @enter="onExpandEnter"
            @afterEnter="onExpandAfterEnter"
            @beforeLeave="onExpandBeforeLeave"
        >
            <ul v-if="show || isActive" :aria-expanded="show || isActive">
                <li class="pt-1" />
                <li
                    class="relative px-4 pb-1 text-gray-700"
                    v-for="(child, index) of item.children"
                    :key="index"
                >
                    <router-link
                        v-if="child.path"
                        class="flex cursor-pointer items-center gap-2 overflow-hidden text-ellipsis whitespace-nowrap rounded-md text-md hover:bg-primary-50 hover:text-primary-700"
                        active-class="bg-primary-50 hover:!bg-primary-100 text-primary-700"
                        :class="isCollapsed ? 'p-3' : 'px-3 py-2'"
                        :to="{ name: child.path }"
                    >
                        <component
                            v-if="child.component"
                            :is="child.component"
                            v-bind="{ isCollapsed }"
                        />
                        <template v-else>
                            <base-icon
                                v-if="child.icon"
                                :name="child.icon"
                                variant="inherit"
                            />
                            <template v-if="!isCollapsed">
                                {{ $t(child.label) }}
                            </template>
                        </template>
                    </router-link>
                    <template v-else>
                        <div
                            class="flex cursor-pointer items-center gap-2 overflow-hidden text-ellipsis whitespace-nowrap rounded-md bg-primary-50 text-md text-primary-700 hover:!bg-primary-100 hover:text-primary-700"
                            :class="isCollapsed ? 'p-3' : 'py-2 pl-12'"
                        >
                            <base-icon
                                v-if="child.icon"
                                :name="child.icon"
                                variant="inherit"
                            />
                            <template v-if="!isCollapsed">
                                {{ $t(child.label) }}
                            </template>
                        </div>
                    </template>
                </li>
            </ul>
        </transition>
    </li>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { intersection } from 'lodash-es'

const route = useRoute()
const router = useRouter()
const emit = defineEmits(['open-sidebar'])

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },

    isCollapsed: {
        type: Boolean,
        default: false,
    },
})

const hasChildren = computed(
    () => props.item.children && props.item.children.length > 0
)

const isActive = computed(() => {
    const matchedRoutes = router.currentRoute.value.matched.map(
        (route) => route.name
    )

    if (hasChildren.value) {
        const menuRoutes = props.item.children.map((child) => child.path)
        return intersection(matchedRoutes, menuRoutes).length > 0
    }

    return intersection(matchedRoutes, [props.item.path]).length > 0
})

const show = ref(false)

onMounted(async () => {
    if (!hasChildren.value) {
        return
    }

    await router.isReady()

    show.value = props.item.children.some((item) => {
        return route.name.includes(item.path)
    })
})

const onClickRootMenu = () => {
    if (!hasChildren.value) {
        return
    }

    show.value = props.isCollapsed ? true : !show.value
    emit('open-sidebar')
}

const onExpandEnter = (el) => {
    el.style.height = el.scrollHeight + 'px'
}

const onExpandAfterEnter = (el) => {
    el.style.height = 'auto'
}

const onExpandBeforeLeave = (el) => {
    el.style.height = el.scrollHeight + 'px'
}
</script>
