<template>
    <span v-if="svg" v-html="svg" :style="styles" :class="classes" />
</template>

<script setup>
import axios from 'axios'
import {
    getCacheIcon,
    setCacheIcon,
} from '@tenant/components/base/icon/base-icon'
import { asset } from '@tenant/core/filter'

const props = defineProps({
    variant: {
        type: String,
        default: 'primary',
        required: false,
        validator(value) {
            return [
                'inherit',
                'primary',
                'gray',
                'white',
                'grayLight',
                'danger',
                'warning',
                'success',
                'hardPrimary',
                'current',
            ].includes(value)
        },
    },
    size: {
        type: String,
        default: 'md',
        required: false,
        validator(value) {
            return [
                'xxs',
                'xs',
                'sm',
                'md',
                'lg',
                'xl',
                '2xl',
                '3xl',
                '4xl',
                '5xl',
            ].includes(value)
        },
    },
    name: {
        type: String,
        required: true,
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
})

const svg = ref(null)

onMounted(async () => {
    await setSvg(props.name)
})

watch(
    () => props.name,
    async (value) => {
        await setSvg(value)
    },
    { deep: true }
)

const styles = computed(() => {
    const { size } = props

    const sizeData = {
        xxs: '9px',
        xs: '12px',
        sm: '16px',
        md: '20px',
        lg: '24px',
        xl: '30px',
        '2xl': '36px',
        '3xl': '48px',
        '4xl': '86px',
        '5xl': '120px',
    }

    return {
        width: sizeData[size],
        height: sizeData[size],
    }
})

const classes = computed(() => {
    const { variant } = props

    const colorData = {
        inherit: null,
        primary: 'text-primary-500',
        gray: 'text-gray-500',
        grayLight: 'text-gray-400',
        danger: 'text-danger-500',
        warning: 'text-warning-500',
        success: 'text-success-500',
        white: 'text-white',
        hardPrimary: 'text-primary-700',
        current: 'stroke-current',
    }

    return [
        'sas-icon',
        colorData[variant],
        props.disabled ? 'pointer-events-none opacity-50' : '',
    ]
})

const setSvg = async (name) => {
    const iconDirectory = name.split(':').join('/')
    let svgData = getCacheIcon(iconDirectory)

    if (!svgData) {
        const resp = await axios.get(asset(`static/icons/${iconDirectory}.svg`))
        svgData = setCacheIcon(iconDirectory, resp.data)
    }

    svg.value = svgData
}
</script>
