<template>
    <div class="relative flex flex-col">
        <div
            class="relative flex"
            :class="[props.tabClass, tabsClasses, { 'mb-6': hasMargin }]"
        >
            <slot name="tabs" v-bind="{ items, tabsClasses, tabActiveClasses }">
                <router-link
                    v-for="(item, index) in items"
                    :to="{
                        name: item.route,
                        query: keepQuery ? $route.query : null,
                    }"
                    :key="index"
                    :active-class="tabActiveClasses"
                    :class="[tabClasses, tabItemClass]"
                    class="mr-2 flex cursor-pointer items-center px-3 py-2 font-medium text-gray-500 transition duration-150"
                >
                    <slot name="tab-item" v-bind="{ item }">
                        {{ item.label }}
                        <span
                            class="w-min-6 ml-2 flex items-center rounded-full bg-gray-100 px-[9px] py-0.5 text-center text-xs"
                            v-if="item.badgeContent || item.iconName"
                        >
                            <base-icon
                                class="mx-1"
                                v-if="item.iconName"
                                :name="item.iconName"
                            />
                            {{ item.badgeContent }}
                        </span>
                    </slot>
                </router-link>
            </slot>
        </div>

        <slot name="content">
            <router-view :route-props="contentProps"></router-view>
        </slot>
    </div>
</template>

<script setup>
const props = defineProps({
    items: {
        type: Array,
        required: true,
    },

    variant: {
        type: String,
        default: 'horizontal',
    },

    tabClass: {
        type: [String, Array, Object],
        default: null,
    },

    tabItemClass: {
        type: [String, Array, Object],
        default: null,
    },

    size: {
        type: String,
        default: 'sm',
    },

    hasMargin: {
        type: Boolean,
        default: true,
    },
    keepQuery: {
        type: Boolean,
        default: true,
    },
    tabStyle: {
        type: String,
        default: 'default',
        validator(value) {
            return ['underlined', 'rounded', 'default'].includes(value)
        },
    },
    contentProps: {
        type: [String, Object, Array, Number],
        required: false,
    },
})

const tabsClasses = computed(() => ({
    'flex-col': props.variant === 'vertical',
    'text-sm': props.size === 'sm',
    'text-md': props.size === 'md',
    'rounded-lg border bg-gray-50 p-1 border-gray-100':
        props.tabStyle === 'rounded',
}))

const tabActiveClasses = computed(() => {
    if (props.tabStyle === 'rounded') {
        return 'bg-white shadow-sm rounded-md'
    }

    if (props.tabStyle === 'underlined') {
        return 'text-primary-700 border-b-2 border-b-primary-700'
    }

    return 'bg-primary-50 text-primary-700'
})

const tabClasses = computed(() => ({
    'rounded-md text-gray-500 hover:bg-primary-100 hover:text-primary-700 focus:ring-primary-100':
        props.tabStyle === 'default',
    'rounded-md text-gray-700 bg-transparent hover:bg-white border-transparent hover:text-gray-600':
        props.tabStyle === 'rounded',
    'font-medium text-gray-500 transition duration-150 hover:text-primary-700 p-3 z-10':
        props.tabStyle === 'underlined',
}))
</script>
