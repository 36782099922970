<template>
    <div
        class="flex flex-col"
        :class="[block ? 'w-full' : null, containerClass]"
    >
        <form-control-label :label="label" />

        <div class="relative">
            <form-control-highlight v-if="highlight" />
            <div
                v-if="iconName"
                class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
            >
                <base-icon :name="iconName" variant="gray" size="md" />
            </div>

            <vue-number
                v-bind="restAttrs"
                v-model="value"
                :precision="precision"
                :prefix="prefix"
                :suffix="suffix"
                :decimal="decimal"
                :separator="thousand"
                class="block w-full rounded-lg border text-md text-gray-900 placeholder-gray-500 outline-0 focus:ring-4 focus:ring-offset-0 disabled:cursor-not-allowed"
                :class="[inputClasses, inputClass]"
                :disabled="disabled"
                :max="max"
            />

            <slot v-if="$slots['right']">
                <slot name="right" />
            </slot>

            <slot name="tooltip" v-bind="{ tooltip, errorMessage }">
                <div
                    v-if="tooltip && !errorMessage"
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                >
                    <base-icon
                        name="line-icons:general:help-circle"
                        variant="grayLight"
                        size="sm"
                    />
                </div>

                <div
                    v-if="errorMessage && !disabled"
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                >
                    <base-icon
                        name="line-icons:alerts-&-feedback:alert-circle"
                        variant="danger"
                        size="sm"
                    />
                </div>
            </slot>
        </div>

        <form-control-hint-text
            v-if="!hideMessage"
            :hint-text="hintText"
            :error-message="errorMessage"
        />
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
}
</script>

<script setup>
import { DECIMAL_SEPARATOR, THOUSAND_SEPARATOR } from '@tenant/utils/constants'

const props = defineProps({
    /**
     * Highlight dot and outline in yellow shown above the input field
     */
    highlight: {
        type: Boolean,
        default: false,
    },
    iconName: {
        type: String,
        default: '',
    },
    inputClass: {
        type: String,
        default: '',
    },
    label: {
        type: String,
        default: '',
    },
    hintText: {
        type: String,
        default: '',
    },
    tooltip: {
        type: String,
        default: '',
    },
    errorMessage: {
        type: String,
        default: '',
    },
    size: {
        type: String,
        default: 'md',
    },
    modelValue: {
        type: [String, Number],
        default: '',
    },
    precision: {
        type: Number,
        default: null,
    },
    rightIcon: {
        type: String,
        default: '',
    },
    prefix: {
        type: String,
        default: '',
    },
    suffix: {
        type: String,
        default: '',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    block: {
        type: Boolean,
        default: true,
    },
    hideMessage: {
        type: Boolean,
        default: false,
    },
    max: {
        type: Number,
        default: null,
    },
})

// Access the component's fallthrough attributes
const attrs = useAttrs()

const { class: containerClass, ...restAttrs } = attrs

// Input handler
const emit = defineEmits(['update:modelValue'])
const value = computed({
    get() {
        return props.modelValue ? Number(props.modelValue) : ''
    },
    set(value) {
        emit('update:modelValue', value ? Number(value) : null)
    },
})

const thousand = ref(THOUSAND_SEPARATOR)
const decimal = ref(DECIMAL_SEPARATOR)

const inputClasses = computed(() => {
    const { iconName, size, errorMessage, highlight, disabled } = props

    return {
        'pl-10': iconName,
        'px-3 py-2': size === 'sm',
        'px-3.5 py-2.5': size === 'md',
        // Only apply the styles with Primary color when it's non-destructive (default state) to avoid override issues
        'border-gray-300 focus:border-primary-300 focus:ring-primary-100':
            !errorMessage,
        'border-danger-300 focus:border-danger-300 focus:ring-danger-100':
            errorMessage && !disabled,
        'text-gray-500 border-gray-300 disabled:bg-gray-50': disabled,
        'border-warning-300 focus:border-warning-300 ring-4 ring-warning-100 focus:ring-warning-100':
            highlight,
    }
})
</script>
