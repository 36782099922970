<template>
    <slot v-bind="{ errorMessage }" />
</template>

<script setup>
import { toRef } from 'vue'
import { useFieldError } from 'vee-validate'

const emit = defineEmits(['on-unmounted'])

const props = defineProps({
    name: {
        type: String,
        required: true,
    },
})

const errorMessage = useFieldError(toRef(props, 'name'))

onUnmounted(() => {
    emit('on-unmounted', props.name)
})
</script>
