<template>
    <div class="w-full">
        <span class="pr-2 text-xs text-gray-500">
            {{ note ? note : $t('settingsCurrencies.base_currency_note') }}
        </span>
        <base-badge
            v-if="company"
            :label="baseCurrency"
            variant="success"
            @click="onClickBaseCurrency"
        />
    </div>
</template>
<script setup>
const { company } = useAuth()
const { defaultCurrency } = useCurrencies()
const emit = defineEmits(['click-base-currency'])

defineProps({
    note: {
        type: String,
        required: false,
    },
})

const baseCurrency = computed(() => {
    return company.value?.currency?.iso ?? defaultCurrency.value?.iso
})

const onClickBaseCurrency = () => {
    emit('click-base-currency')
}
</script>
