<template>
    <component
        :is="params.component"
        v-bind="params.props"
        :data="params.data"
        :value="params.value"
        @click="handleEvent('click', $event)"
        @change="handleEvent('change', $event)"
    />
</template>

<script setup lang="ts">
import type { Component } from 'vue'

export interface AgGridCellProps {
    params: {
        component?: Component
        props?: object
        on?: Record<any, any>
        data?: object
        value?: any
    }
}

const props = defineProps<AgGridCellProps>()

const handleEvent = (eventName: string, event: any) => {
    const { params } = props
    if (!params.on) {
        return
    }

    const handler = params.on[eventName] || null
    if (!handler) {
        return
    }

    handler(params.value, params.data, event)
}
</script>
