<template>
    <div
        v-if="searchEntity"
        ref="dropdown"
        class="absolute left-0 top-full z-20 mt-2 w-80 rounded-lg border border-gray-100 bg-white p-4 text-sm text-gray-700 shadow-md"
    >
        <base-loading
            v-if="loading"
            class="justify-center bg-white py-2"
            size="sm"
        />

        <template v-else>
            <div class="flex w-full flex-col gap-3">
                <accountant-partial-search-bar-more-character
                    v-if="searchTerm.length < 3"
                />
                <accountant-partial-search-bar-empty-state
                    v-else-if="!results.length"
                />
                <component
                    v-else
                    :is="getComponent(searchEntity)"
                    v-for="item in results"
                    :key="item.id"
                    :item="item"
                    @click="onClickItem(item)"
                />
            </div>
        </template>
    </div>
</template>

<script setup>
import SearchClientComponent from '@tenant/components/partial/accountant/accountant-search-bar/items/accountant-search-clients-item.vue'

const dropdown = ref(null)
const emit = defineEmits(['hide-search-result'])

const props = defineProps({
    results: {
        type: Array,
        default: () => [],
    },

    loading: {
        type: Boolean,
        default: false,
    },

    searchTerm: {
        type: String,
        default: '',
    },

    searchEntity: {
        type: String,
        required: true,
    },
})

const itemComponents = {
    'accountant-search-clients-item': SearchClientComponent,
}

const getComponent = (component) => {
    return itemComponents[`accountant-search-${component}-item`]
}

const close = (e) => {
    if (e.target.id === 'searchEntities') {
        return
    }

    if (!dropdown.value.contains(e.target)) {
        emit('hide-search-result')
    }
}

onMounted(() => {
    document.addEventListener('click', close)
})

onBeforeUnmount(() => {
    document.removeEventListener('click', close)
})

const router = useRouter()
const onClickItem = (item) => {
    switch (props.searchEntity) {
        case 'clients':
            router.push({
                name: 'accountant.clients.detail',
                params: { id: item.id },
            })
            break
    }

    emit('hide-search-result')
}
</script>
