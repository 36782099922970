<template>
    <div :class="containerClass">
        <div
            v-if="!loaded && showLoading"
            class="absolute bottom-0 left-0 right-0 top-0 z-50"
        >
            <span
                class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
            >
                <base-loading size="lg" />
            </span>
        </div>

        <object v-if="base64File" :data="base64File" v-bind="$attrs">
            <slot />
        </object>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
}
</script>

<script setup>
const props = defineProps({
    showLoading: {
        type: Boolean,
        default: true,
    },
    url: {
        type: String,
        default: null,
    },
    containerClass: {
        type: String,
        default: null,
    },
})

const { loadFile, base64File, loaded } = useFileStorage()

onMounted(() => {
    loadFile(props.url)
})

watch(
    () => props.url,
    (value) => {
        loadFile(value)
    }
)
</script>
