<template>
    <div class="flex w-full flex-col">
        <form-control-label :label="label" />

        <div
            ref="formControlRef"
            class="relative flex gap-2 rounded-lg border outline-0 focus:ring-4 focus:ring-offset-0"
            :class="containerClasses"
        >
            <form-control-highlight v-if="highlight" />

            <div v-if="iconName" class="pointer-events-none flex items-center">
                <base-icon :name="iconName" variant="gray" size="md" />
            </div>

            <div class="flex grow flex-wrap items-baseline gap-x-1.5 gap-y-1">
                <form-control-result-list
                    :results="results"
                    option-label="value"
                    @clear-selection="onClearResults"
                />

                <input
                    class="grow border-none p-0 text-md text-gray-900 placeholder-gray-500 outline-0 focus:ring-0 disabled:cursor-not-allowed disabled:bg-transparent"
                    :class="{ 'text-gray-500': disabled }"
                    v-bind="attrs"
                    v-model="inputValue"
                    :placeholder="placeholder"
                    @focus="onFocus"
                    @keyup.enter="onEnterInput"
                    @change="onEnterInput"
                />
            </div>

            <div
                v-if="tooltip && !errorMessage"
                class="pointer-events-none flex items-center"
            >
                <base-icon
                    name="line-icons:general:help-circle"
                    variant="grayLight"
                    size="sm"
                />
            </div>

            <div
                v-if="errorMessage && !disabled"
                class="pointer-events-none flex items-center"
            >
                <base-icon
                    name="line-icons:alerts-&-feedback:alert-circle"
                    variant="danger"
                    size="sm"
                />
            </div>
        </div>

        <form-control-hint-text
            :hint-text="hintText"
            :error-message="errorMessage"
        />
    </div>
</template>

<script setup>
import { onClickOutside } from '@vueuse/core'
import { useSelector } from '@tenant/composables'

const attrs = useAttrs()
const emit = defineEmits(['update:modelValue'])

const props = defineProps({
    /**
     * Label displayed above the form field
     */
    label: {
        type: String,
        default: '',
    },
    /**
     * If there is no option selected, a placeholder will show
     */
    placeholder: {
        type: String,
        default: () => {
            const { t } = useI18n()

            return t('general.enterValueLabel')
        },
    },
    /**
     * Short help text that goes below the input field
     */
    hintText: {
        type: String,
        default: '',
    },
    /**
     * A floating, non-actionable label used to explain an input
     */
    tooltip: {
        type: String,
        default: '',
    },
    /**
     * Error message in red shown below the input field when it's destructive
     */
    errorMessage: {
        type: String,
        default: '',
    },
    /**
     * Set the amount of padding in the input
     */
    size: {
        type: String,
        default: 'md',
        validator(value) {
            return ['sm', 'md'].includes(value)
        },
    },
    /**
     * Highlight dot and outline in yellow shown above the input field
     */
    highlight: {
        type: Boolean,
        default: false,
    },
    /**
     * Name of the icon displayed on the left corner of the input
     */
    iconName: {
        type: String,
        default: '',
    },
    /**
     * Component model value
     */
    modelValue: {
        type: Array,
        default: () => [],
    },
})

const isFocused = ref(false)
const inputValue = ref('')
const formControlRef = ref(null)
const { results, addResultItem, removeResultItem, replaceResults } =
    useSelector([], [], 'value', inputValue, true)

const { disabled } = attrs
const containerClasses = computed(() => {
    const { size, errorMessage, highlight } = props

    return {
        'px-3 py-2': size === 'sm',
        'px-3.5 py-2.5': size === 'md',
        'border-gray-300': !errorMessage,
        'ring-4 ring-offset-0': isFocused.value,
        'border-primary-300 ring-primary-100': isFocused.value && !errorMessage,
        'border-danger-300': errorMessage && !disabled,
        'border-danger-300 ring-danger-100':
            isFocused.value && errorMessage && !disabled,
        'border-gray-300 bg-gray-50 cursor-not-allowed': disabled,
        'border-warning-300 focus:border-warning-300 ring-4 ring-warning-100 focus:ring-warning-100':
            highlight,
    }
})

watch(
    () => props.modelValue,
    () => {
        const internalResults = props.modelValue.map((resultItem) => ({
            value: resultItem,
        }))

        replaceResults(internalResults)
    },
    { immediate: true, deep: true }
)

watch(
    results,
    (values) => {
        const resultValues = values.map((resultItem) => resultItem.value)
        if (props.modelValue.length === resultValues.length) {
            let isEqual = true
            for (let i = 0; i < props.modelValue.length; i++) {
                if (!resultValues.includes(props.modelValue[i])) {
                    isEqual = false
                    break
                }
            }

            if (isEqual) {
                return
            }
        }

        emit('update:modelValue', resultValues)
    },
    { deep: true }
)

const onEnterInput = () => {
    if (!inputValue.value) {
        return
    }

    addResultItem({
        value: inputValue.value,
    })

    inputValue.value = ''
}

const onClearResults = (option) => {
    if (disabled) {
        return
    }

    removeResultItem(option)
}

const onFocus = () => {
    isFocused.value = true
}

const onUnFocus = () => {
    isFocused.value = false
}

onClickOutside(formControlRef, onUnFocus)
</script>
