<template>
    <slide-out-menu size="xs" :has-padding="false">
        <template #close-icon>
            <div class="pr-6 pt-6">
                <base-icon
                    size="lg"
                    variant="gray"
                    class="cursor-pointer"
                    name="line-icons:general:x"
                    @click.prevent="$emit('close')"
                />
            </div>
        </template>

        <template #slide-out-menu-header>
            <div class="pl-6 pt-6">
                <div class="text-xl text-gray-900">
                    {{ centralUser?.full_name }}
                </div>
                <div class="text-sm text-gray-500">
                    {{ centralUser?.email }}
                </div>
            </div>
        </template>

        <div class="flex justify-end border-b border-gray-200 px-6 py-3.5">
            <base-button
                variant="link-danger"
                :has-padding="false"
                icon="line-icons:general:log-out-01"
                @click="logout"
            >
                {{ $t('userMenu.logout') }}
            </base-button>
        </div>
    </slide-out-menu>
</template>

<script setup>
const { centralUser } = useAuth()
const { logout } = useAuthHelper()
</script>
