<template>
    <div
        class="flex w-full items-start justify-between gap-x-6 border-b border-gray-300 px-6 py-3.5"
        v-bind="attrs"
    >
        <slot name="heading" v-if="withHeading">
            <slot name="left">
                <div class="flex flex-col flex-wrap gap-1 self-center">
                    <slot name="title">
                        <strong
                            class="text-xl font-medium text-gray-900"
                            v-if="title"
                        >
                            {{ title }}
                            <base-badge
                                v-if="badge"
                                :label="badge"
                                class="ml-2"
                            />
                        </strong>
                    </slot>

                    <slot name="description">
                        <span
                            class="text-sm font-normal text-gray-500"
                            v-if="description"
                        >
                            {{ description }}
                        </span>
                    </slot>
                </div>
            </slot>
            <slot name="right" />
        </slot>
    </div>

    <div
        class="flex flex-1 flex-col overflow-auto"
        :class="contentClass"
        ref="el"
    >
        <slot />
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
}
</script>

<script setup>
import { useInfiniteScroll } from '@vueuse/core'

defineProps({
    title: {
        type: String,
        default: '',
    },
    description: {
        type: String,
        default: '',
    },
    withHeading: {
        type: Boolean,
        default: true,
    },
    badge: {
        type: String,
        default: null,
    },
    contentClass: {
        type: String,
        default: null,
    },
})

const attrs = useAttrs()
const emitter = useEmitter()

const el = ref(null)

useInfiniteScroll(
    el,
    () => {
        emitter.emit('scroll-end')
    },
    { distance: 10 }
)

onMounted(() => {
    emitter.emit('set-sticky-style', true)
})

onUnmounted(() => {
    emitter.emit('set-sticky-style', false)
})
</script>
