<template>
    <div class="flex items-center justify-between">
        <base-button
            size="sm"
            variant="default"
            :disabled="currentPage === 1"
            @click="prevPage"
        >
            <base-icon name="line-icons:arrows:arrow-left" variant="inherit" />
            {{ $t('grid.pagination.prev') }}
        </base-button>

        <!-- small viewport content -->
        <span class="md:hidden">
            {{ $t('grid.pagination.page') }} {{ currentPage }}
            {{ $t('grid.pagination.of') }} {{ total }}
        </span>

        <!-- large viewport content -->
        <ul class="hidden items-center gap-px md:flex">
            <li v-for="(pageNum, index) in displayedPages" :key="index">
                <base-button
                    v-if="typeof pageNum === 'number'"
                    size="sm"
                    class="focus:ring-transparent"
                    :label="pageNum.toString()"
                    :variant="
                        currentPage === pageNum ? 'gray' : 'tertiary-color'
                    "
                    @click="changePageByPageNumber(pageNum)"
                />
                <div v-else class="min-w-[40px] cursor-default text-center">
                    {{ pageNum }}
                </div>
            </li>
        </ul>

        <base-button
            size="sm"
            variant="default"
            :disabled="currentPage === total"
            @click="nextPage"
        >
            {{ $t('grid.pagination.next') }}
            <base-icon name="line-icons:arrows:arrow-right" variant="inherit" />
        </base-button>
    </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { range } from 'lodash-es'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()
const props = defineProps({
    page: {
        type: Number,
        required: true,
    },

    replaceQuery: {
        type: Boolean,
        default: true,
    },

    total: {
        type: Number,
        required: true,
    },

    totalVisible: {
        type: Number,
        required: false,
        default: 7,
    },
})

const currentPage = ref(props.page)

watch(
    () => props.page,
    (value) => {
        currentPage.value = value
    }
)

const emit = defineEmits(['page-change'])

watch(
    () => props.page,
    (value) => {
        currentPage.value = value
    }
)

const pageChange = async () => {
    if (props.replaceQuery) {
        const query = {
            ...route.query,
            page: currentPage.value,
        }

        await router.replace({ query })
    }

    emit('page-change', currentPage.value, 'page')
}

const changePageByPageNumber = (pageNum) => {
    currentPage.value = pageNum
    pageChange()
}

const prevPage = () => {
    currentPage.value -= 1
    pageChange()
}

const nextPage = () => {
    currentPage.value += 1
    pageChange()
}

const displayedPages = computed(() => {
    const maxPages = props.total
    const maxVisible = props.totalVisible

    if (maxPages <= maxVisible) {
        return range(1, maxPages + 1)
    }

    const even = maxVisible % 2 === 0 ? 1 : 0
    const left = Math.floor(maxVisible / 2)
    const right = maxPages - left + 1 + even

    if (currentPage.value === left) {
        return [
            ...range(1, left + 2),
            '...',
            ...range(maxPages - left + 1 + even, maxPages + 1),
        ]
    }

    if (currentPage.value === right) {
        return [
            ...range(1, left + 1),
            '...',
            ...range(maxPages - left + even, maxPages + 1),
        ]
    }

    if (currentPage.value > left && currentPage.value < right) {
        const start = currentPage.value - left + 2
        const end = currentPage.value + left - 2 - even

        return [1, '...', ...range(start, end + 1), '...', maxPages]
    }

    return [
        ...range(1, left + 1),
        '...',
        ...range(maxPages - left + 1 + even, maxPages + 1),
    ]
})
</script>
