<template>
    <base-modal
        close
        size="xxl"
        :show="show"
        :loading="loading"
        @modal-close="closeModal"
    >
        <template #modal-header>
            <span class="text-lg font-medium text-gray-900">
                {{ $t('settingsTextTemplates.modalPlaceholder.title') }}
            </span>
        </template>
        <div class="flex flex-col">
            <base-tabs
                variant="horizontal"
                :items="textTemplatePlaceholderTypes"
            >
                <template #tabs="{ items }">
                    <span
                        v-for="item in items"
                        :key="item.id"
                        class="mr-2 cursor-pointer rounded-md px-3 py-2 font-medium text-gray-500 transition duration-150 hover:bg-primary-100 hover:text-primary-700 focus:ring-primary-100"
                        :class="activeClass(item.id, activePlaceholderType)"
                        @click="changePlaceholderTypeTab(item)"
                    >
                        {{ item.name }} ({{
                            item.text_template_placeholders.length
                        }})
                    </span>
                </template>
                <template #content>
                    <div class="grid grid-cols-4 gap-1 text-sm">
                        <span
                            v-for="item in templatePlaceholders"
                            :key="item.id"
                            class="cursor-pointer rounded-md px-3 py-2 text-center font-medium text-gray-500 transition duration-150 hover:bg-primary-100 hover:text-primary-700 focus:ring-primary-100"
                            :class="activePlaceholderClass(item.id)"
                            @click="selectPlaceholder(item)"
                        >
                            {{ item.name }}
                            <span class="block break-all text-xs font-normal">
                                {{ item.value }}
                            </span>
                        </span>
                    </div>
                </template>
            </base-tabs>
        </div>
        <template #modal-footer>
            <div class="flex justify-end gap-3">
                <base-button
                    outline
                    variant="default"
                    class="w-60 justify-center"
                    @click="closeModal"
                >
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button class="w-60 justify-center" @click="onSubmit">
                    {{
                        $t('general.insert', {
                            number: totalSelectedPlaceholder,
                        })
                    }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
import { computed, ref, unref, watch } from 'vue'
import { useApi } from '@tenant/composables'

const props = defineProps({
    show: {
        type: Boolean,
        required: false,
    },
    templateType: {
        type: [Number, String],
        required: false,
    },
    templateTechnicalType: {
        type: [Number, String],
        required: false,
    },
    templateLineItemType: {
        type: [Number, String],
        required: false,
    },
})

const emit = defineEmits(['modal-close', 'on-insert'])

const { execute, loading } = useApi(
    '/api/text-template-placeholder-types',
    'GET'
)

const textTemplatePlaceholderTypes = ref([])
const activePlaceholderType = ref(null)
const selectedPlaceholders = ref([])

const templatePlaceholders = computed(() => {
    const placeholderType = unref(activePlaceholderType)
    if (!placeholderType) {
        return []
    }

    return placeholderType.text_template_placeholders || []
})

const totalSelectedPlaceholder = computed(
    () => selectedPlaceholders.value.length
)

watch(
    () => props.show,
    async (value) => {
        if (!value) {
            return
        }

        textTemplatePlaceholderTypes.value = await execute({
            queries: {
                type_id: unref(props.templateType),
                type_module_id: unref(props.templateTechnicalType),
                type_line_item_id: unref(props.templateLineItemType),
            },
        })
        activePlaceholderType.value = unref(textTemplatePlaceholderTypes)[0]
    }
)

const activeClass = (itemId, activeItem) => {
    return itemId === activeItem.id ? 'bg-primary-50 !text-primary-700' : ''
}

const activePlaceholderClass = (itemId) => {
    return selectedPlaceholders.value.find((i) => i.id === itemId)
        ? 'bg-primary-50 !text-primary-700'
        : ''
}

const changePlaceholderTypeTab = (item) => {
    activePlaceholderType.value = item
}

const selectPlaceholder = (item) => {
    const foundIndex = selectedPlaceholders.value.findIndex(
        (i) => i.id === item.id
    )

    if (foundIndex > -1) {
        selectedPlaceholders.value.splice(foundIndex, 1)
    } else {
        selectedPlaceholders.value.push(item)
    }
}

const closeModal = () => {
    emit('modal-close')
    selectedPlaceholders.value = []
}

const onSubmit = () => {
    emit('on-insert', unref(selectedPlaceholders))
    closeModal()
}
</script>
