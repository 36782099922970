<template>
    <div :class="containerClass">
        <div
            v-if="!loaded && showLoading"
            class="absolute bottom-0 left-0 right-0 top-0 z-50"
        >
            <span
                class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
            >
                <base-loading :size="loadingSize" />
            </span>
        </div>

        <slot>
            <img v-if="base64File" :src="base64File" v-bind="$attrs" />
        </slot>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
}
</script>

<script setup>
const props = defineProps({
    showLoading: {
        type: Boolean,
        default: true,
    },
    src: {
        type: String,
        default: null,
    },
    containerClass: {
        type: String,
        default: null,
    },
    loadingSize: {
        type: String,
        default: 'lg',
        required: false,
        validator(value) {
            return ['xxs', 'xs', 'sm', 'md', 'lg', 'xl', '2xl'].includes(value)
        },
    },
})

const { loadFile, base64File, loaded } = useFileStorage()

onMounted(() => {
    loadFile(props.src)
})

watch(
    () => props.src,
    (value) => {
        loadFile(value)
    }
)
</script>
