<template>
    <aside
        class="sentry-unmask flex flex-col transition-all"
        :class="isCollapsed ? 'w-20' : 'w-80'"
    >
        <slot name="header" />

        <slot name="menu" v-bind="{ menus, isCollapsed, openSidebar }">
            <ul
                class="flex-1 overflow-auto py-8"
                v-if="menus && menus.length > 0"
            >
                <template v-for="(menu, index) of menus" :key="index">
                    <partial-sidebar-item
                        v-if="hasPermission(menu?.permission)"
                        :item="menu"
                        :is-collapsed="isCollapsed"
                        @open-sidebar="openSidebar"
                    />
                </template>
            </ul>
        </slot>

        <slot name="footer">
            <div class="flex justify-end border-t px-4">
                <base-button
                    class="p-3"
                    variant="link-gray"
                    :has-padding="false"
                    @click="toggleCollapseSidebar"
                >
                    <base-icon
                        v-if="isCollapsed"
                        name="line-icons:arrows:chevron-right"
                        size="lg"
                        variant="inherit"
                    />
                    <base-icon
                        v-else
                        name="line-icons:arrows:chevron-left"
                        size="lg"
                        variant="inherit"
                    />
                </base-button>
            </div>
        </slot>
    </aside>
</template>

<script setup>
defineProps({
    menus: {
        type: Array,
        required: false,
    },
})

const route = useRoute()
const isCollapsed = ref(false)

watch(
    () => route.name,
    () => {
        if (route.meta.closeSidebar) {
            isCollapsed.value = !!route.meta.closeSidebar
        }
    }
)

const toggleCollapseSidebar = () => {
    isCollapsed.value = !isCollapsed.value
}

const openSidebar = () => {
    isCollapsed.value = false
}

onMounted(() => {
    window.addEventListener('resize', onScreenResize)
})

onDeactivated(() => {
    window.removeEventListener('resize', onScreenResize)
})

const onScreenResize = () => {
    isCollapsed.value = window.innerWidth <= 1440
}

const acl = useAcl()
const hasPermission = (permission) => {
    if (!permission) {
        return true
    }

    if (typeof permission === 'function') {
        return permission()
    }

    return acl.can(permission)
}

defineExpose({
    isCollapsed,
})
</script>
