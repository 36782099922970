<template>
    <div class="relative z-20 w-full" ref="container">
        <div
            class="fixed z-10 min-w-60 rounded-lg border border-gray-100 shadow-lg"
            :class="{ '-translate-y-full': position === 'top' }"
            :style="[
                {
                    left: `${state.left}px`,
                    width: `${state.width}px`,
                    top: `${state.top}px`,
                },
            ]"
        >
            <slot name="before-option" />
            <div
                ref="el"
                class="max-h-[260px] overflow-y-auto rounded-lg"
                v-if="options && options.length"
            >
                <div
                    v-for="(item, index) of options"
                    :key="item[optionValue]"
                    @click="onClickOption(item)"
                    :class="{
                        'border-t': borderedOption && index > 0,
                    }"
                >
                    <slot
                        name="option"
                        v-bind="{ item, index, selected: isSelected(item) }"
                    >
                        <div
                            class="flex cursor-pointer select-none justify-between px-3.5 py-2.5 hover:bg-gray-50"
                            :class="[
                                isSelected(item) ? 'bg-gray-50' : 'bg-white',
                            ]"
                        >
                            <slot
                                name="option-label"
                                v-bind="{
                                    item,
                                    index,
                                    selected: isSelected(item),
                                }"
                            >
                                <span
                                    class="overflow-hidden text-ellipsis whitespace-nowrap"
                                >
                                    {{ getOptionLabel(item) }}
                                </span>
                            </slot>

                            <slot
                                name="option-icon"
                                v-bind="{
                                    item,
                                    index,
                                    selected: isSelected(item),
                                }"
                            >
                                <div
                                    v-if="isSelected(item)"
                                    class="align-self-end pointer-events-none inset-y-0 right-0 flex items-center"
                                >
                                    <base-icon
                                        name="line-icons:general:check"
                                        variant="primary"
                                        size="md"
                                    />
                                </div>
                            </slot>
                        </div>
                    </slot>
                </div>
            </div>
            <template v-else>
                <div
                    v-if="!isLoadingMore && showEmpty"
                    class="flex select-none justify-between bg-white px-3.5 py-2.5"
                >
                    <span
                        class="overflow-hidden text-ellipsis whitespace-nowrap opacity-50"
                    >
                        {{ $t('general.empty') }}
                    </span>
                </div>
            </template>

            <base-loading
                v-if="isLoadingMore"
                class="justify-center bg-white py-2"
                size="sm"
            />

            <slot name="create" />

            <slot name="after-option" />
        </div>
    </div>
</template>

<script setup>
import { useInfiniteScroll } from '@vueuse/core'

const emit = defineEmits(['selectOption', 'on-load-more'])
const container = ref(null)
const el = ref(null)

const props = defineProps({
    hasInfinityScroll: {
        type: Boolean,
        default: false,
    },

    position: {
        label: String,
        default: 'bottom',
        validator(value) {
            return ['bottom', 'top'].includes(value)
        },
    },

    isLoadingMore: {
        type: Boolean,
        default: false,
    },

    results: {
        type: Array,
        required: true,
    },

    options: {
        type: Array,
        required: true,
    },

    optionValue: {
        type: String,
        default: 'value',
    },

    optionLabel: {
        type: [String, Function],
        default: 'label',
    },

    customIsSelected: {
        type: Function,
        default: null,
    },

    borderedOption: {
        type: Boolean,
        default: false,
    },
    showEmpty: {
        type: Boolean,
        default: true,
    },
})

const state = reactive({
    left: null,
    top: null,
    bottom: null,
    width: null,
})

onMounted(() => {
    setPositionOptionList()
    document.addEventListener('scroll', setPositionOptionList, {
        capture: true,
    })
    window.addEventListener('resize', setPositionOptionList)
})

onBeforeUnmount(() => {
    document.removeEventListener('scroll', setPositionOptionList, {
        capture: true,
    })
    window.removeEventListener('resize', setPositionOptionList)
})

const setPositionOptionList = () => {
    const rect = container.value.getBoundingClientRect()

    state.left = rect.left
    state.top = rect.top
    state.width = rect.width
}

if (props.hasInfinityScroll) {
    useInfiniteScroll(
        el,
        () => {
            emit('on-load-more')
        },
        { distance: 10 }
    )
}

const onClickOption = (option) => {
    emit('selectOption', option)
}

const isSelected = (option) => {
    if (props.customIsSelected) {
        return props.customIsSelected(option)
    }

    return (
        props.results &&
        props.results.some(
            (selection) =>
                selection[props.optionValue] === option[props.optionValue]
        )
    )
}

const getOptionLabel = (val) => {
    if (typeof props.optionLabel === 'function') {
        return props.optionLabel(val)
    }

    return val[props.optionLabel]
}
</script>
