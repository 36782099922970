<template>
    <data-grid
        :data-source="entities.data"
        :columns="columns"
        :loading="loadingSearch"
        :empty-text="$t('transactions.emptyText')"
        :empty-sub="$t('transactions.emptySubText')"
        custom-classes="rounded-none"
        identify-field="id"
        @row-click="onTransactionSelect"
    >
        <template #column-date="{ item }">
            {{ $filters.dateShortFormat(item.date_of_transfer) }}
        </template>

        <template #column-amount="{ item }">
            <span
                class="text-sm font-semibold"
                :class="{
                    'text-danger-600': item.amount < 0,
                    'text-success-600': item.amount > 0,
                }"
            >
                {{
                    $filters.currency(
                        isNegative
                            ? -Math.abs(item.amount)
                            : Math.abs(item.amount),
                        item.currency.iso
                    )
                }}
            </span>
        </template>

        <template #footer>
            <grid-pagination
                :total="entities.last_page"
                :page="entities.current_page"
            />
        </template>
    </data-grid>
</template>

<script setup>
import { useApiFactory } from '@tenant/composables/use-api-factory'
import { TRANSACTION_STATUS_TYPE } from '@tenant/utils/constants'

const { t } = useI18n()

const props = defineProps({
    isNegative: {
        type: Boolean,
        default: false,
    },
    paymentAccountId: {
        type: String,
        required: true,
    },
    queries: {
        type: Object,
        default: () => ({}),
    },
})

const emit = defineEmits(['transaction-select'])

const { entities, useApiSearch } = useApiFactory('transactions')
const { loading: loadingSearch, execute: getTransactions } = useApiSearch({
    payment_account_id: props.paymentAccountId,
    page: 1,
    limit: 5,
    filter: {
        amount: [props.isNegative ? '<' : '>', 0],
        status: ['<>', TRANSACTION_STATUS_TYPE.PAID],
    },
})

const columns = [
    {
        property: 'description',
        label: t('transaction.bookPaymentGrid.columnName'),
    },
    {
        property: 'date',
        label: t('transaction.bookPaymentGrid.columnDate'),
    },
    {
        property: 'amount',
        label: t('transaction.bookPaymentGrid.columnAmount'),
    },
]

onMounted(() => {
    getTransactions()
})

const onTransactionSelect = (item) => {
    emit('transaction-select', item)
}

watch(
    () => props.queries,
    () => {
        getTransactions({
            payment_account_id: props.paymentAccountId,
            page: 1,
            limit: 5,
            filter: props.queries,
        })
    },
    {
        deep: true,
    }
)

watch(
    () => props.paymentAccountId,
    () => {
        getTransactions({
            payment_account_id: props.paymentAccountId,
            page: 1,
            limit: 5,
            filter: props.queries,
        })
    },
    {
        deep: true,
    }
)
</script>
