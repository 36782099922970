<template>
    <div class="relative flex w-1/2 max-w-xs items-end">
        <base-dropdown contentClasses="w-80 p-4">
            <template #btn="{ toggleDropdown }">
                <base-button
                    class="rounded-r-none border-r-0 bg-white p-2.5"
                    variant="default"
                    size="sm"
                    :hasPadding="false"
                    @click="toggleDropdown"
                >
                    <span class="flex items-center">
                        <base-icon
                            name="line-icons:general:search-sm"
                            variant="gray"
                        />
                        <base-icon
                            name="line-icons:arrows:chevron-down"
                            size="sm"
                        />
                    </span>
                </base-button>
            </template>

            <template #default="{ toggleDropdown }">
                <base-dropdown-item
                    v-for="(option, index) in options"
                    :key="option"
                    itemClasses="rounded py-2 px-2.5 hover:bg-primary-600 hover:text-white group"
                    :class="
                        option.label === currentOption.label ? 'bg-gray-50' : ''
                    "
                    @click="
                        () => {
                            onOptionSelect(index), toggleDropdown()
                        }
                    "
                >
                    <span class="flex items-center justify-between">
                        {{ option.label }}

                        <base-icon
                            v-if="option.label === currentOption.label"
                            name="line-icons:general:check"
                            variant="inherit"
                            class="text-primary-700 group-hover:text-white"
                        />
                    </span>
                </base-dropdown-item>
            </template>
        </base-dropdown>

        <form-text-input
            id="searchEntities"
            class="[&_input]:rounded-l-none"
            v-model="searchTerm"
            size="sm"
            :placeholder="
                $t('topBand.searchPlaceholder', {
                    chartOfAccount: currentOption.label,
                })
            "
            :key="currentOption.label"
            @focus="onFocusSearchInput"
        />

        <accountant-partial-search-result
            v-if="showSearchResult"
            :results="results"
            :loading="loading"
            :searchTerm="searchTerm"
            :searchEntity="currentOption.value"
            @hide-search-result="showSearchResult = false"
        />
    </div>
</template>

<script setup>
import { useDebounceFn } from '@vueuse/core'

const { execute, loading } = useApi('/api/accountant/search', 'GET')

const searchTerm = ref('')
const showSearchResult = ref(false)
const options = ref([
    {
        label: 'Clients',
        value: 'clients',
    },
])
const currentOption = ref(options.value[0])

const onOptionSelect = (index) => {
    currentOption.value = options.value[index]
}

const results = ref([])
const onChangeSearchTerm = async (value) => {
    results.value = await execute({
        queries: {
            entity: currentOption.value.value.replace(/-/g, '_'),
            term: value,
        },
    })
}

watch(
    () => searchTerm.value,
    useDebounceFn((value) => {
        if (value.length > 2) {
            onChangeSearchTerm(value)
        } else {
            results.value = []
        }
    }, 500)
)

watch(
    () => currentOption.value,
    () => {
        results.value = []
    }
)

const onFocusSearchInput = () => {
    showSearchResult.value = true
    if (searchTerm.value.length < 3) return
    if (results.value.length) return
    onChangeSearchTerm(searchTerm.value)
}
</script>
