<template>
    <div ref="formControlRef" class="flex w-full flex-col">
        <form-control-label :label="label" />

        <div
            class="relative flex gap-2 rounded-lg border bg-white outline-0 focus:ring-4 focus:ring-offset-0"
            :class="containerClasses"
        >
            <form-control-highlight v-if="highlight" />

            <div v-if="iconName" class="pointer-events-none flex items-center">
                <base-icon :name="iconName" variant="gray" size="md" />
            </div>

            <div
                class="relative flex max-w-full grow flex-wrap items-baseline gap-x-1.5 gap-y-1"
            >
                <form-control-result-list
                    :results="results"
                    :option-value="optionValue"
                    :option-label="optionLabel"
                    @clear-selection="onClearResults"
                />

                <input
                    class="max-w-full grow border-0 p-0 text-md font-normal text-gray-900 placeholder-gray-500 outline-0 focus:ring-0 disabled:cursor-not-allowed disabled:bg-transparent"
                    :class="{ 'text-gray-500': disabled }"
                    v-bind="attrs"
                    v-model="inputValue"
                    :placeholder="placeholder"
                    @focus="openSelection"
                />
            </div>

            <div
                v-if="tooltip && !errorMessage"
                class="pointer-events-none flex items-center"
            >
                <base-icon
                    name="line-icons:general:help-circle"
                    variant="grayLight"
                    size="sm"
                />
            </div>

            <div
                v-if="errorMessage && !disabled"
                class="pointer-events-none flex items-center"
            >
                <base-icon
                    name="line-icons:alerts-&-feedback:alert-circle"
                    variant="danger"
                    size="sm"
                />
            </div>

            <form-control-icon
                v-if="!disabled"
                class="absolute right-2 top-3 text-gray-500"
                :is-multiple="true"
                :is-selected="results && !!results.length"
                :is-open="isOpenSelection"
                @clear-option="onClearResults"
                @open-selection-list="openSelection"
                @close-selection-list="closeSelection"
            />
        </div>

        <slot name="option-list">
            <form-control-option-list
                v-if="isOpenSelection"
                :results="results"
                :options="currentOptions"
                :option-value="optionValue"
                :option-label="optionLabel"
                @select-option="onClickOption"
            >
                <template #option="{ item, index, selected }">
                    <slot name="option" v-bind="{ item, index, selected }" />
                </template>

                <template #create>
                    <slot name="create" />
                </template>
            </form-control-option-list>
        </slot>

        <form-control-hint-text
            :hint-text="hintText"
            :error-message="errorMessage"
        />
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
}
</script>

<script setup>
import { computed, ref, watch, useAttrs, watchEffect } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { useI18n } from 'vue-i18n'
import { useSelector } from '@tenant/composables'

const attrs = useAttrs()
const emit = defineEmits(['update:modelValue'])

const props = defineProps({
    /**
     * Label displayed above the form field
     */
    label: {
        type: String,
        default: '',
    },
    /**
     * If there is no option selected, a placeholder will show
     */
    placeholder: {
        type: String,
        default: () => {
            const { t } = useI18n()

            return t('general.chooseValueLabel')
        },
    },
    /**
     * Short help text that goes below the input field
     */
    hintText: {
        type: String,
        default: '',
    },
    /**
     * A floating, non-actionable label used to explain an input
     */
    tooltip: {
        type: String,
        default: '',
    },
    /**
     * Error message in red shown below the input field when it's destructive
     */
    errorMessage: {
        type: String,
        default: '',
    },
    /**
     * Set the amount of padding in the input
     */
    size: {
        type: String,
        default: 'md',
        validator(value) {
            return ['sm', 'md'].includes(value)
        },
    },
    /**
     * Highlight dot and outline in yellow shown above the input field
     */
    highlight: {
        type: Boolean,
        default: false,
    },
    /**
     * Name of the icon displayed on the left corner of the input
     */
    iconName: {
        type: String,
        default: '',
    },
    /**
     * The options that you can choose from when you click on the input
     */
    options: {
        type: Array,
        default: () => [],
    },
    /**
     * The returned string value is what is internally used within component to validate the following:
     *
     * - Which options are matched to a search term (searchableFields)
     * - Which options are already selected
     */
    optionValue: {
        type: String,
        default: 'value',
    },
    /**
     * The returned string value is the string that's rendered:
     *
     * - As the label for options in the menu
     * - As the label for value(s) in the control.
     */
    optionLabel: {
        type: String,
        default: 'label',
    },
    /**
     * Option property that can be search
     */
    searchableFields: {
        type: Array,
        default: () => ['value', 'label'],
    },
    /**
     * Component model value
     */
    modelValue: {
        type: Array,
        default: () => [],
    },
})

const isOpenSelection = ref(false)
const inputValue = ref('')
const formControlRef = ref(null)
const internalOptions = computed(() => props.options)

const {
    results,
    currentOptions,
    addResultItem,
    removeResultItem,
    replaceResults,
    isSelected,
} = useSelector(
    internalOptions,
    props.searchableFields,
    props.optionValue,
    inputValue,
    true
)

const { disabled } = attrs
const containerClasses = computed(() => {
    const { size, errorMessage, highlight } = props

    return {
        'px-3 py-2 pr-8': size === 'sm',
        'px-3.5 py-2.5 pr-8': size === 'md',
        'border-gray-300': !errorMessage,
        'ring-4 ring-offset-0': isOpenSelection.value,
        'border-primary-300 ring-primary-100':
            isOpenSelection.value && !errorMessage,
        'border-danger-300': errorMessage && !disabled,
        'border-danger-300 ring-danger-100':
            isOpenSelection.value && errorMessage && !disabled,
        'border-gray-300 bg-gray-50 cursor-not-allowed': disabled,
        'border-warning-300 focus:border-warning-300 ring-4 ring-warning-100 focus:ring-warning-100':
            highlight,
    }
})

watchEffect(() => {
    const results = props.modelValue.map((resultItem) => {
        return internalOptions.value.find(
            (options) => options[props.optionValue] === resultItem
        )
    })

    replaceResults(results.filter(Boolean))
})

watch(
    results,
    (results) => {
        const resultValues = results.map(
            (resultItem) => resultItem[props.optionValue]
        )

        if (props.modelValue.length === resultValues.length) {
            let isEqual = true
            for (let i = 0; i < props.modelValue.length; i++) {
                if (!resultValues.includes(props.modelValue[i])) {
                    isEqual = false
                    break
                }
            }

            if (isEqual) {
                return
            }
        }

        emit('update:modelValue', resultValues)
    },
    { deep: true }
)

const onClickOption = (option) => {
    inputValue.value = ''

    if (isSelected(option)) {
        onClearResults(option)
    } else {
        addResultItem(option)
        closeSelection()
    }
}

const onClearResults = (option) => {
    if (disabled) {
        return
    }

    removeResultItem(option)
    closeSelection()
}

const openSelection = () => {
    isOpenSelection.value = true
}

const closeSelection = () => {
    isOpenSelection.value = false
}

onClickOutside(formControlRef, closeSelection)
</script>
