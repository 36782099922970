<template>
    <div class="user-select-none flex items-center">
        <base-icon
            v-if="isSelected && !isMultiple"
            name="line-icons:general:x-close"
            variant="inherit"
            class="cursor-pointer"
            @click="onClearOption"
        />
        <template v-else>
            <base-icon
                v-if="isOpen"
                name="line-icons:arrows:chevron-up"
                variant="inherit"
                class="cursor-pointer"
                @click="onCloseSelectionList"
            />
            <base-icon
                v-else
                name="line-icons:arrows:chevron-down"
                variant="inherit"
                class="cursor-pointer"
                @click="onOpenSelectionList"
            />
        </template>
    </div>
</template>

<script setup>
const emit = defineEmits([
    'clearOption',
    'openSelectionList',
    'closeSelectionList',
])

defineProps({
    isMultiple: {
        type: Boolean,
        default: false,
    },

    isSelected: {
        type: Boolean,
    },

    isOpen: {
        type: Boolean,
    },
})

const onClearOption = (option) => {
    emit('clearOption', option)
}

const onOpenSelectionList = (option) => {
    emit('openSelectionList', option)
}

const onCloseSelectionList = (option) => {
    emit('closeSelectionList', option)
}
</script>
