<template>
    <div>
        <div
            ref="dropzoneRef"
            class="mb-4 w-full cursor-pointer rounded-lg border border-gray-200 px-6 py-4"
            :class="[{ 'pointer-events-none': loading }, containerClass]"
        >
            <div
                class="pointer-events-none relative flex flex-col items-center justify-center"
            >
                <span
                    class="mb-4 flex items-center justify-center rounded-full bg-gray-100 p-2 text-gray-600 ring-8 ring-gray-50"
                >
                    <div
                        v-if="loading"
                        class="absolute bottom-0 left-0 right-0 top-0 z-50 bg-white opacity-50"
                    >
                        <span
                            class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
                        >
                            <base-loading size="lg" />
                        </span>
                    </div>
                    <base-icon
                        name="line-icons:general:upload-cloud-02"
                        size="lg"
                        variant="inherit"
                    />
                </span>
                <i18n-t
                    keypath="fileUpload.uploadLabel"
                    tag="span"
                    class="mb-1 text-sm text-gray-500"
                >
                    <span class="text-link font-medium">
                        {{ $t('fileUpload.clickToUploadLabel') }}
                    </span>
                </i18n-t>
                <span class="text-xs text-gray-500" v-if="description">
                    {{ description }}
                </span>
            </div>
        </div>

        <div
            v-for="uploadFile in uploadFiles"
            :key="uploadFile.upload.uuid"
            class="mb-3"
        >
            <div
                v-if="uploadFile.accepted"
                class="flex flex-col rounded-lg border border-gray-200 p-4"
            >
                <div class="flex justify-between">
                    <div class="flex flex-col">
                        <span class="text-sm font-medium text-gray-700">
                            {{ uploadFile.name }}
                        </span>
                        <span class="text-sm text-gray-500">
                            {{ $filters.humanFileSize(uploadFile.size) }}
                        </span>
                    </div>
                    <base-icon
                        v-if="allowRemove"
                        name="line-icons:general:trash-01"
                        size="md"
                        variant="gray"
                        class="cursor-pointer"
                        @click="onRemoveFile(uploadFile)"
                    />
                </div>
            </div>
            <div
                v-else
                class="flex flex-col rounded-lg border border-danger-300 bg-danger-25 p-4"
            >
                <div class="flex justify-between">
                    <div class="flex flex-col">
                        <span class="text-sm font-medium text-danger-700">
                            {{ $t('fileUpload.uploadFailedLabel') }}
                        </span>
                        <span class="text-sm font-normal text-danger-600">
                            {{ uploadFile.name }}
                        </span>
                    </div>
                    <base-icon
                        v-if="allowRemove"
                        name="line-icons:general:trash-01"
                        size="md"
                        variant="danger"
                        class="cursor-pointer"
                        @click="onRemoveFile(uploadFile)"
                    />
                </div>
            </div>
        </div>

        <div
            v-if="errorMessage"
            class="flex flex-col rounded-lg border border-danger-300 bg-danger-25 p-4"
        >
            <div class="flex justify-between">
                <div class="flex flex-col">
                    <span class="text-sm font-medium text-danger-700">
                        {{ errorMessage }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref } from 'vue'
import Dropzone from 'dropzone'
import { sameOrigin } from '@tenant/utils/helper'
import axios from 'axios'
import coreAxios from '@tenant/core/init/axios'

const emit = defineEmits([
    'update:modelValue',
    'onRemoveFile',
    'onChangeFiles',
    'onUploadFiles',
])

const props = defineProps({
    containerClass: {
        type: String,
        default: null,
    },
    modelValue: {
        type: [Array, File, Object],
        default: () => [],
    },
    errorMessage: {
        type: String,
        default: null,
    },
    // ['.svg', '.jpeg'...]
    acceptedFiles: {
        type: Array,
        default: () => [],
    },
    maxFiles: {
        type: Number,
        required: false,
        default: null,
    },
    maxFilesize: {
        type: Number,
        required: false,
        default: 10, // 10MB
    },
    description: {
        type: String,
        default: '',
    },
    allowRemove: {
        type: Boolean,
        required: false,
        default: true,
    },
    customPath: {
        type: Function,
        required: false,
        default: null,
    },
    customName: {
        type: Function,
        required: false,
        default: null,
    },
})

const dropzoneRef = ref(null)
const uploadFiles = ref([])
const loading = ref(false)
let dropzoneInstance = null

onMounted(() => {
    dropzoneInstance = new Dropzone(dropzoneRef.value, {
        url: '#',
        autoProcessQueue: false,
        maxFiles: props.maxFiles,
        previewTemplate: '<div></div>',
        acceptedFiles: props.acceptedFiles.toString(),
        maxFilesize: props.maxFilesize,
    })

    dropzoneInstance.on('addedfile', function (file) {
        uploadFiles.value.push(file)
    })

    dropzoneInstance.on('addedfiles', function (uploadFiles) {
        const files = []
        for (const uploadFile of uploadFiles) {
            files.push(uploadFile)
        }

        emit(
            'onUploadFiles',
            files.filter((file) => file.accepted)
        )
        emitModelValue()
    })

    dropzoneInstance.on('removedfile', (file) => {
        uploadFiles.value = uploadFiles.value.filter(
            (uploadFile) => uploadFile.upload.uuid !== file.upload.uuid
        )

        if (!file.accepted) {
            return
        }

        emit('onRemoveFile', file)
        emitModelValue()
    })
})

onBeforeUnmount(() => {
    dropzoneInstance.removeEventListeners()
})

onBeforeUnmount(() => {
    dropzoneInstance.off('addedfile')
    dropzoneInstance.off('addedfiles')
    dropzoneInstance.off('removedfile')
})

watch(
    () => props.modelValue,
    (files) => {
        if (!files) {
            return
        }

        initialModel(files)
    }
)

onMounted(() => {
    const files = props.modelValue
    if (!files) {
        return
    }

    initialModel(files)
})

const initialModel = async (initialFile) => {
    try {
        loading.value = true
        const files = Array.isArray(initialFile) ? initialFile : [initialFile]

        await Promise.all(
            files.map(async (file) => {
                if (file instanceof File) {
                    return
                }

                const path = props.customPath(file)
                let content
                if (sameOrigin(path, window.location)) {
                    content = await coreAxios.get(props.customPath(file), {
                        responseType: 'blob',
                    })
                } else {
                    content = await axios
                        .get(props.customPath(file), {
                            responseType: 'blob',
                        })
                        .then((data) => data.data)
                }

                const dropzoneFile = new File(
                    [content],
                    props.customName(file),
                    {
                        type: content.type,
                    }
                )

                dropzoneFile.__proto__.fileId = file.uuid
                dropzoneInstance.addFile(dropzoneFile)

                emitModelValue()
            })
        )
    } finally {
        loading.value = false
    }
}

const onRemoveFile = (uploadFile) => {
    dropzoneInstance.removeFile(uploadFile)
}

const emitModelValue = () => {
    const emitFiles = uploadFiles.value.reduce((uploadFiles, uploadFile) => {
        if (!uploadFile.accepted) {
            return uploadFiles
        }

        return [...uploadFiles, uploadFile]
    }, [])

    const maxFiles = props.maxFiles

    const emitData = maxFiles === 1 ? emitFiles[0] : emitFiles
    emit('update:modelValue', emitData)
    emit('onChangeFiles', emitData)
}
</script>
