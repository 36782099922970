<template>
    <div class="flex items-center justify-center pb-10">
        <template v-for="(step, index) in steps" :key="index">
            <div class="relative">
                <div
                    v-if="index < active - 1"
                    class="rounded-full bg-primary-600 text-white"
                    :class="doneDotClasses"
                >
                    <base-icon
                        name="line-icons:general:check"
                        variant="inherit"
                        :size="size"
                    />
                </div>
                <div
                    v-else
                    class="rounded-full bg-white"
                    :class="[
                        dotClasses,
                        index <= active - 1
                            ? 'border-primary-600 ring-4 ring-primary-100'
                            : 'border-gray-100',
                    ]"
                ></div>

                <div
                    class="absolute left-1/2 top-full -translate-x-1/2 whitespace-nowrap pt-3 text-center"
                    :class="textClasses"
                >
                    <div
                        v-if="step.name"
                        class="font-medium"
                        :class="
                            index === active - 1
                                ? 'text-primary-700'
                                : 'text-gray-700'
                        "
                    >
                        {{ step.name }}
                    </div>
                    <div
                        v-if="step.description"
                        :class="
                            index == active - 1
                                ? 'text-primary-600'
                                : 'text-gray-500'
                        "
                    >
                        {{ step.description }}
                    </div>
                </div>
            </div>

            <!-- Divider -->
            <div
                v-if="!(index == steps.length - 1)"
                class="h-0.5 max-w-sm flex-grow"
                :class="index < active - 1 ? 'bg-primary-600' : 'bg-gray-200'"
            ></div>
        </template>
    </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
    steps: {
        type: Array,
        required: true,
    },

    size: {
        type: String,
        default: 'sm',
        validator(value) {
            return ['sm', 'md', 'lg'].includes(value)
        },
    },

    active: {
        type: Number,
        default: 1,
    },
})

const dotClasses = computed(() => {
    const classes = {
        'h-6 w-6 border-8': props.size === 'sm',
        'h-8 w-8 border-[10px]': props.size === 'md',
        'h-10 w-10 border-[12px]': props.size === 'lg',
    }

    return classes
})

const doneDotClasses = computed(() => {
    const classes = {
        'p-1': props.size === 'sm',
        'p-1.5': props.size === 'md',
        'p-2': props.size === 'lg',
    }

    return classes
})

const textClasses = computed(() => {
    const classes = {
        'text-sm': props.size === 'sm',
        'text-md': props.size === 'md' || props.size === 'lg',
    }

    return classes
})
</script>
