<template>
    <form-single-select
        v-model="value"
        v-bind="$attrs"
        :options="accounts"
        option-value="id"
        option-label="name"
        :searchable-fields="['name']"
    >
        <template #label="{ ...label }">
            <slot name="label" v-bind="label" />
        </template>

        <template #option="{ item, selected }">
            <div
                v-if="item?.headline"
                class="bg-white px-3 py-2 text-sm font-semibold text-gray-900"
                @click.stop
            >
                {{ item.name }}
            </div>
            <div
                v-else
                class="cursor-pointer bg-white px-3 text-sm font-normal text-gray-600"
            >
                <div
                    class="rounded-lg px-4 py-2 hover:bg-gray-50"
                    :class="{
                        'bg-primary-600 text-white hover:bg-primary-500':
                            selected,
                    }"
                >
                    {{ item.name }}
                </div>
            </div>
        </template>

        <template #badge>
            <base-badge
                v-if="isShowBadge"
                :label="$t('general.recommended')"
                class="gap-2 !p-0 !px-2"
                variant="success"
            />
        </template>

        <template v-if="canCreate" #create>
            <div
                class="flex cursor-pointer select-none items-center gap-2 bg-primary-50 px-3.5 py-3 text-gray-900 hover:bg-primary-100"
                @click="onShowAddModal"
            >
                <base-icon name="line-icons:general:plus-circle" />
                {{ $t('general.createNewAccount') }}
            </div>

            <bookeeping-accounts-add-modal
                v-if="includeModal"
                :show="state.showAddModal"
                @modal-close="onCloseAddModal"
                @on-finished="onFinished"
            />
        </template>
    </form-single-select>
</template>

<script setup>
const emit = defineEmits(['update:modelValue', 'open-modal'])

const props = defineProps({
    modelValue: {
        type: [String, Number],
        default: '',
    },

    vectorCoa: {
        type: Object,
        default: null,
    },
    includeModal: {
        type: Boolean,
        default: true,
    },
    canCreate: {
        type: Boolean,
        default: true,
    },
    excludedTypes: {
        type: Array,
        default: () => [],
    },
    only: {
        type: Boolean,
        default: false,
    },
    type: {
        type: String,
        default: 'income',
        validate: (value) => ['income', 'expense'].includes(value),
    },
})

const state = reactive({
    showAddModal: false,
})

const value = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
    },
})

const { chartOfAccounts, invalidate } = useFlatChartOfAccounts(
    2,
    props.type,
    props.only
)
const accounts = computed(() =>
    chartOfAccounts.value?.filter((item) => {
        if (item.headline) return true
        return !props.excludedTypes.includes(getType(item)?.slug)
    })
)

const onShowAddModal = () => {
    if (props.includeModal) {
        state.showAddModal = true
    } else {
        emit('open-modal', true)
    }
}

const onCloseAddModal = () => {
    state.showAddModal = false
}

const onFinished = (chartOfAccount) => {
    onCloseAddModal()
    invalidate()
    emit('update:modelValue', chartOfAccount.id, chartOfAccount)
}

const isShowBadge = computed(() => {
    if (!props.vectorCoa) {
        return false
    }

    const mappedVectorCoa = accounts.value.find(
        (coa) => coa.id === props.vectorCoa.id
    )

    return (
        props.modelValue !== '' &&
        props.vectorCoa &&
        props.modelValue === props.vectorCoa.id &&
        mappedVectorCoa
    )
})

const getType = (item) => {
    return item.ancestors.find((i) => i.parent_id === null)
}
</script>
