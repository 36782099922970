<template>
    <div>
        <data-grid
            :data-source="invoices.data"
            :columns="columns"
            :loading="loadingSearch"
            :empty-text="$t('invoices.emptyText')"
            :empty-sub="$t('invoices.emptySubText')"
            identify-field="id"
            @row-click="redirectToDetail"
            v-bind="attrs"
        >
            <template v-if="$slots['header']" #header>
                <slot name="header" />
            </template>

            <template v-if="$slots['filter']" #filter>
                <slot name="filter" />
            </template>

            <template #column-status="{ item }">
                <partial-status
                    :status="
                        getInvoiceStatus(
                            item.sale_document.status,
                            item.due_date
                        )
                    "
                    variant="small"
                >
                    <template #text>{{ getStatusText(item) }}</template>
                </partial-status>
            </template>

            <template #column-dueDate="{ item }">
                <span
                    :class="{
                        'line-through':
                            item.sale_document.status === INVOICE_STATUS.PAID,
                        'text-danger-600': [
                            INVOICE_STATUS.DUE,
                            INVOICE_STATUS.PART_PAID,
                        ].includes(
                            getInvoiceStatus(
                                item.sale_document.status,
                                item.due_date
                            )
                        ),
                    }"
                >
                    {{ $filters.dateHumanFormat(item.due_date) }}
                </span>
            </template>

            <template #column-customer="{ item }">
                <div class="flex flex-col">
                    <div
                        class="whitespace-nowrap text-sm font-normal text-gray-500"
                    >
                        <slot name="title">{{
                            getContactName(item.sale_document.contact)
                        }}</slot>
                    </div>
                </div>
            </template>

            <template #column-date="{ item }">
                {{ $filters.dateHumanFormat(item.date_of_invoice) }}
            </template>

            <template #column-number="{ item }">
                <span class="text-primary-600">
                    {{ item.sale_document.number }}
                </span>
            </template>

            <template #column-amountNet="{ item }">
                {{
                    $filters.currency(
                        item.sale_document.amount_net_total,
                        item.sale_document?.currency?.iso ?? invoiceCurrency
                    )
                }}
            </template>

            <template #column-amountGross="{ item }">
                {{
                    $filters.currency(
                        item.sale_document.amount_gross_total,
                        item.sale_document?.currency?.iso ?? invoiceCurrency
                    )
                }}
            </template>

            <template #column-unpaidGross="{ item }">
                {{
                    $filters.currency(
                        item.sale_document.unpaid_amount,
                        item.sale_document?.currency?.iso ?? invoiceCurrency
                    )
                }}
            </template>

            <template #column-action="{ item }">
                <invoices-context-menu
                    :item="item"
                    @delete-invoice="openDeleteModal(item)"
                    @download-invoice="downloadInvoice(item)"
                    @copy-invoice="copyInvoice(item)"
                />
            </template>

            <template #footer>
                <grid-pagination
                    :total="invoices.last_page"
                    :page="invoices.current_page"
                    :replace-query="replaceQuery"
                    @page-change="onPageChange"
                />
            </template>

            <template #empty v-if="$slots['empty']">
                <slot name="empty" />
            </template>

            <template v-if="!hasFilter" #empty-actions>
                <slot name="empty-actions">
                    <base-button
                        v-if="$acl.can('create_invoices')"
                        size="md"
                        variant="primary"
                        icon="line-icons:general:plus"
                        @click="redirectToCreate"
                    >
                        {{ $t('invoices.list.add') }}
                    </base-button>
                </slot>
            </template>
        </data-grid>

        <invoices-delete-modal
            v-if="invoice"
            :invoice-id="invoice.id"
            :loading="isDeleting"
            :show="showDeleteModal"
            @modal-close="showDeleteModal = false"
            @modal-confirm="onDeleteInvoice"
        />
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
}
</script>

<script setup>
import { CONTACT_RELATIONSHIP_TYPES, CURRENCIES } from '@tenant/utils/constants'
import { useInvoice } from '@tenant/modules/tenant/invoices/composables/use-invoice'
import { INVOICE_STATUS } from '@tenant/modules/tenant/invoices/utils/constants'
import { exportPDF } from '@tenant/utils/helper'

const attrs = useAttrs()

const { t } = useI18n()
const router = useRouter()

const emit = defineEmits(['invoice-total'])
const props = defineProps({
    params: {
        type: Object,
        default: () => ({}),
    },
    replaceQuery: {
        type: Boolean,
        default: true,
    },
    gridColumns: {
        type: Array,
        default: null,
    },
})

const {
    entities: invoices,
    useApiSearch,
    useApiDelete,
    setEntity,
    entity: invoice,
} = useApiFactory('sale-documents/invoices')
const {
    execute: searchInvoice,
    loading: loadingSearch,
    executeRefreshSearch: refreshInvoices,
} = useApiSearch({ ...props.params }, false)

const state = reactive({
    page: 1,
})

onMounted(() => {
    loadData()
})

watch(
    () => invoices.value.total,
    () => emit('invoice-total', invoices.value.total)
)

const invoiceCurrency = CURRENCIES.eur.iso

const columns = computed(() => {
    return (
        props.gridColumns || [
            {
                property: 'date',
                label: t('invoices.list.date'),
                headerCellClass: 'hidden xl:table-cell',
                dataCellClass: 'hidden xl:table-cell',
            },
            {
                property: 'number',
                label: t('invoices.list.number'),
                headerCellClass: 'hidden xl:table-cell',
                dataCellClass: 'hidden xl:table-cell',
            },
            {
                // TODO: AC-950
                property: 'orderNo',
                label: t('invoices.list.orderNo'),
                headerCellClass: 'hidden xl:table-cell',
                dataCellClass: 'hidden xl:table-cell',
            },
            {
                property: 'customer',
                label: t('invoices.list.customer'),
                headerCellClass: 'hidden xl:table-cell',
                dataCellClass: 'hidden xl:table-cell',
            },
            {
                property: 'status',
                label: t('invoices.list.status'),
            },
            {
                property: 'dueDate',
                label: t('invoices.list.dueDate'),
                headerCellClass: 'hidden xl:table-cell',
                dataCellClass: 'hidden xl:table-cell',
            },
            {
                property: 'amountGross',
                label: t('invoices.list.amountGross'),
                headerCellClass: 'hidden xl:table-cell',
                dataCellClass: 'hidden xl:table-cell',
            },
            {
                property: 'unpaidGross',
                label: t('invoices.list.unpaidGross'),
                headerCellClass: 'hidden xl:table-cell',
                dataCellClass: 'hidden xl:table-cell',
            },
        ]
    )
})

watch(
    () => props.params,
    () => {
        loadData()
    }
)

const loadData = async () => {
    await searchInvoice({
        page: state.page,
        ...props.params,
    })
    emit('invoice-total', invoices.value.total)
}

const onPageChange = (page) => {
    state.page = page

    return loadData()
}

const redirectToCreate = () => {
    router.push({ name: 'invoices.create' })
}

const getContactName = (contact) => {
    if (!contact) return null

    return contact.type === CONTACT_RELATIONSHIP_TYPES.person
        ? `${contact.first_name} ${contact.last_name}`
        : contact.company
}

// DELETE INVOICE
const showDeleteModal = ref(false)
const isDeleting = ref(false)
const { execute: deleteInvoice } = useApiDelete()
const { errorNotify, successNotify } = useNotification()

const onDeleteInvoice = async (id) => {
    try {
        isDeleting.value = true
        await deleteInvoice(id)
        await refreshInvoices()
    } catch ({ hasErrors, errorMessage }) {
        if (hasErrors) {
            errorNotify({
                title: errorMessage,
            })
        }
    } finally {
        isDeleting.value = false
        showDeleteModal.value = false
    }
}

const openDeleteModal = (item) => {
    setEntity(item)
    showDeleteModal.value = true
}
// END: DELETE INVOICE

// DOWNLOAD INVOICE
const { getInvoiceStatus, getStatusText } = useInvoice()
const { execute } = useApi(
    '/api/sale-documents/invoices/{id}/generate-pdf',
    'POST'
)
const downloadInvoice = async (item) => {
    try {
        const { content } = await execute({
            params: {
                id: item.id,
            },
        })
        exportPDF(`${item.invoice_header}.pdf`, content)
    } catch ({ hasErrors, errorMessage }) {
        if (hasErrors) {
            errorNotify({
                title: errorMessage,
            })
        }
    }
}
// END: DOWNLOAD INVOICE

// PRE-FETCH CONTACTS FOR FILTER PANEL
import { QueryClient } from '@tanstack/vue-query'
import { useAttrs } from 'vue'
const { execute: executeSearchContacts } = useApiFactory(
    'contacts'
).useApiSearch({}, false)

const queryClient = new QueryClient()
queryClient.prefetchQuery({
    queryKey: ['contacts'],
    queryFn: () =>
        executeSearchContacts({
            queries: { page: 1, limit: 500 },
        }),
    staleTime: 60 * 1000,
})
// END: PRE-FETCH CONTACTS FOR FILTER PANEL

const emitter = useEmitter()
const { execute: executeCopyInvoice } = useApi(
    '/api/sale-documents/invoices/{id}/copy',
    'POST'
)

// COPY INVOICE
const copyInvoice = async ({ ...item }) => {
    try {
        emitter.emit('set-loading', true)

        await executeCopyInvoice({
            params: {
                id: item.id,
            },
        })
        await refreshInvoices()

        successNotify({
            title: t('invoices.general.copySuccess'),
        })
    } catch ({ hasErrors, errorMessage }) {
        if (hasErrors) {
            errorNotify({
                title: errorMessage,
            })
        }
    } finally {
        emitter.emit('set-loading', false)
    }
}
// END: COPY INVOICE

const route = useRoute()
const hasFilter = computed(() => route.query.q || route.query.filter)

const redirectToDetail = (id, { sale_document }) => {
    if (sale_document.status === INVOICE_STATUS.REPEATING) {
        router.push({ name: 'invoices.repeating.edit', params: { id } })
    } else {
        router.push({ name: 'invoices.dispatch', params: { id } })
    }
}
</script>
