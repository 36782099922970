<template>
    <div
        class="grid cursor-pointer grid-cols-3 gap-3 rounded-lg bg-gray-50 px-3 py-2 hover:!bg-primary-50"
    >
        <div class="col-span-2 text-left text-sm font-normal text-gray-700">
            {{ item.name }}
        </div>

        <div class="col-span-1 text-right text-sm font-normal text-black">
            {{ $filters.symbolCurrency(item.closing_balance, item.currency) }}
        </div>

        <div class="col-span-3 flex justify-between">
            <div class="text-left text-xs font-normal text-primary-600">
                {{ getType(item)?.name }}
                <span
                    v-if="getDetailType(item)?.name"
                    class="text-xs font-normal text-gray-500"
                >
                    | {{ getDetailType(item)?.name }}
                </span>
            </div>
        </div>
    </div>
</template>
<script setup>
defineProps({
    item: {
        type: Object,
        required: true,
    },
})

const getType = (item) => {
    return item.ancestors.find((i) => i.parent_id === null)
}

const getDetailType = (item) => {
    return item.ancestors.find((i) => i.parent_id !== null)
}
</script>
