<template>
    <li>
        <a
            class="group mb-1 block cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap px-4"
            @click="onClickRootMenu"
        >
            <base-tooltip
                class="!m-0 w-full !border-none"
                :class="{ '[&>.popper]:hidden': !isCollapsed }"
                dark
                hover
                :content="$t(item.label)"
                placement="right"
            >
                <partial-sidebar-item-main
                    :class="
                        isActive
                            ? 'text-primary-700'
                            : ' text-gray-700 group-hover:text-primary-700'
                    "
                    :name="item.path"
                    :is-active="isActive"
                    :has-children="hasChildren"
                    :is-collapsed="isCollapsed"
                >
                    <base-icon
                        v-if="item.icon"
                        :class="[
                            isActive ? 'text-primary-500' : 'text-gray-500',
                            {
                                'group-hover:text-primary-500':
                                    isCollapsed && isActive,
                            },
                            {
                                'group-hover:text-gray-700':
                                    isCollapsed && !isActive,
                            },
                            {
                                'mr-3 group-hover:text-primary-500':
                                    !isCollapsed,
                            },
                        ]"
                        :name="item.icon"
                        variant="inherit"
                        size="lg"
                    />
                    <span
                        v-if="item.label && !isCollapsed"
                        class="flex-1 text-md"
                    >
                        {{ $t(item.label) }}
                    </span>
                    <base-icon
                        v-if="hasChildren && !isCollapsed"
                        variant="inherit"
                        :name="
                            show || isActive
                                ? 'line-icons:arrows:chevron-up'
                                : 'line-icons:arrows:chevron-down'
                        "
                    />
                </partial-sidebar-item-main>
            </base-tooltip>
        </a>

        <transition
            v-if="hasChildren"
            name="expand"
            @enter="onExpandEnter"
            @afterEnter="onExpandAfterEnter"
            @beforeLeave="onExpandBeforeLeave"
        >
            <ul
                v-if="(show || isActive) && !isCollapsed"
                :aria-expanded="(show || isActive) && !isCollapsed"
            >
                <li class="pt-1" />
                <template v-for="(child, index) of item.children" :key="index">
                    <li
                        v-if="
                            child?.permission
                                ? $acl.can(child.permission)
                                : true
                        "
                        class="relative px-4 pb-1 text-gray-700"
                    >
                        <router-link
                            v-if="child.path"
                            class="flex cursor-pointer items-center gap-2 overflow-hidden text-ellipsis whitespace-nowrap rounded-md py-2 pl-12 text-md hover:bg-primary-50 hover:text-primary-700"
                            active-class="bg-primary-50 hover:!bg-primary-100 text-primary-700"
                            :to="{ name: child.path }"
                        >
                            <component
                                v-if="child.component"
                                :is="child.component"
                            />
                            <template v-else>
                                <base-icon
                                    v-if="child.icon"
                                    :name="child.icon"
                                />
                                {{ $t(child.label) }}
                            </template>
                        </router-link>
                        <template v-else>
                            <div
                                class="flex cursor-pointer items-center gap-2 overflow-hidden text-ellipsis whitespace-nowrap rounded-md bg-primary-50 py-2 pl-12 text-md text-primary-700 hover:!bg-primary-100 hover:text-primary-700"
                            >
                                <base-icon
                                    v-if="child.icon"
                                    :name="child.icon"
                                />
                                {{ $t(child.label) }}
                            </div>
                        </template>
                    </li>
                </template>
            </ul>
        </transition>
    </li>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { intersection } from 'lodash-es'

const route = useRoute()
const router = useRouter()
const emit = defineEmits(['open-sidebar'])

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },

    isCollapsed: {
        type: Boolean,
        default: false,
    },
})

const hasChildren = computed(
    () => props.item.children && props.item.children.length > 0
)

const isActive = computed(() => {
    const matchedRoutes = router.currentRoute.value.matched.map(
        (route) => route.name
    )

    if (hasChildren.value) {
        const menuRoutes = props.item.children.map((child) => child.path)
        return intersection(matchedRoutes, menuRoutes).length > 0
    }

    return intersection(matchedRoutes, [props.item.path]).length > 0
})

const show = ref(false)

onMounted(async () => {
    if (!hasChildren.value) {
        return
    }

    await router.isReady()

    show.value = props.item.children.some((item) => {
        return route.name.includes(item.path)
    })
})

const onClickRootMenu = () => {
    if (!hasChildren.value) {
        return
    }

    show.value = props.isCollapsed ? true : !show.value
    emit('open-sidebar')
}

const onExpandEnter = (el) => {
    el.style.height = el.scrollHeight + 'px'
}

const onExpandAfterEnter = (el) => {
    el.style.height = 'auto'
}

const onExpandBeforeLeave = (el) => {
    el.style.height = el.scrollHeight + 'px'
}
</script>
