<template>
    <div
        class="chat-content flex grow flex-col gap-4 overflow-auto break-words"
        :class="{ 'scroll-smooth': initialize }"
        ref="chatContent"
    >
        <div v-if="isFetching" class="flex justify-center">
            <base-loading />
        </div>

        <template v-for="(item, index) in data" :key="item.id">
            <partial-chat-devider
                v-if="isDifferenceDayMessage(item, data[index - 1])"
                :item="item"
            />

            <partial-chat-answer
                v-if="item.user_id === centralUser.id"
                :item="item"
                :full-width="fullWidth"
            />

            <partial-chat-question
                v-else
                :item="item"
                :full-width="fullWidth"
            />
        </template>

        <partial-chat-typing v-if="typingUser" :user="typingUser" />
        <span class="chat-content-anchor"></span>
    </div>
</template>

<script setup>
import receiveSound from '@tenant/assets/audio/receiveMessage.mp3'
import sendSound from '@tenant/assets/audio/sendMessage.mp3'
import { isDifferenceDayMessage } from '@tenant/utils/helper'
import { ECHO_EVENTS } from '@tenant/utils/constants'

const audioSend = new Audio(sendSound)
const audioReceive = new Audio(receiveSound)
const emitter = useEmitter()
const { centralUser } = useAuth()

const typingUser = ref(null)
let typingTimeout = null

const props = defineProps({
    room: {
        type: Object,
        required: true,
    },
    reference: {
        type: String,
        default: null,
    },
    fullWidth: {
        type: Boolean,
        default: false,
    },
})

const { newMessage, updateConversation } = useConversation()
const {
    data,
    initialize,
    chatContent,
    isFetching,
    isBottomContent,
    scrollContentToBottom,
} = useMessages(props.room, props.reference)

onMounted(() => {
    emitter.on('sent-message', onSentMessage)
    emitter.on('subscription-sent-message', onSubscriptionSentMessage)
})

onUnmounted(() => {
    emitter.off('sent-message', onSentMessage)
    emitter.off('subscription-sent-message', onSubscriptionSentMessage)
})

useListenForWhisper(`chat.room.${props.room.id}`, 'typing', (user) => {
    if (typingTimeout) {
        clearTimeout(typingTimeout)
    }

    if (isBottomContent()) {
        scrollContentToBottom()
    }

    typingUser.value = user
    typingTimeout = setTimeout(() => {
        typingUser.value = null
    }, 2000)
})

useListenEmitEcho(ECHO_EVENTS.CHAT_MESSAGE, ({ message }) => {
    if (message.chat_room_id !== props.room.id) {
        return
    }

    typingUser.value = null
    newMessage(props.room, message)
    audioReceive.play()

    emitter.emit('on-new-message', {
        room_id: props.room.id,
        message,
    })

    if (isBottomContent()) {
        scrollContentToBottom()
    }
})

const onSentMessage = ({ room_id, message }) => {
    if (room_id !== props.room.id) {
        return
    }

    emitter.emit('on-new-message', {
        room_id: props.room.id,
        message,
    })
    audioSend.play()
    scrollContentToBottom()
    updateConversation({
        ...props.room,
        latest_message: message,
    })
}

const onSubscriptionSentMessage = ({ room_id }) => {
    if (room_id !== props.room.id) {
        return
    }

    scrollContentToBottom()
}
</script>
