<template>
    <base-tabs>
        <template #tabs="{ items, tabClasses, tabActiveClasses }">
            <div
                v-for="(item, index) in items"
                :key="index"
                :class="[
                    tabClasses,
                    activeTab === item.name ? tabActiveClasses : '',
                ]"
                class="mr-2 flex min-w-fit flex-1 cursor-pointer items-center justify-center px-3 py-2 font-medium text-gray-500 transition duration-150"
                @click="onChangeTab(item)"
            >
                {{ item.label }}
                <span
                    class="w-min-6 ml-2 flex items-center rounded-full bg-gray-100 px-[9px] py-0.5 text-center text-xs"
                    v-if="item.badgeContent || item.iconName"
                >
                    <base-icon
                        class="mx-1"
                        v-if="item.iconName"
                        :name="item.iconName"
                    />
                    {{ item.badgeContent }}
                </span>
            </div>
        </template>

        <template #content>
            <slot name="content" v-bind="{ activeTab }" />
        </template>
    </base-tabs>
</template>

<script setup>
const props = defineProps({
    default: {
        type: String,
        default: '',
    },
})

const activeTab = ref(props.default)

const emit = defineEmits(['change-tab'])

const onChangeTab = (tab) => {
    activeTab.value = tab.name
    emit('change-tab', tab)
}
</script>
