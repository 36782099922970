<template>
    <div class="flex w-80 flex-col border-r">
        <accountant-inbox-conversations :client="client" />
    </div>

    <base-loading
        v-if="loading"
        class="flex flex-1 items-center justify-center"
    />
    <template v-else-if="result">
        <div class="flex grow flex-col border-r">
            <accountant-inbox-chat-window
                v-if="roomId"
                :room="result"
                :key="roomId"
            />
            <accountant-email-chat-window
                v-if="mailConversationId"
                :room="result"
                :key="mailConversationId"
            />
        </div>
        <div class="flex w-72 flex-col">
            <accountant-inbox-detail v-if="roomId" :room="result" />
            <accountant-inbox-email-detail
                v-else-if="mailConversationId"
                :room="result"
            />
        </div>
    </template>
</template>
<script setup>
defineProps({
    client: {
        type: Object,
        default: null,
    },
})

const route = useRoute()
const roomId = computed(() => route.query.conversation)
const mailConversationId = computed(() => route.query['mail-conversation'])

const { execute, result, loading } = useApi(
    '/api/chat/conversations/detail/{id}',
    'GET'
)

watch(
    () => roomId.value,
    () => {
        loadConversation(roomId.value)
    }
)

onMounted(() => {
    loadConversation(mailConversationId.value ?? roomId.value)
})

const loadConversation = (roomId) => {
    if (!roomId) {
        return
    }

    execute({
        params: {
            id: roomId,
        },
    })
}

watch(
    () => mailConversationId.value,
    () => {
        loadConversation(mailConversationId.value)
    }
)
</script>
