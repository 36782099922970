<template>
    <div>
        <button
            @click="toggleAccordion"
            class="relative z-20 flex w-full items-center"
            :aria-expanded="isOpen"
            :class="triggerClasses"
        >
            <slot name="left" v-bind="{ isOpen }" />
            <slot name="title" />
            <span class="ml-auto">
                <slot name="right" v-bind="{ isOpen }">
                    <span
                        class="flex h-5 w-5 items-center justify-center"
                        :class="{ 'rounded-full border': iconBordered }"
                    >
                        <slot name="icon" v-bind="{ isOpen }">
                            <base-icon
                                v-if="isOpen"
                                name="line-icons:general:minus"
                                size="sm"
                                variant="grayLight"
                            />
                            <base-icon
                                v-else
                                name="line-icons:general:plus"
                                size="sm"
                                variant="grayLight"
                            />
                        </slot>
                    </span>
                </slot>
            </span>
        </button>
        <transition
            name="expand"
            @enter="onExpandEnter"
            @beforeLeave="onExpandBeforeLeave"
            ref="contentContainer"
        >
            <div v-show="isOpen" class="z-10 overflow-hidden">
                <slot name="content" />
            </div>
        </transition>
    </div>
</template>

<script setup>
const props = defineProps({
    iconBordered: {
        type: Boolean,
        default: true,
    },
    triggerClasses: {
        type: String,
        default: '',
    },
    expanded: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits(['toggle'])

const isOpen = ref(props.expanded)

const toggleAccordion = () => {
    isOpen.value = !isOpen.value
    emit('toggle', isOpen.value)
}

const collapseAccordion = () => {
    isOpen.value = false
    emit('toggle', isOpen.value)
}

const onExpandEnter = (el) => {
    el.style.height = el.scrollHeight + 'px'
}

const onExpandBeforeLeave = (el) => {
    el.style.height = el.scrollHeight + 'px'
}

const contentContainer = ref(null)
defineExpose({ isOpen, collapseAccordion, contentContainer })
</script>
