<template>
    <div class="flex">
        <base-icon
            class="animate-spin text-gray-200"
            name="general:loading"
            :class="classes"
            :style="styles"
            :variant="iconVariant"
        />
        <slot></slot>
    </div>
</template>

<script setup>
const props = defineProps({
    variant: {
        type: String,
        default: 'primary',
        required: false,
        validator(value) {
            return ['primary', 'danger', 'gray'].includes(value)
        },
    },
    iconVariant: {
        type: String,
        default: 'inherit',
        required: false,
        validator(value) {
            return [
                'inherit',
                'primary',
                'gray',
                'grayLight',
                'danger',
                'warning',
                'success',
            ].includes(value)
        },
    },
    size: {
        type: String,
        default: 'md',
        required: false,
        validator(value) {
            return ['xxs', 'xs', 'sm', 'md', 'lg', 'xl', '2xl'].includes(value)
        },
    },
})

const styles = computed(() => {
    const { size } = props

    const sizeData = {
        xxs: '20px',
        xs: '24px',
        sm: '28px',
        md: '42px',
        lg: '50px',
        xl: '58px',
        '2xl': '64px',
    }

    return {
        width: sizeData[size],
        height: sizeData[size],
    }
})

const classes = computed(() => {
    const { variant } = props

    const colorData = {
        primary: 'fill-primary-600',
        danger: 'fill-danger-600',
    }

    return colorData[variant]
})
</script>
