<template>
    <div class="flex h-full flex-col">
        <!-- Header -->
        <div class="flex border-b p-6 pb-4">
            <div class="flex grow items-center gap-4">
                <base-icon
                    class="cursor-pointer"
                    name="line-icons:arrows:chevron-left"
                    variant="inherit"
                    @click="onReturn"
                />
                {{
                    $t('chat.chatWindow.title', {
                        name: accountant.full_name,
                    })
                }}
            </div>

            <base-icon
                class="cursor-pointer"
                name="line-icons:general:x"
                variant="gray"
                @click="onClose"
            />
        </div>

        <div class="grow" />

        <!-- Footer form -->
        <div class="border-t px-6 py-4">
            <form class="flex gap-3" @submit="onSendMessage">
                <form-text-input
                    v-model="message"
                    :error-message="messageError"
                    hide-message
                    class="grow"
                    :placeholder="$t('chat.chatWindow.messageInputPlaceholder')"
                    autofocus
                />
                <base-button
                    type="submit"
                    class="p-3"
                    icon="line-icons:communication:send-01"
                    :disabled="disabledSend"
                    :loading="loadingSend"
                    :has-padding="false"
                />
            </form>
        </div>
    </div>
</template>

<script setup>
import { isEmpty } from 'lodash-es'

const emit = defineEmits(['return', 'close', 'new-conversation'])

defineProps({
    accountant: {
        type: Object,
        default: null,
    },
})

const { handleSubmit } = useForm()
const {
    value: message,
    errorMessage: messageError,
    setErrors: setMessageError,
} = useField('message')

const disabledSend = computed(() => isEmpty(message.value))

const { execute: send, loading: loadingSend } = useApi(
    '/api/chat/new-accountant',
    'POST'
)

const onReturn = () => {
    emit('return')
}

const onClose = () => {
    emit('close')
}

const onSendMessage = handleSubmit((values) => {
    send({
        data: values,
    })
        .then((message) => {
            emit('new-conversation', message)
        })
        .catch(({ errorMessage }) => {
            setMessageError(errorMessage)
        })
})
</script>
