<template>
    <span
        class="inline-flex cursor-pointer items-center rounded-md border border-gray-300 py-px pl-1.5 pr-1 text-sm font-medium"
        v-for="item of results"
        :key="item[optionValue]"
        @click="onClickOption(item)"
    >
        <span class="mr-1 text-gray-700">
            {{ item[optionLabel] }}
        </span>
        <base-icon name="line-icons:general:x" size="sm" variant="gray" />
    </span>
</template>

<script setup>
const emit = defineEmits(['clearSelection'])

defineProps({
    results: {
        type: Array,
        required: true,
    },

    optionValue: {
        type: String,
        default: 'value',
    },

    optionLabel: {
        type: String,
        default: 'label',
    },
})

const onClickOption = (option) => {
    emit('clearSelection', option)
}
</script>
