<template>
    <div class="flex w-full flex-col" :class="containerClass">
        <form-control-label :label="label" />

        <div class="relative">
            <form-control-highlight v-if="highlight" />

            <textarea
                v-bind="restAttrs"
                v-model="value"
                class="block w-full rounded-lg border text-sm text-gray-900 placeholder-gray-500 outline-0 focus:ring-4 focus:ring-offset-0 disabled:cursor-not-allowed"
                :class="inputClasses"
                :disabled="disabled"
            />

            <div
                v-if="tooltip && !errorMessage"
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
            >
                <base-icon
                    name="line-icons:general:help-circle"
                    variant="grayLight"
                    size="sm"
                />
            </div>

            <div
                v-if="errorMessage && !disabled"
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
            >
                <base-icon
                    name="line-icons:alerts-&-feedback:alert-circle"
                    variant="danger"
                    size="sm"
                />
            </div>
        </div>

        <form-control-hint-text
            :hint-text="hintText"
            :error-message="errorMessage"
        />
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
}
</script>

<script setup>
import { computed, useAttrs } from 'vue'

const props = defineProps({
    /**
     * Label displayed above the form field
     */
    label: {
        type: String,
        default: '',
    },
    /**
     * Short help text that goes below the input field
     */
    hintText: {
        type: String,
        default: '',
    },
    /**
     * A floating, non-actionable label used to explain an input
     */
    tooltip: {
        type: String,
        default: '',
    },
    /**
     * Error message in red shown below the input field when it's destructive
     */
    errorMessage: {
        type: String,
        default: '',
    },
    /**
     * Set the amount of padding in the input
     */
    size: {
        type: String,
        default: 'md',
        validator(value) {
            return ['sm', 'md'].includes(value)
        },
    },
    /**
     * Highlight dot and outline in yellow shown above the input field
     */
    highlight: {
        type: Boolean,
        default: false,
    },
    /**
     * Component model value
     */
    modelValue: {
        type: [String, Number],
        default: '',
    },
})

// Access the component's fallthrough attributes
const attrs = useAttrs()

const { disabled, class: containerClass, ...restAttrs } = attrs

// Input handler
const emit = defineEmits(['update:modelValue'])
const value = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
    },
})

const inputClasses = computed(() => {
    const { size, errorMessage, highlight } = props

    return {
        'px-3 py-2': size === 'sm',
        'px-3.5 py-2.5': size === 'md',
        // Only apply the styles with Primary color when it's non-destructive (default state) to avoid override issues
        'border-gray-300 focus:border-primary-300 focus:ring-primary-100':
            !errorMessage,
        'border-danger-300 focus:border-danger-300 focus:ring-danger-100':
            errorMessage && !disabled,
        'text-gray-500 border-gray-300 disabled:bg-gray-50': disabled,
        'border-warning-300 focus:border-warning-300 ring-4 ring-warning-100 focus:ring-warning-100':
            highlight,
    }
})
</script>
