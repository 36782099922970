<template>
    <div
        class="flex w-full items-center justify-between"
        :class="range ? 'left-44' : 'left-0'"
    >
        <template v-if="Array.isArray(internalModelValue)">
            <span v-for="(item, index) in internalModelValue" :key="index">
                <span
                    class="rounded-xl border border-gray-300 px-3.5 py-2.5 text-md font-normal text-gray-900"
                >
                    {{ formatPreview(item) }}
                </span>
                <span
                    v-if="internalModelValue.length - 1 !== index"
                    class="mx-1.5 text-md font-normal text-gray-500"
                >
                    –
                </span>
            </span>
        </template>

        <div
            class="flex flex-1 gap-x-3"
            :class="range ? 'justify-end' : 'justify-between'"
        >
            <base-button
                @click="$emit('closePicker')"
                size="sm"
                variant="default"
                :full-width="!range"
            >
                {{ $t('general.cancel') }}
            </base-button>
            <base-button
                @click="$emit('selectDate')"
                size="sm"
                :full-width="!range"
            >
                {{ $t('general.apply') }}
            </base-button>
        </div>
    </div>
</template>

<script setup>
import { dayjsFormat } from '@tenant/utils/day'
import { DATE_HUMAN_FORMAT } from '@tenant/utils/constants'

defineProps({
    internalModelValue: {
        type: [Date, Array],
    },
    range: {
        type: Boolean,
    },
})

defineEmits(['selectDate', 'closePicker'])

const formatPreview = (date) => {
    if (!date) {
        return ''
    }

    return dayjsFormat(date, DATE_HUMAN_FORMAT)
}
</script>
