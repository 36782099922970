<template>
    <slide-out-menu size="auto" :has-padding="false" @close="$emit('close')">
        <template #close-icon>
            <div class="pr-6 pt-6">
                <base-icon
                    size="lg"
                    variant="gray"
                    class="cursor-pointer"
                    name="line-icons:general:x"
                    @click.prevent="$emit('close')"
                />
            </div>
        </template>

        <template #slide-out-menu-header>
            <div class="pl-6 pt-6">
                <div class="text-xl text-gray-900">
                    {{ user?.full_name }}
                </div>
                <div class="text-sm text-gray-500">
                    {{ user?.email }}
                </div>
            </div>
        </template>

        <div class="flex justify-between border-b border-gray-200 px-6 py-3.5">
            <base-button
                variant="link-color"
                :has-padding="false"
                @click="goToProfilePage"
            >
                {{ $t('userMenu.myAccount') }}
            </base-button>

            <base-button
                variant="link-danger"
                :has-padding="false"
                icon="line-icons:general:log-out-01"
                @click="logout"
            >
                {{ $t('userMenu.logout') }}
            </base-button>
        </div>

        <div class="border-b border-gray-200 py-6">
            <p class="px-6 pb-6 text-xl">{{ $t('userMenu.assistanceText') }}</p>

            <ul>
                <base-context-menu-item class="px-6">
                    <template #icon>
                        <base-icon
                            name="line-icons:communication:message-text-square-01"
                            variant="inherit"
                            class="group-hover:text-primary-500"
                        />
                    </template>
                    {{ $t('userMenu.questionText') }}
                </base-context-menu-item>
                <base-context-menu-item class="px-6">
                    <template #icon>
                        <base-icon
                            name="line-icons:communication:mail-01"
                            variant="inherit"
                            class="group-hover:text-primary-500"
                        />
                    </template>
                    {{ $t('userMenu.sendEmailText') }}
                </base-context-menu-item>
                <base-context-menu-item class="px-6">
                    <template #icon>
                        <base-icon
                            name="line-icons:users:user-plus-01"
                            variant="inherit"
                            class="group-hover:text-primary-500"
                        />
                    </template>
                    {{ $t('userMenu.findAccountantText') }}
                </base-context-menu-item>
            </ul>
        </div>

        <div class="p-6">
            <a
                href="#"
                target="_blank"
                class="flex gap-2.5 rounded-lg bg-gray-100 p-5 hover:bg-gray-50"
            >
                <base-icon name="general:star" variant="white" size="md" />

                <div>
                    <p class="text-md text-gray-900">
                        {{ $t('userMenu.newsText') }}
                    </p>
                    <p class="flex items-center text-xs text-gray-500">
                        {{ $t('userMenu.featureText') }}
                        <base-icon
                            size="sm"
                            name="line-icons:arrows:chevron-right"
                            class="!text-primary-700"
                        />
                    </p>
                </div>
            </a>
        </div>
    </slide-out-menu>
</template>

<script setup>
const { user } = useAuth()
const router = useRouter()
const emit = defineEmits(['close'])

const { logout } = useAuthHelper()

const goToProfilePage = () => {
    emit('close')
    router.push({
        name: 'profile',
    })
}
</script>
