<template>
    <base-tooltip
        :bordered="true"
        :padding="false"
        v-if="exchangeRate && fromIso !== toIso"
    >
        <div
            class="flex items-center justify-end gap-3 text-right text-sm font-medium text-primary-700"
        >
            {{
                $t('createInvoice.lineItemsExchangeRate', {
                    now: $filters.dateCustomFormat(
                        exchangeRate.date,
                        'YYYY-MM-DD'
                    ),
                    fromIso: fromIso,
                    toIso: toIso,
                    factor: value,
                })
            }}

            <base-icon
                variant="inherit"
                class="h-1/2 w-1/2"
                size="sm"
                name="line-icons:editor:pencil-01"
            />
        </div>

        <template #content="{ close }">
            <div
                class="flex items-center justify-between border-b border-gray-300 bg-gray-200 p-2"
            >
                <p class="text-sm">
                    {{ $t('invoices.editExchangeRate') }}
                </p>

                <base-icon
                    @click="close"
                    name="line-icons:general:x"
                    size="md"
                    variant="gray"
                    class="cursor-pointer"
                />
            </div>

            <div class="max-full flex flex-col items-start justify-start p-3">
                <form-number-input
                    v-model="value"
                    :precision="6"
                    :label="$t('invoices.exchangeRateLabel')"
                    class="mb-2 items-start"
                />

                <div class="mb-4">
                    <form-checkbox
                        v-model="shouldRecalculate"
                        :text="$t('invoices.recalculateLabel')"
                    />
                </div>

                <base-button
                    variant="primary"
                    :disabled="!value"
                    @click="onSave(close)"
                >
                    {{ $t('general.save') }}
                </base-button>
            </div>
        </template>
    </base-tooltip>
</template>

<script setup>
const emit = defineEmits(['update:modelValue', 'on-save-factor'])

const props = defineProps({
    exchangeRate: {
        type: Object,
        default: null,
    },
    fromIso: {
        type: Object,
        default: null,
    },
    toIso: {
        type: Object,
        default: null,
    },
    modelValue: {
        type: String,
        default: '',
    },
})

const shouldRecalculate = ref(false)

const value = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
    },
})

const onSave = (closeCb) => {
    if (shouldRecalculate.value) {
        emit('on-save-factor')
    }

    closeCb()
}
</script>
