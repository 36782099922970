<template>
    <Teleport to="body">
        <transition
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
            @after-enter="onAfterEnter"
            @after-leave="onAfterLeave"
        >
            <div
                v-if="show"
                class="fixed left-0 top-0 z-50 h-screen w-screen bg-gray-700 bg-opacity-30 py-5 text-center backdrop-blur-xxs duration-200"
                :class="outlineClass"
            >
                <div
                    class="absolute bottom-0 left-0 right-0 top-0"
                    @click.prevent="closeModal"
                />

                <div class="inline-block h-full align-middle" />
                <div
                    class="relative z-50 inline-block max-h-full max-w-[90%] overflow-auto rounded-xl bg-white text-start align-middle shadow-lg"
                    :class="containerClass"
                >
                    <div class="flex">
                        <div :class="modalClasses">
                            <div
                                v-if="loading"
                                class="absolute bottom-0 left-0 right-0 top-0 z-50 bg-white p-6 opacity-50"
                            >
                                <span
                                    class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
                                >
                                    <base-loading size="lg" />
                                </span>
                            </div>

                            <slot name="container">
                                <header
                                    v-if="showHeader"
                                    class="relative flex items-start p-6 pb-0"
                                    :class="{
                                        'pr-10': close,
                                        'border-b pb-6': hasSeparator,
                                    }"
                                >
                                    <div class="flex-1">
                                        <slot name="modal-icon">
                                            <base-icon
                                                v-if="icon"
                                                :name="icon"
                                                class="mb-5"
                                            />
                                        </slot>

                                        <slot name="modal-header">
                                            <h4>{{ title }}</h4>

                                            <h5>{{ subtitle }}</h5>
                                        </slot>
                                    </div>

                                    <button
                                        v-if="close"
                                        @click.prevent="closeModal"
                                        class="absolute right-6 top-6"
                                    >
                                        <base-icon
                                            name="line-icons:general:x"
                                            size="lg"
                                            variant="gray"
                                        />
                                    </button>
                                </header>

                                <slot name="content" v-bind="{ doneEnter }">
                                    <div
                                        class="flex-1 py-5"
                                        :class="[
                                            {
                                                'px-6': contentPadding,
                                            },
                                            customContentClass,
                                        ]"
                                    >
                                        <slot v-bind="{ doneEnter }" />
                                    </div>
                                </slot>

                                <slot name="modal-footer-outer">
                                    <footer
                                        v-if="showFooter"
                                        class="p-6 pt-0"
                                        :class="{
                                            'border-t pt-6': hasSeparator,
                                        }"
                                    >
                                        <slot name="modal-footer" />
                                    </footer>
                                </slot>
                            </slot>
                        </div>
                    </div>
                </div>
                <slot name="backdrop" />
            </div>
        </transition>
    </Teleport>
</template>

<script>
export default {
    inheritAttrs: false,
}
</script>

<script setup>
import { computed, onMounted, onBeforeUnmount, ref, useAttrs } from 'vue'

const attrs = useAttrs()
const emit = defineEmits(['modal-close'])

const doneEnter = ref(false)

onMounted(() => {
    document.addEventListener('keyup', closeModalOnEscapeKey)
})

onBeforeUnmount(() => {
    document.removeEventListener('keyup', closeModalOnEscapeKey)
})

const props = defineProps({
    showHeader: {
        type: Boolean,
        default: true,
    },
    showFooter: {
        type: Boolean,
        default: true,
    },

    customContentClass: {
        type: [Object, Array, String],
        required: false,
        default: () => ({}),
    },
    show: {
        type: [Boolean, Number, String],
        default: false,
    },

    loading: {
        type: Boolean,
        default: false,
    },

    title: {
        type: String,
    },

    subtitle: {
        type: String,
    },

    close: {
        type: Boolean,
    },

    size: {
        type: String,
        validator(value) {
            // The value must match one of these strings
            return ['sm', 'md', 'lg', 'sxl', 'xl', 'xxl', 'auto'].includes(
                value
            )
        },
    },

    icon: {
        type: String,
    },

    containerClass: {
        type: [String, Array, Object],
    },

    outlineClass: {
        type: [String, Array, Object],
    },

    contentPadding: {
        type: Boolean,
        default: true,
    },
    hasSeparator: {
        type: Boolean,
        default: false,
    },
})

// CLASSES
const modalClasses = computed(() => {
    const { size } = props

    const classes = {
        sm: 'w-[400px]',
        md: 'w-[480px]',
        lg: 'w-[600px]',
        sxl: 'w-[700px]',
        xl: 'w-[860px]',
        xxl: 'w-[1126px]',
        auto: null,
    }

    return [classes[size], attrs.class]
})

const closeModalOnEscapeKey = (e) => {
    if (e.keyCode === 27 && props.show) {
        closeModal()
    }
}

const closeModal = () => {
    if (props.loading) {
        return
    }

    emit('modal-close')
}

const onAfterEnter = () => {
    doneEnter.value = true
}

const onAfterLeave = () => {
    doneEnter.value = false
}
</script>
