<template>
    <div class="flex" :class="containerCls">
        <label class="relative w-full cursor-pointer p-4" :class="labelClass">
            <input
                v-bind="restAttrs"
                v-model="model"
                type="radio"
                class="peer hidden"
                :disabled="disabled"
            />
            <span
                class="absolute bottom-0 left-0 right-0 top-0 z-0"
                :class="classContent"
            />
            <span
                class="absolute top-1/2 h-4 w-4 -translate-y-1/2 rounded-full border border-gray-300 bg-white transition duration-150 peer-checked:border-primary-600"
                :class="bulletClasses"
            />
            <span
                class="absolute top-1/2 hidden h-4 w-4 -translate-y-1/2 items-center justify-center rounded-full text-white transition duration-150 peer-checked:flex"
                :class="bulletClasses"
            >
                <base-icon
                    v-if="bulletIcon"
                    variant="inherit"
                    :name="bulletIcon"
                    size="xs"
                />
                <span
                    v-else
                    class="h-1.5 w-1.5 rounded-full bg-primary-500"
                ></span>
            </span>
            <span
                class="relative flex w-full items-center"
                :class="contentClasses"
            >
                <slot v-bind="{ checked }" />
            </span>

            <slot name="bottom" v-bind="{ classContent }" />
        </label>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
}
</script>

<script setup>
import { computed, useAttrs } from 'vue'

const props = defineProps({
    size: {
        type: String,
        default: 'md',
    },

    disabled: {
        type: Boolean,
        default: false,
    },

    modelValue: {
        type: [Boolean, String, Number, Object],
        default: false,
    },

    checkedCondition: {
        type: Function,
        default: null,
    },

    bulletPosition: {
        type: String,
        default: 'right',
        required: false,
        validator(value) {
            return ['right', 'left'].includes(value)
        },
    },

    bulletIcon: {
        type: String,
        default: 'line-icons:general:check',
    },
})

const attrs = useAttrs()

const { class: containerCls, ...restAttrs } = attrs

// Input handler
const emit = defineEmits(['update:modelValue'])
const model = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
    },
})

const classContent = computed(
    () =>
        'rounded-lg border border-gray-200 transition duration-150 peer-checked:border-primary-600 peer-checked:bg-primary-50 peer-disabled:bg-gray-50'
)

const checked = computed(() => {
    if (props.checkedCondition) {
        return props.checkedCondition(attrs.value, model.value)
    }

    return model.value === attrs.value
})

const labelClass = computed(() => {
    return {
        'cursor-default': props.disabled,
    }
})

const bulletClasses = computed(() => {
    let classes = {
        'right-4': props.bulletPosition === 'right',
        'left-4': props.bulletPosition === 'left',
    }

    if (props.bulletIcon) {
        classes = {
            ...classes,
            'peer-checked:bg-primary-600': true,
        }
    }

    return classes
})

const contentClasses = computed(() => {
    const classes = {
        'pr-6': props.bulletPosition === 'right',
        'pl-6': props.bulletPosition === 'left',
    }

    return classes
})
</script>
