<template>
    <base-modal close size="xxl" :show="show" @modal-close="closeModal">
        <template #modal-header>
            <span class="text-lg font-medium text-gray-900">
                {{ $t('settingsTextTemplates.modalTemplate.title') }}
            </span>
        </template>
        <div class="flex flex-col">
            <data-grid
                :data-source="items"
                :columns="columns"
                :loading="loading"
            >
                <template #column-languages="{ item }">
                    <div class="grid grid-cols-4 gap-1 text-sm">
                        <template
                            v-for="content in item.contents"
                            :key="content.id"
                        >
                            <span
                                v-if="content.language"
                                class="cursor-pointer rounded-md px-3 py-2 text-center font-medium text-gray-500 transition duration-150 hover:bg-primary-100 hover:text-primary-700 focus:ring-primary-100"
                                :class="activeTemplateClass(content)"
                                @click="onSelectTemplateContent(item, content)"
                            >
                                {{ content.language.name }}
                            </span>
                        </template>
                    </div>
                </template>
            </data-grid>
        </div>
        <template #modal-footer>
            <div class="flex justify-end gap-3">
                <base-button
                    outline
                    variant="default"
                    class="w-60 justify-center"
                    @click="closeModal"
                >
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button class="w-60 justify-center" @click="onSubmit">
                    {{
                        $t('general.insertText', {
                            name: selectedTemplate?.name,
                            language: selectedTemplateContent?.language?.name,
                        })
                    }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
import { unref, watch, computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useApi } from '@tenant/composables'

const emit = defineEmits(['modal-close', 'on-insert'])

const props = defineProps({
    show: {
        type: Boolean,
        required: false,
    },
    templateType: {
        type: [Number, String],
        required: false,
    },
    templateTechnicalType: {
        type: [Number, String],
        required: false,
    },
    templateLineItemType: {
        type: [Number, String],
        required: false,
    },
})

const { t } = useI18n()

const { execute, loading, result } = useApi(
    '/api/text-templates-by-type',
    'GET'
)

const selectedTemplate = ref(null)
const selectedTemplateContent = ref(null)

const activeTemplateClass = (item) => {
    return selectedTemplateContent.value?.id === item.id
        ? 'bg-primary-50 !text-primary-700'
        : ''
}

const columns = [
    {
        property: 'name',
        label: t('settingsTextTemplates.list.name'),
        headerCellClass: 'w-[50%]',
    },
    {
        property: 'languages',
        label: t('settingsTextTemplates.list.languages'),
    },
]

const items = computed(() => unref(result) || [])

watch(
    () => props.show,
    async (value) => {
        if (!value) {
            return
        }

        await execute({
            queries: {
                type_id: unref(props.templateType),
                type_module_id: unref(props.templateTechnicalType),
                type_line_item_id: unref(props.templateLineItemType),
            },
        })
    }
)

const onSelectTemplateContent = (template, content) => {
    selectedTemplate.value = template
    selectedTemplateContent.value = content
}

const closeModal = () => {
    emit('modal-close')
    selectedTemplate.value = null
    selectedTemplateContent.value = null
}

const onSubmit = () => {
    emit('on-insert', unref(selectedTemplateContent))
    closeModal()
}
</script>
