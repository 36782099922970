<template>
    <base-modal close size="lg" :show="show" @modal-close="closeModal">
        <template #modal-header>
            <span class="text-lg font-medium text-gray-900">
                {{ $t('noPaymentAccountModal.title') }}
            </span>
        </template>

        <base-alert
            variant="primary"
            :title="$t('noPaymentAccountModal.alertTitle')"
            :description="$t('noPaymentAccountModal.alertDescription')"
        />

        <template #modal-footer>
            <div class="flex gap-3">
                <base-button full-width variant="default" @click="closeModal">
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button full-width @click="onSubmit">
                    {{ $t('noPaymentAccountModal.createPaymentAccount') }}
                    <base-icon
                        name="line-icons:general:link-external-02"
                        class="text-white"
                    />
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
defineProps({
    show: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits(['modal-close'])
const router = useRouter()

const closeModal = () => {
    emit('modal-close')
}

const onSubmit = () => {
    closeModal()
    const routeData = router.resolve({
        name: 'settings.accounting.payment-account.connect-bank',
    })
    window.open(routeData.href, '_blank')
}
</script>
