<template>
    <base-modal close @modal-close="closeModal" :show="show" size="sm">
        <template #modal-icon>
            <div class="flex">
                <slot name="icon">
                    <base-feature-icon
                        variant="danger"
                        size="sm"
                        name="line-icons:alerts-&-feedback:alert-triangle"
                        type="highlight"
                    />
                </slot>
            </div>
        </template>

        <div class="flex flex-col gap-2">
            <p class="text-lg font-medium text-gray-900">
                {{
                    $t('modal.transaction.bookClose.title', {
                        date: $filters.dateCustomFormat(
                            date,
                            DATE_HUMAN_FORMAT_NO_COMMA
                        ),
                    })
                }}
            </p>
            <p class="text-sm font-normal text-gray-500">
                {{ $t('modal.transaction.bookClose.description') }}
            </p>
        </div>
        <template #modal-footer>
            <base-button full-width variant="danger" @click="closeModal">
                {{ $t('modal.transaction.bookClose.button') }}
            </base-button>
        </template>
    </base-modal>
</template>

<script setup>
import { DATE_HUMAN_FORMAT_NO_COMMA } from '../../utils/constants'

const emit = defineEmits(['modal-close', 'modal-confirm'])

const props = defineProps({
    code: {
        type: [String, Number],
        default: null,
    },
    date: {
        type: String,
        default: '',
    },
})

const show = computed(() => `${props.code}` === '40000')

const closeModal = () => {
    emit('modal-close')
}
</script>
