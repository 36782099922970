<template>
    <div class="flex flex-col overflow-auto py-3" ref="el">
        <div
            class="cursor-pointer px-6 hover:bg-gray-25"
            v-for="conversation in data"
            :key="conversation.id"
            @click="onLoadChat(conversation)"
        >
            <div class="flex items-start gap-3 border-b py-4">
                <div class="relative">
                    <base-avatar
                        size="lg"
                        :user="
                            getReceiver(conversation.participants, centralUser)
                        "
                    />
                    <span
                        class="absolute -bottom-0.5 right-0 h-3.5 w-3.5 rounded-full border-2 border-white bg-success-500"
                    />
                </div>

                <div class="grow">
                    <div class="mb-3 flex items-center gap-2">
                        <span class="text-sm font-medium text-gray-700">
                            {{
                                getReceiverFullName(
                                    conversation.participants,
                                    centralUser
                                )
                            }}
                        </span>

                        <span class="text-xs text-gray-500">
                            {{
                                $t('timeAgo', {
                                    diffDate:
                                        conversation.latest_message.diff_date,
                                })
                            }}
                        </span>
                    </div>

                    <div
                        class="truncate text-sm text-gray-500"
                        v-html="
                            $filters.sanitize(
                                conversation.latest_message.message
                            )
                        "
                    />
                </div>

                <span class="h-2.5 w-2.5 rounded-full bg-success-500" />
            </div>
        </div>

        <div
            v-if="isFetching"
            class="flex w-full items-center justify-center py-2"
        >
            <base-loading />
        </div>
    </div>
</template>

<script setup>
import { getReceiver, getReceiverFullName } from '@tenant/utils/helper'
import { useInfiniteScroll } from '@vueuse/core'
import { ECHO_EVENTS } from '@tenant/utils/constants'

const emit = defineEmits(['load-chat'])
const { centralUser } = useAuth()

const el = ref(null)

const props = defineProps({
    groupId: {
        type: String,
        default: null,
    },
})

useInfiniteScroll(
    el,
    () => {
        onLoadMore()
    },
    { distance: 10 }
)

const { data, onLoadMore, isFetching } = useConversations(props.groupId)
const { newConversation, updateConversation } = useConversation()

useListenEmitEcho(ECHO_EVENTS.CHAT_NEW, ({ chatRoom }) => {
    newConversation(chatRoom)
})
useListenEmitEcho(ECHO_EVENTS.CHAT_MESSAGE, ({ chatRoom }) => {
    updateConversation(chatRoom)
})

const onLoadChat = (conversation) => {
    emit('load-chat', conversation)
}
</script>
