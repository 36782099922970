<template>
    <form-entity-select
        :item-label="getContactName"
        :input-text-style="showExisting ? 'italic' : 'normal'"
        endpoint="/api/contacts"
        :bordered-option="true"
        :show-empty="showEmpty"
        @change="onContactChange"
        @on-enter="onEnter"
    >
        <template #option-label="{ item, inputValue }">
            <div class="flex flex-col">
                <span
                    class="mb-2"
                    v-html="getContactItemLabel(item, inputValue)"
                />
                <span class="text-sm text-gray-500" v-show="showCusNo">
                    {{
                        $t('general.customerNo', {
                            number: item.customer_number,
                        })
                    }}
                </span>
            </div>
        </template>

        <template #badge v-if="hasNewContactCreated || showExisting">
            <base-badge
                v-if="hasNewContactCreated"
                :label="$t('general.new')"
                class="gap-2 !p-0 !px-2 uppercase"
                variant="success"
            />

            <base-badge
                v-if="showExisting"
                :label="$t('general.exist')"
                class="gap-2 !p-0 !px-2"
                variant="primary"
            />
        </template>

        <template v-if="createContact" #create>
            <div
                @click="onAddContact"
                class="flex cursor-pointer select-none items-center justify-between bg-primary-50 px-3.5 py-5 text-gray-900 hover:bg-primary-100"
            >
                {{ $t('general.createNewContactBtn') }}
                <base-icon name="line-icons:general:plus-circle" />
            </div>
        </template>

        <template v-if="createContact" #after-options>
            <create-contact-modal
                :show="showCreateContactModal"
                @modal-close="onCloseCreateContactModal"
                @contact-added="onNewContactAdded"
            />
        </template>
    </form-entity-select>
</template>
<script setup>
import { getContactItemLabel, getContactName } from '@tenant/utils/helper'

const props = defineProps({
    createContact: {
        type: Boolean,
        default: true,
    },
    showCusNo: {
        type: Boolean,
        default: true,
    },
    showEmpty: {
        type: Boolean,
        default: false,
    },
    showExisting: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits(['on-contact-changed'])

const showCreateContactModal = ref(false)
const onAddContact = () => {
    showCreateContactModal.value = true
}

const onCloseCreateContactModal = () => {
    showCreateContactModal.value = false
}

const onNewContactAdded = (contact) => {
    showCreateContactModal.value = false
    onContactChange(contact?.id, contact)
}

const onContactChange = (id, item) => {
    emit('on-contact-changed', { id, item })
}

const { errorNotify } = useNotification()
const { result: newContact, execute: executeCreate } = useApiFactory(
    'contacts/quick-create'
).useApiCreate()

const attrs = useAttrs()
const hasNewContactCreated = computed(() => {
    return newContact.value && newContact.value?.id === attrs.modelValue
})
const onEnter = (inputValue, results) => {
    if (!props.createContact || results.length > 0) {
        return
    }

    executeCreate({
        name: inputValue,
    })
        .then((res) => {
            onNewContactAdded(res)
        })
        .catch(({ errorMessage }) => {
            errorNotify({
                text: errorMessage,
            })
        })
}
</script>
