<template>
    <div
        class="flex flex-col"
        :class="[block ? 'w-full' : null, containerClass]"
    >
        <form-control-label :label="label" />

        <Multiselect
            v-model="value"
            :mode="mode"
            :searchable="true"
            :append-to-body="true"
            :value-prop="optionValue"
            :label="optionLabel"
            :attrs="{ placeholder: searchPlaceholder }"
            :classes="{
                container: `relative mx-auto w-full flex items-center justify-end px-2 ${
                    size === 'sm' ? 'py-2' : 'py-2.5'
                } cursor-pointer border border-gray-300 rounded-md bg-white`,
                search: 'w-full absolute inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-base font-sans bg-white rounded pl-3.5',
                caret: 'bg-multiselect-caret bg-center bg-no-repeat w-5 h-5 bg-cover py-px box-content relative z-10 opacity-50 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none',
                caretOpen: 'rotate-180 pointer-events-auto',
                tags: 'flex-grow flex-shrink flex flex-wrap items-center min-w-0 gap-x-2 gap-y-1.5',
                tagDisabled: 'pr-2 opacity-50',
                tagWrapper:
                    'whitespace-nowrap overflow-hidden overflow-ellipsis',
                tagsSearchWrapper:
                    'inline-block relative flex-grow flex-shrink h-full',
                tagsSearch:
                    'absolute inset-0 border-0 focus:ring-0 p-0 w-full ml-1',
                spacer: 'h-6 box-content',
            }"
            v-bind="rest"
        >
            <template #tag="bind">
                <slot name="tag" v-bind="bind" />
            </template>

            <template #option="bind">
                <slot name="option" v-bind="bind" />
            </template>
        </Multiselect>

        <form-control-hint-text
            v-if="!hideMessage"
            :hint-text="hintText"
            :error-message="errorMessage"
        />
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
}
</script>

<script setup>
import '@vueform/multiselect/themes/tailwind.css'
import Multiselect from '@vueform/multiselect'

const props = defineProps({
    /**
     * Label displayed above the form field
     */
    label: {
        type: String,
        default: '',
    },
    /**
     * Search placeholder
     */
    searchPlaceholder: {
        type: String,
        default: '',
    },
    /**
     * Name of the icon displayed on the left corner of the input
     */
    iconName: {
        type: String,
        default: '',
    },
    /**
     * Component model value
     */
    modelValue: {
        default: null,
    },
    /**
     * Width block
     */
    block: {
        type: Boolean,
        default: true,
    },
    /**
     * Mode
     */
    mode: {
        type: String,
        default: 'tags',
        validator(value) {
            return ['single', 'multiple', 'tags'].includes(value)
        },
    },
    /**
     * Set the amount of padding in the input
     */
    size: {
        type: String,
        default: 'md',
        validator(value) {
            return ['sm', 'md'].includes(value)
        },
    },

    optionValue: {
        type: String,
        default: 'value',
    },

    optionLabel: {
        type: String,
        default: 'label',
    },
    /**
     * Hide message
     */
    hideMessage: {
        type: Boolean,
        default: false,
    },
    /**
     * Short help text that goes below the input field
     */
    hintText: {
        type: String,
        default: '',
    },
    /**
     * Error message in red shown below the input field when it's destructive
     */
    errorMessage: {
        type: String,
        default: '',
    },
})

const emit = defineEmits(['update:modelValue'])

const attrs = useAttrs()
const { class: containerClass, ...rest } = attrs

const value = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
    },
})
</script>
