<template>
    <notification-group>
        <div
            class="pointer-events-none fixed inset-0 z-[9999] flex items-start justify-end p-6"
        >
            <div class="flex w-full max-w-md flex-col gap-y-2.5">
                <notification
                    v-slot="{ notifications, close }"
                    enter="transform ease-out duration-100 transition"
                    enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
                    enter-to="translate-y-0 opacity-100 sm:translate-x-0"
                    leave="transition ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                    move="transition duration-200"
                    move-delay="delay-100"
                >
                    <div
                        v-for="notification in notifications"
                        :key="notification.id"
                    >
                        <div
                            class="pointer-events-auto mx-auto mr-6 flex w-full flex-col overflow-hidden rounded-lg border border-gray-100 bg-white px-2.5 pb-4 pt-2.5 shadow-4xl"
                        >
                            <div
                                class="relative mb-2.5 flex w-full items-start justify-between"
                            >
                                <base-feature-icon
                                    :name="notificationIcon(notification)"
                                    :variant="notification.type"
                                />

                                <button @click.prevent="close">
                                    <base-icon
                                        name="line-icons:general:x"
                                        size="lg"
                                        variant="gray"
                                    />
                                </button>
                            </div>

                            <div class="px-1.5">
                                <div>
                                    <h4
                                        class="mb-1 text-sm font-medium text-gray-900"
                                        v-if="notification.title"
                                    >
                                        {{ notification.title }}
                                    </h4>
                                    <p
                                        class="text-sm font-normal text-gray-500"
                                        v-if="notification.text"
                                    >
                                        {{ notification.text }}
                                    </p>
                                </div>
                                <div class="mt-4 flex gap-x-3">
                                    <span
                                        @click="close"
                                        class="cursor-pointer text-sm font-medium text-gray-500"
                                    >
                                        {{ $t('general.dismiss') }}
                                    </span>
                                    <span
                                        v-if="notification.viewChange"
                                        @click="notification.viewChange"
                                        class="cursor-pointer text-sm font-medium text-primary-700"
                                    >
                                        {{ $t('general.viewChanges') }}
                                    </span>
                                    <span
                                        v-if="notification.action"
                                        @click="
                                            () => {
                                                notification.action.method()
                                                close()
                                            }
                                        "
                                        class="cursor-pointer text-sm font-medium text-primary-700"
                                    >
                                        {{ notification.action.text }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </notification>
            </div>
        </div>
    </notification-group>
</template>

<script setup>
const notificationIcon = (notification) => {
    const iconNames = {
        success: 'line-icons:general:check-verified-01',
        danger: 'line-icons:alerts-&-feedback:alert-circle',
        warning: 'line-icons:alerts-&-feedback:alert-triangle',
        primary: 'line-icons:alerts-&-feedback:alert-circle',
    }

    return iconNames[notification.type]
}
</script>
