<template>
    <div class="flex flex-1 flex-col">
        <div
            class="flex items-center justify-between border-b border-gray-200 px-6 py-4"
        >
            <h5 class="text-lg font-medium leading-9 text-gray-900">
                {{ $t('accountantInbox.newConversation') }}
            </h5>
        </div>
        <form class="mx-12 my-12 flex flex-1 items-end" @submit="submitForm">
            <div
                class="flex flex-1 flex-col rounded-lg border border-gray-100 px-6 py-4 shadow-xl"
            >
                <base-context-menu
                    class="!justify-start"
                    position="left"
                    dropdown-classes="-top-[90px]"
                >
                    <template #trigger="{ toggleMenu }">
                        <div
                            class="flex cursor-pointer select-none items-center gap-1 text-xs font-normal text-gray-900"
                            @click="toggleMenu"
                        >
                            <base-icon
                                name="line-icons:communication:message-chat-square"
                                variant="inherit"
                            />
                            {{
                                conversationType === 'chat'
                                    ? $t(
                                          'accountantInbox.conversationType.chat'
                                      )
                                    : $t(
                                          'accountantInbox.conversationType.email'
                                      )
                            }}
                            <base-icon
                                name="line-icons:arrows:chevron-down"
                                variant="inherit"
                            />
                        </div>
                    </template>
                    <template #default="{ closeDropdown }">
                        <base-context-menu-item
                            @click="
                                () => {
                                    closeDropdown()
                                    changeConversationType('chat')
                                }
                            "
                        >
                            {{ $t('accountantInbox.conversationType.chat') }}
                        </base-context-menu-item>
                        <base-context-menu-item
                            @click="
                                () => {
                                    closeDropdown()
                                    changeConversationType('email')
                                }
                            "
                        >
                            {{ $t('accountantInbox.conversationType.email') }}
                        </base-context-menu-item>
                    </template>
                </base-context-menu>
                <div class="mb-2 flex flex-col">
                    <div
                        class="flex items-center gap-3 border-b border-gray-200 py-2"
                    >
                        <template v-if="conversationType === 'chat'">
                            <p class="w-14 text-xs font-normal text-gray-500">
                                {{ $t('accountantInbox.from') }}
                            </p>
                            <base-badge variant="gray">
                                <template #label>
                                    <div
                                        class="flex items-center gap-1.5 text-sm font-semibold text-gray-700"
                                    >
                                        <base-avatar
                                            size="xxs"
                                            :user="centralUser"
                                        />
                                        {{ centralUser.full_name }}
                                    </div>
                                </template>
                            </base-badge>
                        </template>

                        <template v-else>
                            <p class="w-14 text-xs font-normal text-gray-500">
                                {{ $t('accountantInbox.from') }}
                            </p>

                            <base-multiselect
                                v-if="entities?.length"
                                v-model="from"
                                :error-message="fromError"
                                class="mb-1.5"
                                :options="entities"
                                :disabled="entities?.length === 1"
                                :classes="{
                                    container: `relative mx-auto w-full flex items-center justify-end px-0 cursor-pointer`,
                                    spacer: 'h-6 box-content',
                                    clearIcon: 'hidden',
                                    tags: 'flex-grow flex-shrink flex flex-wrap items-center min-w-0 gap-x-2 gap-y-1.5 mt-0',
                                    tagsSearchWrapper:
                                        'inline-block relative flex-grow flex-shrink h-full m-0',
                                }"
                                :caret="false"
                                option-label="email"
                                option-value="id"
                                size="sm"
                                :max="1"
                            >
                                <template #tag="{ handleTagRemove, option }">
                                    <div
                                        class="inline-flex items-center gap-1.5 rounded-2xl bg-gray-100 px-2 py-0.5 text-xs font-normal text-gray-700"
                                    >
                                        <span
                                            class="whitespace-nowrap text-sm font-semibold text-gray-700"
                                        >
                                            {{ option.email }}
                                        </span>

                                        <base-icon
                                            name="line-icons:general:x-close"
                                            class="cursor-pointer"
                                            variant="gray"
                                            size="xs"
                                            @click="
                                                handleTagRemove(option, $event)
                                            "
                                        />
                                    </div>
                                </template>

                                <template #option="{ option }">
                                    <div class="flex flex-col">
                                        <strong
                                            class="block whitespace-nowrap text-md font-semibold text-gray-700"
                                        >
                                            {{ option.username }}
                                        </strong>
                                        <span
                                            class="block whitespace-nowrap text-sm font-normal text-gray-500"
                                        >
                                            {{ option.email }}
                                        </span>
                                    </div>
                                </template>
                            </base-multiselect>
                        </template>
                    </div>
                    <div
                        class="flex items-center gap-3 border-b border-gray-200 py-2"
                    >
                        <p
                            class="w-14 text-xs font-normal"
                            :class="
                                toError ? 'text-danger-500' : 'text-gray-500'
                            "
                        >
                            {{ $t('accountantInbox.to') }}
                        </p>
                        <accountant-inbox-select-clients
                            v-if="conversationType === 'email'"
                            v-model="to"
                            value-property="email"
                            :tagify-props="{
                                pattern:
                                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                enforceWhitelist: false,
                            }"
                        />

                        <accountant-inbox-select-clients
                            v-if="conversationType === 'chat'"
                            v-model="to"
                        />
                    </div>
                    <div
                        class="flex items-center gap-3 border-b border-gray-200 py-2"
                    >
                        <p
                            class="w-14 text-xs font-normal"
                            :class="
                                subjectError
                                    ? 'text-danger-500'
                                    : 'text-gray-500'
                            "
                        >
                            {{ $t('accountantInbox.subject') }}
                        </p>
                        <input
                            v-model="subject"
                            class="w-full text-md font-medium text-gray-900 placeholder-gray-500 outline-0"
                        />
                    </div>
                    <textarea
                        v-model="message"
                        class="border-none px-1 py-2.5 text-sm font-normal"
                        :class="
                            messageError
                                ? 'text-danger-500 placeholder-danger-500'
                                : 'text-gray-900 placeholder-gray-500'
                        "
                        rows="4"
                        cols="50"
                        placeholder="Use ⌘K for shortcuts"
                    />
                </div>
                <div class="flex justify-end">
                    <base-button type="submit">
                        {{ $t('general.send') }}
                    </base-button>
                </div>
            </div>
        </form>
    </div>
</template>
<script setup>
import sendSound from '@tenant/assets/audio/sendMessage.mp3'
const audioSend = new Audio(sendSound)
const { centralUser } = useAuth()
const router = useRouter()

const props = defineProps({
    client: {
        type: Object,
        default: null,
    },
    defaultType: {
        type: String,
        default: 'chat',
    },
})
const emit = defineEmits(['on-sent'])

const { handleSubmit, setErrors } = useForm()

const { value: subject, errorMessage: subjectError } = useField('subject')
const { value: message, errorMessage: messageError } = useField('message')
const { value: to, errorMessage: toError } = useField('to', undefined, {
    initialValue: props?.client?.email,
})
const { value: from, errorMessage: fromError } = useField('mail_connection_id')

const { execute } = useApi('/api/chat/new-client', 'POST')
const { execute: executeNewConversation } = useApi(
    '/api/mail/new-conversation',
    'POST'
)

const { t } = useI18n()
const { errorNotify } = useNotification()
const submitForm = handleSubmit((values) => {
    if (conversationType.value === 'email' && !values.mail_connection_id) {
        errorNotify({
            text: t('accountantInbox.error.noMailConnection'),
            action: {
                text: t('accountantInbox.setupMail'),
                method: () =>
                    router.push({ name: 'accountant.setting.emails' }),
            },
        })
        return
    }

    const apiCall =
        conversationType.value === 'email'
            ? executeNewConversation({
                  data: {
                      ...values,
                      mail_connection_id: values.mail_connection_id[0],
                  },
              })
            : execute({
                  data: values,
              })
    apiCall
        .then((message) => {
            audioSend.play()
            if (props.client) {
                emit('on-sent', message)
            } else {
                router.push({
                    name: 'accountant.inbox.chat',
                    query: {
                        conversation: message.chat_room_id,
                    },
                })
            }
        })
        .catch(({ errors, errorMessage }) => {
            setErrors(errors)
            errorNotify({
                text: errorMessage,
            })
        })
})

const conversationType = ref(props.defaultType)
const changeConversationType = (type) => {
    conversationType.value = type
}

// MAIL CONNECTIONS
const { useApiSearch, entities } = useApiFactory(
    `accountant/mail-connection/list/${centralUser.value.id}`
)
useApiSearch({
    all: true,
})

watch(
    () => entities.value,
    () => {
        if (entities.value.length) {
            from.value = [entities.value[0].id]
        }
    },
    {
        immediate: true,
    }
)
// END: MAIL CONNECTIONS
</script>
