<template>
    <div
        class="flex flex-col"
        :class="[containerClass, fullWidth ? 'w-full' : '']"
    >
        <slot name="label">
            <form-control-label :label="label" />
        </slot>

        <div
            class="relative h-full"
            :class="[
                disabled ? 'cursor-not-allowed bg-gray-50 text-gray-500' : '',
            ]"
        >
            <form-control-highlight v-if="highlight" />

            <Datepicker
                ref="dp"
                :teleport="true"
                :enable-time-picker="false"
                v-bind="restAttrs"
                v-model="model"
                :range="!single"
                :format="format"
                :auto-apply="autoApply"
                :month-picker="monthPicker"
                :year-picker="yearPicker"
                :disabled="disabled"
            >
                <template #left-sidebar>
                    <slot name="left-sidebar">
                        <form-date-picker-left-sidebar v-if="!single" />
                    </slot>
                </template>
                <template #dp-input="{ value }">
                    <base-button
                        :variant="hasError ? 'danger-300' : 'default'"
                        :outline="hasError"
                        :size="size"
                        :disabled="disabled"
                        :full-width="fullWidth"
                        :class="buttonClasses"
                        class="disabled:opacity-100"
                    >
                        <template #content>
                            <span
                                class="flex items-center gap-3"
                                :class="[
                                    contentContainerClass,
                                    fullWidth ? 'w-full' : '',
                                    clearable && !!modelValue ? 'pr-5' : '',
                                ]"
                            >
                                <slot name="icon" v-bind="{ value }">
                                    <base-icon
                                        name="line-icons:time:calendar-date"
                                        variant="inherit"
                                    />
                                </slot>

                                <slot v-bind="{ value, placeholder, disabled }">
                                    <span
                                        class="flex-1 text-left text-md font-normal text-gray-500"
                                        v-if="!value && placeholder"
                                    >
                                        {{
                                            value ||
                                            placeholder ||
                                            $t('general.selectDate', {
                                                count: single ? 1 : 2,
                                            })
                                        }}
                                    </span>

                                    <span
                                        class="flex-1 text-left text-md font-normal text-gray-700"
                                        :class="{ '!text-gray-500': disabled }"
                                        v-else
                                    >
                                        {{
                                            value ||
                                            $t('general.selectDate', {
                                                count: single ? 1 : 2,
                                            })
                                        }}
                                    </span>
                                </slot>

                                <slot name="right-icon" v-bind="{ value }" />
                            </span>
                        </template>
                    </base-button>
                </template>

                <template #clear-icon="{ clear }">
                    <div class="mr-4" v-if="clearable">
                        <base-icon
                            name="line-icons:general:x-close"
                            :variant="hasError ? 'danger' : 'gray'"
                            class="cursor-pointer"
                            @click.stop="clear"
                        />
                    </div>
                </template>

                <template
                    #action-row="{
                        internalModelValue,
                        selectDate,
                        closePicker,
                    }"
                >
                    <form-date-picker-action-row
                        :internal-model-value="internalModelValue"
                        :range="!single"
                        @select-date="selectDate"
                        @closePicker="
                            () => {
                                closePicker()
                                $emit('close')
                            }
                        "
                    />
                </template>
            </Datepicker>
        </div>

        <slot name="hint-text">
            <form-control-hint-text
                :hint-text="hintText"
                :error-message="errorMessage"
            />
        </slot>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
}
</script>

<script setup>
import { computed, useAttrs, ref } from 'vue'
import { isNil } from 'lodash-es'
import Datepicker from '@vuepic/vue-datepicker'
import {
    dayjsFormat,
    nativeDateFormat,
    shortFormat,
    customDateFormat,
} from '@tenant/utils/day'
import { DATE_SERVER_FORMAT } from '@tenant/utils/constants'

const attrs = useAttrs()
const { class: containerClass, ...restAttrs } = attrs

const props = defineProps({
    /**
     * Single date or multiple date
     */
    single: {
        type: Boolean,
        default: false,
    },
    /**
     * Clearable
     */
    clearable: {
        type: Boolean,
        default: true,
    },
    /**
     * Label displayed above the form field
     */
    label: {
        type: String,
        default: '',
    },
    /**
     * Placeholder of datepicker
     */
    placeholder: {
        type: String,
        default: '',
    },
    /**
     * Short help text that goes below the input field
     */
    hintText: {
        type: String,
        default: '',
    },
    /**
     * Error message in red shown below the input field when it's destructive
     */
    errorMessage: {
        type: String,
        default: '',
    },
    /**
     * Set the amount of padding in the input
     */
    size: {
        type: String,
        default: 'md',
        validator(value) {
            return ['sm', 'md'].includes(value)
        },
    },
    /**
     * Highlight dot and outline in yellow shown above the input field
     */
    highlight: {
        type: Boolean,
        default: false,
    },
    /**
     * Component model value
     */
    modelValue: {
        type: [Array, String, Object, Number],
        default: (props) => (props.single ? '' : []),
    },
    /**
     * Full width
     */
    fullWidth: {
        type: Boolean,
        default: true,
    },
    /**
     * Auto apply
     */
    autoApply: {
        type: Boolean,
        default: true,
    },

    disabled: {
        type: Boolean,
        default: false,
    },
    monthPicker: {
        type: Boolean,
        default: false,
    },
    yearPicker: {
        type: Boolean,
        default: false,
    },
    customDisplayFormat: {
        type: String,
        default: null,
        required: false,
    },
    contentContainerClass: {
        type: String,
        default: '',
    },
})

const dp = ref(null)

const format = (dates) => {
    if (props.monthPicker) {
        return props.customDisplayFormat
            ? customDateFormat(dates, props.customDisplayFormat)
            : dayjsFormat(dates, 'MM/YYYY')
    }

    if (props.yearPicker) {
        return dates.getFullYear()
    }

    if (Array.isArray(dates)) {
        return dates.map((date) => shortFormat(date)).join(' ~ ')
    }

    return props.customDisplayFormat
        ? customDateFormat(dates, props.customDisplayFormat)
        : shortFormat(dates)
}

const hasError = computed(() => !!props.errorMessage)

// Input handler
const emit = defineEmits(['update:modelValue'])
const model = computed({
    get() {
        let modalValue = []

        if (isNil(props.modelValue) || props.yearPicker || props.monthPicker) {
            return props.modelValue
        }

        if (props.single) {
            modalValue = nativeDateFormat(props.modelValue)
        } else {
            modalValue = props.modelValue.map(nativeDateFormat)
        }

        return modalValue
    },
    set(model) {
        if (props.yearPicker || props.monthPicker) {
            emit('update:modelValue', model)
            return
        }

        if (!isNil(model)) {
            if (Array.isArray(model)) {
                model = model.map((date) =>
                    dayjsFormat(date, DATE_SERVER_FORMAT)
                )
            } else {
                model = dayjsFormat(model, DATE_SERVER_FORMAT)
            }
        }

        emit('update:modelValue', model)
    },
})

const buttonClasses = computed(() => {
    const { size, highlight } = props

    return {
        'pl-3': size === 'sm',
        'pl-3.5 !text-md': size === 'md',
        'border-warning-300 focus:border-warning-300 ring-4 ring-warning-100 focus:ring-warning-100':
            highlight,
    }
})
</script>

<style lang="scss">
@import '@vuepic/vue-datepicker/dist/main.css';

.dp__menu {
    --dp-font-family: theme('fontFamily.inter');
    --dp-action-row-padding: 16px;
    --dp-primary-color: theme('colors.primary.600');
    --dp-cell-border-radius: 50%;
    --dp-font-size: theme('fontSize.sm');
    --dp-text-color: theme('colors.gray.700');
    --dp-menu-padding: 1rem 1.5rem;

    //@apply rounded-lg border-gray-200;

    .dp__calendar_header {
        font-weight: theme('fontWeight.medium');
    }

    .dp__calendar_header_separator {
        @apply hidden;
    }

    .dp__month_year_select {
        @apply text-md;
    }

    > .dp__menu_content_wrapper {
        ~ div:last-child {
            @apply static border-t;
        }

        > .dp__sidebar_left {
            @apply border-none p-0;
        }
    }
}
</style>
