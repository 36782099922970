<template>
    <form-contact-select
        :label="$t('createInvoice.customerLabel')"
        :placeholder="$t('createInvoice.customerPlaceholder')"
        :create-contact="false"
        :input-display="getContactName(contact)"
    />
</template>

<script setup>
import { getContactName } from '@tenant/utils/helper'

const { useApiGet } = useApiFactory('contacts')
const { execute: executeGet, result } = useApiGet()

const contact = ref(null)
const { modelValue } = useAttrs()
watch(
    () => modelValue,
    async (value) => {
        await executeGet(value)
        contact.value = result.value
    },
    { immediate: true }
)
</script>
