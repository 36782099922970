<template>
    <div>
        <div
            class="flex cursor-pointer items-center gap-2 py-2.5"
            @click="toggleDropdown"
        >
            <base-icon
                v-if="active"
                class="text-inherit"
                name="line-icons:arrows:chevron-up"
                variant="gray"
                size="lg"
            />
            <base-icon
                v-else
                class="text-inherit"
                name="line-icons:arrows:chevron-down"
                variant="gray"
                size="lg"
            />

            <slot name="header-content">{{ props.headline }}</slot>
        </div>

        <div v-if="active">
            <div class="pb-2.5 pl-8">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script setup>
// PROPS
const props = defineProps({
    headline: {
        type: String,
    },

    active: {
        type: Boolean,
        default: false,
    },
})

const active = ref(props.active)

const toggleDropdown = () => {
    active.value = !active.value
}
</script>
