<template>
    <base-dropdown
        contentClasses="w-auto p-2.5 translate-x-[280px]"
        position="right"
    >
        <!-- Toggle -->
        <template #btn="{ toggleDropdown }">
            <base-tooltip dark hover :content="$t('topBand.addTooltip')">
                <base-button
                    class="p-2"
                    icon="line-icons:general:plus-circle"
                    size="sm"
                    :hasPadding="false"
                    @click="toggleDropdown"
                />
            </base-tooltip>
        </template>

        <!-- ChartOfAccount -->
        <template #default="{ toggleDropdown }">
            <div class="flex gap-6 whitespace-nowrap">
                <!-- Column -->
                <div v-for="column in menu" :key="column">
                    <div class="mb-2.5 flex items-center gap-3 py-2.5 text-sm">
                        <span class="text-primary-700">
                            <base-icon
                                v-if="column.icon"
                                :name="column.icon"
                                variant="primary"
                                size="sm"
                            />
                        </span>

                        {{ column.label }}
                    </div>

                    <!-- Item -->
                    <base-dropdown-item
                        v-for="item in column.items"
                        :key="item"
                        itemClasses="py-2.5 hover:text-primary-600 border-b text-sm min-w-[165px]"
                        @click="
                            ;[
                                router.push({ name: item.path }),
                                toggleDropdown(),
                            ]
                        "
                    >
                        {{ item.label }}
                    </base-dropdown-item>
                </div>
            </div>
        </template>
    </base-dropdown>
</template>

<script setup>
const { t } = useI18n()
const router = useRouter()

const menu = ref([
    {
        label: t('topBand.addMenu.general.label'),
        icon: 'line-icons:general:settings-04',
        items: [
            {
                label: t('topBand.addMenu.general.users'),
                path: 'settings.users',
            },
        ],
    },
    {
        label: t('topBand.addMenu.sales.label'),
        icon: 'line-icons:finance-&-ecommerce:shopping-cart-01',
        items: [
            {
                label: t('topBand.addMenu.sales.contacts'),
                path: 'contacts',
            },
            {
                label: t('topBand.addMenu.sales.estimates'),
                path: '',
            },
            {
                label: t('topBand.addMenu.sales.invoices'),
                path: 'invoices',
            },
            {
                label: t('topBand.addMenu.sales.creditNotes'),
                path: '',
            },
        ],
    },
    {
        label: t('topBand.addMenu.expenses.label'),
        icon: 'line-icons:finance-&-ecommerce:bill-check',
        items: [
            {
                label: t('topBand.addMenu.expenses.expenses'),
                path: '',
            },
            {
                label: t('topBand.addMenu.expenses.bills'),
                path: '',
            },
            {
                label: t('topBand.addMenu.expenses.reviewExpenses'),
                path: 'reports.profit-and-loss',
            },
        ],
    },
    {
        label: t('topBand.addMenu.banking.label'),
        icon: 'line-icons:finance-&-ecommerce:bank',
        items: [
            {
                label: t('topBand.addMenu.banking.connectAccount'),
                path: 'settings.accounting.payment-account.connect-bank',
            },
            {
                label: t('topBand.addMenu.banking.reviewTransactions'),
                path: 'bookeeping.transactions.review',
            },
            {
                label: t('topBand.addMenu.banking.reconcile'),
                path: 'reconcile.start',
            },
        ],
    },
])
</script>
