<template>
    <div
        class="pointer-events-none fixed inset-0 z-[9999] flex items-start justify-end p-6"
    >
        <div class="flex w-[400px] flex-col gap-y-2.5">
            <TransitionGroup
                enter-active-class="transform ease-out duration-100 transition"
                enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
                enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                leave-active-class="transition ease-in duration-200"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
                move-class="transition duration-200"
            >
                <div
                    v-for="notification in notifications"
                    :key="notification.id"
                >
                    <div
                        class="pointer-events-auto mx-auto mr-6 flex w-full flex-col overflow-hidden rounded-lg border border-gray-100 bg-white p-4 shadow-4xl"
                    >
                        <div class="flex items-start gap-4">
                            <div class="flex">
                                <base-avatar
                                    size="sm"
                                    :user="notification.user"
                                />
                            </div>
                            <div
                                class="flex flex-col gap-1 overflow-auto text-sm font-normal text-gray-500"
                            >
                                <div class="flex items-center gap-2">
                                    <p
                                        class="truncate font-medium text-gray-900"
                                    >
                                        {{ notification.user.full_name }}
                                    </p>
                                    <time class="whitespace-nowrap">
                                        {{
                                            $t('longTimeAgo', {
                                                diffDate:
                                                    notification.diff_date,
                                            })
                                        }}
                                    </time>
                                </div>
                                <p class="line-clamp-2">
                                    {{ notification.message }}
                                </p>

                                <div class="mt-4 flex items-center gap-3">
                                    <span
                                        class="cursor-pointer font-medium"
                                        @click="close(notification)"
                                    >
                                        {{ $t('general.dismiss') }}
                                    </span>
                                    <span
                                        class="flex cursor-pointer items-center gap-2 font-medium text-primary-700"
                                        @click="onAnswer(notification)"
                                    >
                                        <base-icon
                                            class="group-hover:text-gray-500"
                                            name="line-icons:communication:message-chat-square"
                                            variant="inherit"
                                        />
                                        {{ $t('general.answer') }}
                                    </span>
                                </div>
                            </div>

                            <div class="flex flex-1 justify-end">
                                <base-icon
                                    class="cursor-pointer"
                                    name="line-icons:general:x"
                                    size="lg"
                                    variant="gray"
                                    @click="close(notification)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </TransitionGroup>
        </div>
    </div>
</template>

<script setup>
import { ECHO_NOTIFICATIONS } from '@tenant/utils/constants'

const emit = defineEmits(['on-answer'])

const notifications = ref([])
const { centralUser } = useAuth()

useListenNotification(`user.${centralUser.value.id}`, ({ type, message }) => {
    if (type !== ECHO_NOTIFICATIONS.CHAT_UN_ANSWER) {
        return
    }

    notifications.value = [message, ...notifications.value]
})

const close = (notification) => {
    notifications.value = notifications.value.filter(
        (i) => i.id !== notification.id
    )
}

const onAnswer = (notification) => {
    emit('on-answer', notification)
    close(notification)
}
</script>
