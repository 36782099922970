<template>
    <div class="flex h-screen items-center justify-center">
        <div
            class="container flex min-h-[356px] w-[880px] flex-col items-center justify-center bg-no-repeat"
            :style="{
                'background-image': `url(${$filters.asset(
                    'static/images/not-found-image.svg'
                )})`,
            }"
        >
            <h2 class="mb-6 text-gray-900">
                {{ $t('general.notFound.title') }}
            </h2>
            <p class="mb-12 text-gray-600">
                {{ $t('general.notFound.description') }}
            </p>
            <div class="flex gap-3">
                <base-button
                    icon="line-icons:arrows:arrow-left"
                    variant="default"
                    @click="$router.back()"
                >
                    {{ $t('general.notFound.goBack') }}
                </base-button>
                <base-button
                    @click="
                        $router.push({
                            name: 'home',
                        })
                    "
                >
                    {{ $t('general.notFound.goToDashboard') }}
                </base-button>
            </div>
        </div>
    </div>
</template>
<script setup>
const { t } = useI18n()
useHead({
    title: t('general.notFound.head'),
})
</script>
